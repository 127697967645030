import React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Moment from "react-moment";
import { GET_VITALS_RANGE_CLASS, DEFAULTVITALGROUPID } from '../../utils/constant';
import LS_SERVICE from '../../utils/localStorage';
import { Popover } from 'antd';

// import { selectedVitalGroupService } from '../../utils/rxjs-sharing';

class Vitals extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      dataLoaded: props.dataLoaded,
      constants: props.constants,
      visitVitalsFormatted: props.visitVitalsFormatted ?? [],
      vitalsConstant: this.props.homecareView && props.constants.vital_groups['VG003'] ? props.constants.vital_groups['VG003'].vital_sign : props.constants.vitals_details,
      homecareView: this.props.homecareView || false,
      displayResult: null,
      showingVitals: []
    }
  }

  componentDidMount() {

    let seletctedSpecialityIdID = DEFAULTVITALGROUPID;
    //Finding emr_vital_group_id of emr_vital_group table using speciality id of doctor
    let selectedVitalGroup = Object.values(this.state.constants.vital_groups).find(item => {
      let item_arr = [];
      let specialityIds = ""+item.speciality_id
      item_arr = item.speciality_id != null ? specialityIds.split(',') : item_arr;
      if (LS_SERVICE.get('speciality_id') && item_arr.indexOf(LS_SERVICE.get('speciality_id')) && item_arr.indexOf(LS_SERVICE.get('speciality_id').toString()) != -1)
        return true;
    });

    //let seletctedSpecialityId = Object.values(this.state.constants.vital_groups).find(item=>item.speciality_id==LS_SERVICE.get('speciality_id'));
    if (selectedVitalGroup != undefined) {
      seletctedSpecialityIdID = selectedVitalGroup.id

    }

    // selectedVitalGroupService.init(seletctedSpecialityIdID);
    //Finding vital sign array
    //console.log(this.state.constants.vital_groups);
    let seletctedShowingVitalGroup = Object.values(this.state.constants.vital_groups).find(item => item.id == seletctedSpecialityIdID);
    if (this.state.homecareView)
      seletctedShowingVitalGroup = this.state.constants.vital_groups['VG003'];
    this.setState({
      showingVitals: seletctedShowingVitalGroup.vital_sign,
      homecareView: this.props.homecareView
    }, () => {
      if(this.props.homecareView){
        this.setState({
          vitalsConstant: seletctedShowingVitalGroup.vital_sign
        })
      }
      else{
        const remainingVitals = this.state.vitalsConstant.filter((elem) => !this.state.showingVitals.find(({ vital_code }) => elem.vital_code === vital_code));
        this.setState({
          vitalsConstant: remainingVitals
        })
      }

    });

  }


  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (nextProps.visitVitalsFormatted !== prevState.visitVitalsFormatted)
      return { visitVitalsFormatted: nextProps.visitVitalsFormatted }
    return null;
  }

  // getVitalRangeClass(value, range) {
  //     if (value == '-') return '';
  //     let vitalRange = '';
  //     //normal range
  //     if (value >= range.normal.min && value <= range.normal.max)
  //         vitalRange = 'text-success';

  //     //abnormal range
  //     if (
  //         (value > range.critical.min && value < range.normal.min) ||
  //         (value > range.normal.max && value < range.critical.max)
  //     )
  //         vitalRange = 'text-warning';

  //     //critical range
  //     if ((range.critical.min != null && value <= range.critical.min) || (range.critical.max != null && value >= range.critical.max))
  //         vitalRange = 'text-danger';

  //     return vitalRange;
  // }

  // handleModalPopupStateLift = (e, popup, data = null, deleteType = '') => {

  // }

  getSourceDisplayName(source, date, update_date) {
    let sourceName = source[0].toUpperCase() + source.substring(1);
    let time = moment(update_date).format('DD MMM, h:mm A');
    //return source == 'patient' && date && update_date && date != update_date ? sourceName + ' | ' + time : sourceName;
    return sourceName;
  }

  render() {
    return (
        <>
          {this.state.dataLoaded ? (
              <div className="card-body vitals" id="vital-details">
                <div className="row">
                  <div className="col-12 table-responsive" style={{ padding: "0px" }}>
                    {this.state?.visitVitalsFormatted?.date?.length > 0 
                    ?
                    <table className="table">
                      <tbody>
                      <tr>
                        <td>Parameter</td>
                        {this.state?.visitVitalsFormatted?.date.map((v, i) => (
                            <td key={i} className={`pl-0 pr-0 ${this.state.visitVitalsFormatted.source[i] === 'patient' && 'bgis-pink'}`} style={{ maxWidth: "250px" }}>
                              <div className="row">
                                <div className="col-12 text-center vital-source">
                                  {this.state.visitVitalsFormatted?.source_name[i] !== "" ? (
                                      <Popover
                                          className="ml-1"
                                          content={
                                            this.state.visitVitalsFormatted?.source_name[i]
                                          }
                                      >
                                        {this.getSourceDisplayName(this.state.visitVitalsFormatted.source[i], v, this.state.visitVitalsFormatted.update_date[i])}
                                      </Popover>
                                  ) : (
                                      this.getSourceDisplayName(this.state.visitVitalsFormatted.source[i], v, this.state.visitVitalsFormatted.update_date[i])
                                  )}
                                </div>
                                <div className="col-12 text-center vital-time">
                                {this.props.comp != undefined && this.props.comp == 'pastvisit' ? moment(this.state.visitVitalsFormatted.date[i]).subtract(5,'hours').subtract(30,'minute').format('DD MMM, yyyy | h:mm A'):moment(this.state.visitVitalsFormatted.date[i]).format('DD MMM, yyyy | h:mm A')}                                </div>
                              </div>
                            </td>
                        ))}
                        <td className="text-center" style={{ width: "132px" }}>Reference Range</td>
                        <td className="text-center">Trend Graph</td>
                      </tr>

                      {  !this.state.homecareView ?
                          this.state.showingVitals.map((vital) => {
                            if (vital.id != 'V005' && vital.id != 'V006'){
                              var vitalsavailable = 0;
                              {this.state.visitVitalsFormatted[vital.id]?.map((val, i) => {
                                if(val && val != ""){
                                  vitalsavailable = vitalsavailable+1;
                                }
                              })}
                              if(vitalsavailable > 0){
                              return (
                                  <tr key={vital.id}>
                                    <td>{vital.display_name} ({vital.display_value})</td>
                                    {this.state.visitVitalsFormatted[vital.id]?.map((val, i) => {
                                      if (val) return (<td key={i} className={`text-center ${vital.id != 'V001' && vital.id != 'V002' ? GET_VITALS_RANGE_CLASS(val, this.state.constants.vitals_range[vital.id]) : ''} ${this.state.visitVitalsFormatted.source[i] === 'patient' && 'bgis-pink'}`}>{val}</td>)
                                      else return (<td key={i} className={`text-center ${this.state.visitVitalsFormatted.source[i] === 'patient' && 'bgis-pink'}`}>-</td>);
                                    })}
                                    <td>{vital.id == 'V001' || vital.id == 'V002' ? '-' : this.state.constants.vitals_range[vital.id].normal.min + '-' + this.state.constants.vitals_range[vital.id].normal.max}</td>
                                    <td>
                                      <button type="button" className="btn chart-btn button-chart-individual"
                                              onClick={e => this.props.handleModalPopupStateLift(e, 'chartModal', { type: vital.id, name: 'Trend Graph' })}>
                                        <i className="icon-chart"></i>
                                      </button>
                                    </td>
                                  </tr>
                              )
                              }
                            }
                            else if (vital.id == 'V005'){
                              var vitalsavailable = 0;
                              {this.state.visitVitalsFormatted[vital.id]?.map((val, i) => {
                                if(val && val != ""){
                                  vitalsavailable = vitalsavailable+1;
                                }
                              })}
                              if(vitalsavailable > 0){
                              return (
                                  <tr key={vital.id}><td>Blood Pressure (mmHg)</td>
                                    {this.state.visitVitalsFormatted.V006.map((val, i) => (
                                        <td className={`text-center ${this.state.visitVitalsFormatted.source[i] === 'patient' && 'bgis-pink'}`} key={i}>
                                          <span className={(val ? GET_VITALS_RANGE_CLASS(this.state.visitVitalsFormatted.V006[i], this.state.constants.vitals_range.V006) : '')}>{this.state.visitVitalsFormatted.V006[i] ? this.state.visitVitalsFormatted.V006[i] : '-'}</span>/
                                          <span className={(val ? GET_VITALS_RANGE_CLASS(this.state.visitVitalsFormatted.V005[i], this.state.constants.vitals_range.V005) : '')}>{this.state.visitVitalsFormatted.V005[i] ? this.state.visitVitalsFormatted.V005[i] : '-'}</span>
                                        </td>)
                                    )}

                                    <td>{this.state.constants.vitals_range.V006.normal.min + '-' + this.state.constants.vitals_range.V006.normal.max}/{this.state.constants.vitals_range.V005.normal.min + '-' + this.state.constants.vitals_range.V005.normal.max}</td>

                                    <td>
                                      <button type="button" className="btn chart-btn button-chart-individual"
                                              onClick={e => this.props.handleModalPopupStateLift(e, 'chartModal', { type: vital.id, name: 'Trend Graph' })}>
                                        <i className="icon-chart"></i>
                                      </button>
                                    </td>
                                  </tr>
                              )
                            }
                            }
                          })
                          : null
                      }

                      {

                        this.state.vitalsConstant.map((vital) => {
                          if (vital.id != 'V005' && vital.id != 'V006'){
                            var vitalsavailable = 0;
                            {this.state.visitVitalsFormatted[vital.id]?.map((val, i) => {
                              if(val && val != ""){
                                vitalsavailable = vitalsavailable+1;
                              }
                            })}
                            if(vitalsavailable > 0){
                            return (
                                <tr key={vital.id}>
                                  <td>{vital.display_name} ({vital.display_value})</td>
                                  {this.state.visitVitalsFormatted[vital.id]?.map((val, i) => {
                                    if (val) return (<td key={i} className={`text-center ${vital.id != 'V001' && vital.id != 'V002' ? GET_VITALS_RANGE_CLASS(val, this.state.constants.vitals_range[vital.id]) : ''} ${this.state.visitVitalsFormatted.source[i] === 'patient' && 'bgis-pink'}`}>{val}</td>)
                                    else return (<td key={i} className={`text-center ${this.state.visitVitalsFormatted.source[i] === 'patient' && 'bgis-pink'}`}>-</td>);
                                  })}
                                  <td>{vital.id == 'V001' || vital.id == 'V002' ? '-' : this.state.constants.vitals_range[vital.id].normal.min + '-' + this.state.constants.vitals_range[vital.id].normal.max}</td>
                                  <td>
                                    <button type="button" className="btn chart-btn button-chart-individual"
                                            onClick={e => this.props.handleModalPopupStateLift(e, 'chartModal', { type: vital.id, name: 'Trend Graph' })}>
                                      <i className="icon-chart"></i>
                                    </button>
                                  </td>
                                </tr>
                            )
                            }else{
                              return(<React.Fragment></React.Fragment>)
                            }
                          }
                          else if (vital.id == 'V005'){
                            var vitalsavailable = 0;
                            {this.state.visitVitalsFormatted[vital.id]?.map((val, i) => {
                              if(val && val != ""){
                                vitalsavailable = vitalsavailable+1;
                              }
                            })}
                            if(vitalsavailable > 0){
                            return (
                                <tr key={vital.id}><td>Blood Pressure (mmHg)</td>
                                  {this.state.visitVitalsFormatted.V006.map((val, i) => (
                                      <td className={`text-center ${this.state.visitVitalsFormatted.source[i] === 'patient' && 'bgis-pink'}`} key={i}>
                                        <span className={(val ? GET_VITALS_RANGE_CLASS(this.state.visitVitalsFormatted.V006[i], this.state.constants.vitals_range.V006) : '')}>{this.state.visitVitalsFormatted.V006[i] ? this.state.visitVitalsFormatted.V006[i] : '-'}</span>/
                                        <span className={(val ? GET_VITALS_RANGE_CLASS(this.state.visitVitalsFormatted.V005[i], this.state.constants.vitals_range.V005) : '')}>{this.state.visitVitalsFormatted.V005[i] ? this.state.visitVitalsFormatted.V005[i] : '-'}</span>
                                      </td>)
                                  )}

                                  <td>{this.state.constants.vitals_range.V006.normal.min + '-' + this.state.constants.vitals_range.V006.normal.max}/{this.state.constants.vitals_range.V005.normal.min + '-' + this.state.constants.vitals_range.V005.normal.max}</td>

                                  <td>
                                    <button type="button" className="btn chart-btn button-chart-individual"
                                            onClick={e => this.props.handleModalPopupStateLift(e, 'chartModal', { type: vital.id, name: 'Trend Graph' })}>
                                      <i className="icon-chart"></i>
                                    </button>
                                  </td>
                                </tr>
                            )
                        }
                          }
                          else{ return( <React.Fragment></React.Fragment>)
                           
                          }
                        })

                      }

                      </tbody>
                    </table>
                    : null}
                  </div>
                </div>
              </div>) : ""}
        </>
    );
  }
}

export default Vitals;
