import React, { Component, Fragment } from 'react';
import Axios from "axios";
import moment from "moment";
import qs from "qs";
import { NavLink, Link } from 'react-router-dom';
import { Button, Form, Input, Modal, Select, notification, DatePicker, Popover } from "antd";
import Vitals from './sscomponents/vitals';
import Chart from './sscomponents/chart';
import LS_SERVICE from "../../utils/localStorage";
import TextArea from 'antd/lib/input/TextArea';

import {
    AntClearLabels,
    AntDisabledLabels,
    AntSelectLabels,
    FixedCta,
    jQueryLabels,
    onlynumbers
} from "../../utils/jQueryLabels";
import {
    BMI_CALCULATE,
    DURATION_TYPE,
    HANDLEVALIDANTMESSAGES,
    BSA_CALCULATE,
    LOADER,
    LOADER_RED,
    OBJECTIVE_VITAL_STORE,
    PATIENT_DATA,
    OBJECTIVE_GETALL_IPD,
    VITALS_GRAPH
} from "../../utils/constant";

const { Option } = Select;

class VitalCharting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patient: null,
            details: null,
            constants: null,
            visitVitals: [],
            objective_data_loaded: false,
            vitals_data_formatted: false,
            visitVitalsFormatted: [],
            showVitalsTable: false,
            formModeEdit: false,
            fromFieldId: -1,
            formFieldEntryId: null,
            vitalModal: false,
            deleteModal: false,
            chartModal: false,
            number: null,
            vitalsType: '0',
            patientHeight: null,
            patientWeight: null,
            vitalGroups: [],
            selectedVitalGroups: [],
            displayVitals: [],
            vitalNotInRange: [],
            delete_text: '',
            deleteType: '',
            delete_id: '',
            spin_loading: false,
            chartData: {},
            vitalsRange: {},
            chartDataLoaded: false,
            chartGraphSettings: '',
            chartsTab: '2',
            chartsType: 'all',
            chartHeading: 'Vital',
            chartsTabSet: false,
            formIsFetching: false,
            totalVisit: 0,
            noDataModal: false,
            noDataModalText: '',
            dataLoaded: false,
            vital_date_time: moment().format("YYYY-MM-DDTHH:mm:ss"),
            vitalOriginalData: {},
            to_date: "",
            from_date: "",
            vitalPrint: false,
            vitalsConstant: null,
            displayVitalData: [],
            isVitalsSearched: false,
            mhea_patient_id: props.mhea_patient_id,
        }
        this.vitalFormRef = React.createRef();
        this.searchFormRef= React.createRef();
        this.handleVitalModalButtonDisabled = this.handleVitalModalButtonDisabled.bind(this);
    }
    static getDerivedStateFromProps() {
        Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
        return true;
    }
    async componentDidMount() {
        this.setState({
            patient_id: this.props.mhea_patient_id
        }, async () => {
            this.setState({ dataloaded: true });
            await this.handleObjectiveGetAllData();
            await this.handleVitalGraphData();
            
            jQueryLabels();
            FixedCta();
            onlynumbers();
        })
    }

    handleCaseSheetSummaryVitals = async () => {
        this.props.handleCaseSheetSummaryVitals(this.state.visitVitals, this.state.vitalsConstant);
    }
    getPatientData = _ => {
        const { match: { params } } = this.props;
        const { reference_id } = params;
        Axios.get(PATIENT_DATA({ reference_id }))
            .then(success => {
                const patient = {
                    ...success.data.data.results,
                    qms_token_id: success.data.data.results.qmsToken.qms_token_id
                }
                this.setState({
                    patient
                }, () => {
                    this.handleObjectiveGetAllData();
                    this.handleVitalGraphData();
                    this.handleAllergyDropDownData();
                })
            })
            .catch(err => console.log(err))
    }
    handleOnChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        })
    }
    handleOnChangeNumbers = (event, min, max) => {
        let { name, value } = event.currentTarget;
        let vitalNotInRange = this.state.vitalNotInRange;
        //value = value.replace(name !== 'V004' ? /\D/ : /[^0-9.]/g, "");
        var index = vitalNotInRange.indexOf(name);
        if (parseFloat(value) < min || parseFloat(value) > max) {
            if (index == -1)
                vitalNotInRange.push(name);
        }
        else {
            if (index != -1)
                vitalNotInRange.splice(index, 1);
        }
        this.setState({
            [name]: value,
            vitalNotInRange
        }, () => {
            this.vitalFormRef.current.setFieldsValue({
                [name]: value
            })
            this.handleBmiCalculate();
        })
    }

    handleOnChangeText = (event) => {
      let { name, value } = event.currentTarget;
      let vitalNotInRange = this.state.vitalNotInRange;
      console.log(vitalNotInRange);
      this.setState({
          [name]: value,
          vitalNotInRange
      }, () => {
          this.vitalFormRef.current.setFieldsValue({
              [name]: value
          })
      })
  }

    handleBmiCalculate = _ => {
        let { V001, V002, patientHeight, patientWeight } = this.state;
        V001 = V001 !== null ? V001 : patientHeight;
        V002 = V002 !== null ? V002 : patientWeight;
        if (V001 > 0 && V002 > 0) {
            let val = BMI_CALCULATE(V001, V002);
            this.setState({
                V003: val
            }, () => { AntSelectLabels() })
            this.vitalFormRef.current.setFieldsValue({
                V003: val
            }, () => { AntSelectLabels() })
            AntSelectLabels();
        } else {
            this.setState({
                V003: ''
            }, () => { AntSelectLabels() })

            this.vitalFormRef.current.setFieldsValue({
                V003: ''
            })
            AntSelectLabels();
        }
    }
    handleVitalGraphData = async _ => {
        const { patient_id } = this.state;
        const vitalGraphPromise = await Axios.get(VITALS_GRAPH({ patient_id }))
        if (vitalGraphPromise.data.status) {
            const { data } = vitalGraphPromise;
            this.setState({
                chartData: data.details,
                vitalsRange: Object.values(data.constants.range)
            })
        }
    }

    handleOnChangeRemarks(event) {
        let { name, value } = event.currentTarget;
        this.setState({
          [name]: value
        }, () => {
          this.vitalFormRef.current.setFieldsValue({
            [name]: value
          })
          this.handleBmiCalculate();
        })
      }

    makeVitalsData() {
        let vitalDetails = [];
        this.setState({
            patientHeight: null,
            patientWeight: null
        });
        let { patient, patientHeight, patientWeight } = this.state;
        let allVitalIds = [];
        this.state.constants.vitals_details.map((v, k) => {
            allVitalIds.push(v.id);
            vitalDetails[v.id] = [];
            this.setState({ [v.id]: null });
        });

        vitalDetails['date'] = [];
        vitalDetails['source'] = [];
        vitalDetails['update_date'] = [];
        vitalDetails['source_name'] = [];
        allVitalIds.push('date');
        allVitalIds.push('source');
        allVitalIds.push('update_date');
        allVitalIds.push('source_name');

        if (Object.keys(this.state.visitVitals).length > 0) {
            let index = 0;
            this.state.visitVitals.map(function (temp_vitals, k) {
                Object.keys(temp_vitals).forEach(j => {
                    if (vitalDetails[j]) vitalDetails[j][index] = temp_vitals[j];
                    if (j == 'V001' && patientHeight == null && moment().diff(moment(temp_vitals.date[j]), 'months') < 2 && temp_vitals[j]) patientHeight = temp_vitals[j];
                    if (j == 'V002' && patientWeight == null && moment().diff(moment(temp_vitals.date[j]), 'months') < 2 && temp_vitals[j]) patientWeight = temp_vitals[j];
                });
                index++;
            });
        }
        this.setState({
            patientHeight: patientHeight,
            patientWeight: patientWeight,
            V001: patientHeight,
            V002: patientWeight,
        });
       
        let vitals_data = {};
        this.state.constants.vitals_details.map(function (v, k) {
            vitals_data[v.id] = vitalDetails[v.id];
            if (v.id == "V003") {
                LS_SERVICE.set('BMI_VALUE', vitalDetails[v.id][0] ? vitalDetails[v.id][0] : null)
            }
        });
        vitals_data.date = vitalDetails['date'];
        vitals_data.source = vitalDetails['source'];
        vitals_data.update_date = vitalDetails['update_date'];
        vitals_data.source_name = vitalDetails['source_name'];
        this.state.visitVitalsFormatted = vitals_data;
        //vitals groups
        let vitalGroupData = [];
        const doctorSpeciality = LS_SERVICE.get('speciality_id');
        let allVital = null;
        for (const [key, vitalGroup] of Object.entries(this.state.constants.vital_groups)) {
            if (vitalGroup.speciality_id == doctorSpeciality) {
                var vitalSignIds = [];
                for (const [key, vitalSign] of Object.entries(vitalGroup.vital_sign)) {
                    vitalSignIds.push(vitalSign.vital_id);
                }
                vitalGroup.vitalSignIds = vitalSignIds.toString().replace(/(^,)|(,$)/g, "");
                vitalGroupData.push(vitalGroup);
            }
            if (vitalGroup.id == 1) {
                var vitalSignIds2 = [];
                for (const [key, vitalSign] of Object.entries(vitalGroup.vital_sign)) {
                    vitalSignIds2.push(vitalSign.vital_id);
                }
                vitalGroup.vitalSignIds = vitalSignIds2.toString().replace(/(^,)|(,$)/g, "");
                allVital = vitalGroup;
            }
        }
        if (vitalGroupData.length == 0)
            vitalGroupData.push(allVital);
        this.setState({ selectedVitalGroups: vitalGroupData, vitalsType: vitalGroupData[0].id, displayVitals: vitalGroupData[0].vitalSignIds.split(',') })
        this.setState({ vitals_data_formatted: true })
        // this.makeVitalsHTML();
        // return vitals_data;
    }
    handleObjectiveGetAllData = async () => {
        const { patient_id } = this.state;
        var PARAMS = {
            facilityId: parseInt(LS_SERVICE.get('FACILITY_ID')),
            patientId: parseInt(patient_id),
            doctorId: parseInt(LS_SERVICE.get('staff_id')),
            type: 'vitals'
        }
        const getVitalsPromise = await Axios.post(OBJECTIVE_GETALL_IPD, qs.stringify(PARAMS))
        if (getVitalsPromise.data.status) {
            const { data } = getVitalsPromise.data;
            const { details, constants } = data;
            this.setState({
                vitalOriginalData: getVitalsPromise.data.data,
                visitVitals: Object.values(details.visitVitals),
                V001: details?.visitVitals[0]?.V001,
                V002: details?.visitVitals[0]?.V002,
                constants,
                objective_data_loaded: true,
                vitalsConstant: constants.vitals_details
            }, () => { 
                this.makeVitalsData();
                // this.makeVitalsPrintData(this.state.visitVitals);
             })
        }
    }

    handleModalPopup = (e, popup, data = null, deleteType = '') => {
        if (e !== null)
            e.preventDefault();
        this.setState({
            [popup]: !this.state[popup]
        }, () => {
            const { vitalModal } = this.state;
            if (!vitalModal) {
                this.state.constants.vitals_details.map(function (v, k) {
                    this.setState({ [v.id]: null });
                }, this);

                if (this.vitalFormRef.current !== null) {
                    this.vitalFormRef.current.resetFields();
                }
            }
            else {
                let { patientHeight, patientWeight, patient } = this.state;
                // if (LS_SERVICE.get('PATIENT_AGE') > 5) {
                if (this.vitalFormRef.current !== null) {
                    this.vitalFormRef.current.setFieldsValue({
                        V001: patientHeight,
                        V002: patientWeight
                    });
                    this.handleBmiCalculate();
                }
                // }
            }
            AntSelectLabels();
            this.setState({
                spin_loading: false,
                // chartsTab: '2',
                // chartsType: 'all',
                // allergy_active_tab: ALLERGY_TAB_STATUS[0],
                vitalsType: this.state.selectedVitalGroups[0].id,
                formModeEdit: false,
                fromFieldId: -1,
                deleteType,
                delete_id: data !== null ? data.id : ''
            }, () => {
                let { chartModal } = this.state;
                if (chartModal) {
                    this.setState({
                        chartDataLoaded: true,
                        chartsType: data.type,
                        chartHeading: data.name,
                    })
                }
                AntDisabledLabels();
                AntSelectLabels();
            })
        })
    }
    handleAntFormOnChange = d => {
        let { name, value } = d.currentTarget.hasOwnProperty('name') ? d.currentTarget : d.target;
        this.setState({
            [name]: value
        })
    }
    handleVitalSelect = (val, props) => {
        let displayVitals = props.datavitals.split(',');
        this.setState({
            vitalsType: val,
            displayVitals: displayVitals
        })
        AntSelectLabels();
    }
    handleVitalAddSubmit = async _ => {
        if (this.handleVitalModalButtonDisabled()) return;
        let vitalsData = this.vitalFormRef.current.getFieldsValue(true);

        //adding BSA on basis of ht and wt
        if (vitalsData['V001'] && vitalsData['V002']) {
            let Bsa = BSA_CALCULATE(vitalsData['V001'], vitalsData['V002'])
            this.vitalFormRef.current.setFieldsValue({
                V017: Bsa
            });
        }
        vitalsData = this.vitalFormRef.current.getFieldsValue(true);
        const { patient_id, spin_loading } = this.state;
        const PARAMS = {
            vitals_data: vitalsData,
            reference_type: 0,
            reference_id: parseInt(LS_SERVICE.get('ENCOUNTER_ID')),
            patient_id: patient_id,
            source: "ipd_emr",
            hospital_id: LS_SERVICE.get('FACILITY_ID'),
            doctor_id: parseInt(LS_SERVICE.get('staff_id')),
            speciality_id: LS_SERVICE.get('speciality_id'),
            vital_date: this.state.vital_date_time,
        }
        this.setState({
            spin_loading: !spin_loading
        })
        const vitalAddPromise = await Axios.post(OBJECTIVE_VITAL_STORE, qs.stringify(PARAMS))
        if (vitalAddPromise.status) {
            this.handleObjectiveGetAllData();
            this.handleVitalGraphData();
            this.handleModalPopup(null, 'vitalModal');
            this.setState({
                spin_loading: !spin_loading
            })
            notification.success({
                message: 'Vital is recorded successfully.',
                placement: 'topRight'
            });
        }
    }
    handleGraphClick = (e, type = '2') => {
        this.setState({
            chartDataLoaded: false,
            chartsTab: type
        }, () => this.setState({ chartDataLoaded: true }))
    }
    handleVitalModalButtonDisabled = _ => {
        if (this.state.constants && this.state.constants.vitals_details) {
            var vitalFieldsCount = this.state.constants.vitals_details.length;
            var emptyCount = 0;
            this.state.constants.vitals_details.map(function (v, k) {
                if (this.state[v.id] == null || this.state[v.id] == '') emptyCount++;
            }, this);
            if (vitalFieldsCount !== emptyCount) {
              return false;
            }
      
            return true;
        }
        else
            return false;
    }
    handleVitalModalClear = _ => {
        this.state.constants.vitals_details.map(function (v, k) {
            this.setState({ [v.id]: null });
        }, this);
        this.vitalFormRef.current.resetFields();
        const { patientHeight, patientWeight, patient } = this.state;
        // if (patient.age > HOSPITALDEFAULTS.age) {
        //     if (this.vitalFormRef.current !== null) {
        //         this.vitalFormRef.current.setFieldsValue({
        //             V001: patientHeight,
        //             V002: patientWeight
        //         });
        //         this.handleBmiCalculate();
        //     }
        // }
        AntClearLabels();
    }
    handleMaxLimit = type => {
        const { patient } = this.state;
        if (patient !== null) {
            const sD = moment(patient.dob);
            const eD = moment();
            const maxVal = eD.diff(sD, DURATION_TYPE[type]);
            return maxVal;
        }
    }
    chartStateUpdate = data => {
        this.setState({
            chartGraphSettings: data
        })
    }
    disabledDate(current) {
        // Can not select future
        const current_time = moment();
        return current.isAfter(current_time);
    }
    handlerDateTimePicker = async (date) => {
        this.setState({
            vital_date_time: moment(date).format("YYYY-MM-DDTHH:mm:ss")
        });
    }
    handleDatePickerChange = async (date, dateString, field) => {
        if (date) {
          await this.setState({
            [field]: moment(date)
          });
        } else {
          await this.setState({
            [field]: ''
          });
        }
      }

      makeVitalsPrintData(vitalsData){
        const perChunk = 5;
        vitalsData = vitalsData.reduce((resultArray, item, index) => { 
            const chunkIndex = Math.floor(index/perChunk)
            if(!resultArray[chunkIndex]) {
              resultArray[chunkIndex] = [] 
            }
            resultArray[chunkIndex].push(item)
            return resultArray
          }, [])

        let displayVitalData = [];
        for (let index = 0; index < vitalsData.length; index++) {
            const vitalItem = vitalsData[index];
            let vitalDetails = [];
              this.setState({
                  patientHeight: null,
                  patientWeight: null
              });
              let { patient, patientHeight, patientWeight } = this.state;
              let allVitalIds = [];
              this.state.constants.vitals_details.map((v, k) => {
                  allVitalIds.push(v.id);
                  vitalDetails[v.id] = [];
                  this.setState({ [v.id]: null });
              });

              vitalDetails['date'] = [];
              vitalDetails['source'] = [];
              vitalDetails['update_date'] = [];
              vitalDetails['source_name'] = [];
              allVitalIds.push('date');
              allVitalIds.push('source');
              allVitalIds.push('update_date');
              allVitalIds.push('source_name');

              if (Object.keys(vitalItem).length > 0) {
                  let index = 0;
                  vitalItem.map(function (temp_vitals, k) {
                      Object.keys(temp_vitals).forEach(j => {
                          if (vitalDetails[j]) vitalDetails[j][index] = temp_vitals[j];
                          if (j == 'V001' && patientHeight == null && moment().diff(moment(temp_vitals.date[j]), 'months') < 2 && temp_vitals[j]) patientHeight = temp_vitals[j];
                          if (j == 'V002' && patientWeight == null && moment().diff(moment(temp_vitals.date[j]), 'months') < 2 && temp_vitals[j]) patientWeight = temp_vitals[j];
                      });
                      index++;
                  });
              }
              this.setState({
                  patientHeight: patientHeight,
                  patientWeight: patientWeight,
                  V001: patientHeight,
                  V002: patientWeight,
              });

              let vitals_data = {};
              this.state.constants.vitals_details.map(function (v, k) {
                  vitals_data[v.id] = vitalDetails[v.id];
                  if (v.id == "V003") {
                      LS_SERVICE.set('BMI_VALUE', vitalDetails[v.id][0] ? vitalDetails[v.id][0] : null)
                  }
              });
              vitals_data.date = vitalDetails['date'];
              vitals_data.source = vitalDetails['source'];
              vitals_data.update_date = vitalDetails['update_date'];
              vitals_data.source_name = vitalDetails['source_name'];
              displayVitalData.push(vitals_data);
        }

        this.setState({displayVitalData});

      }

      applySearchFilter = async (values) => {

        // fetching vitals data
        const { patient_id, spin_loading } = this.state;
        var PARAMS = {
            facilityId: parseInt(LS_SERVICE.get('FACILITY_ID')),
            patientId: parseInt(patient_id),
            doctorId: parseInt(LS_SERVICE.get('staff_id')),
            type: 'vitals'
        }
        this.setState({spin_loading: !spin_loading});
        const getVitalsPromise = await Axios.post(OBJECTIVE_GETALL_IPD, qs.stringify(PARAMS))
        if (getVitalsPromise.data.status) {
            const { data } = getVitalsPromise.data;
            const { details, constants } = data;
            this.setState({
                vitalOriginalData: getVitalsPromise.data.data,
                visitVitals: Object.values(details.visitVitals),
                V001: details?.visitVitals[0]?.V001,
                V002: details?.visitVitals[0]?.V002,
                constants,
                objective_data_loaded: true,
                vitalsConstant: constants.vitals_details,
            }, ()=>{this.setState({spin_loading: false})})
        }

        const { from_date, to_date, vitalOriginalData, visitVitals } = this.state;
        const { details, constants } = vitalOriginalData;

        let vitalsData = Object.values(details.visitVitals);

        // filter vital data based on dates
        vitalsData = vitalsData.filter(item => { return (moment(item.update_date).isAfter(moment(from_date),'minutes') && moment(item.update_date).isBefore(moment(to_date),'minutes')) || moment(item.update_date).isSame(moment(from_date),'minutes') || moment(item.update_date).isSame(moment(to_date),'minutes')});

        var newObj = {};
        if(typeof vitalsData != 'undefined' && vitalsData.length > 0){
            for(let i = 0 ; i < vitalsData.length;  i++){
                    newObj[i] = vitalsData[i];
            }
            vitalOriginalData.details.visitVitals = newObj;
            this.setState({vitalOriginalData, isVitalsSearched: true});
            this.setState({
                visitVitals: Object.values(vitalOriginalData.details.visitVitals),
                V001: vitalOriginalData.details?.visitVitals[0]?.V001,
                V002: vitalOriginalData.details?.visitVitals[0]?.V002,
                constants,
                objective_data_loaded: true,
            }, () => { this.makeVitalsData() });
        }else{
            this.setState({vitalOriginalData, isVitalsSearched: true});
            this.setState({
                visitVitals: "",
                V001: "",
                V002: "",
                constants,
                objective_data_loaded: true,
            }, () => { this.makeVitalsData() });

        }

        this.makeVitalsPrintData(vitalsData);
      }

      vitalPrintModalClose = () => {
        this.setState({vitalPrint: false});
      }

      getSourceDisplayName(source, date, update_date) {
        let sourceName = source ? source[0].toUpperCase() + source.substring(1) : '';
        let time = moment(update_date).format('DD MMM, h:mm A');
        return source == 'patient' && date && update_date && date != update_date ? sourceName + ' | ' + time : sourceName;
    }

    printVitals = () => {
        this.setState({ vitalPrint: false });
        window.frames["print_frame"].document.body.innerHTML = document.getElementById("print-container").innerHTML;
        window.close();
        window.frames["print_frame"].window.focus();
        window.frames["print_frame"].window.print();
      }

    handleVitalsPrint() {
        const { isVitalsSearched, visitVitals } = this.state;
        if (isVitalsSearched) {
            this.setState({ vitalPrint: true });
        }else{
            this.makeVitalsPrintData(visitVitals);
            this.setState({ vitalPrint: true });
        }
    }

    onClearSearch(){
        this.searchFormRef.current.setFieldsValue({
            from_date:null,
            to_date: null
        })
        this.setState({from_date:"", to_date:"", isVitalsSearched: false });
        this.handleObjectiveGetAllData();
    }
    disabledSearchDate = (current, type) => {
        if(type == 'to'){
            if (this.state.from_date) {
                 return current && current < moment(this.state.from_date)   
            }
        }else{
            if (this.state.to_date) {
                return current && current > moment(this.state.to_date).endOf('day')   
           }
        }
      }
    render() {

        const { objective_data_loaded, formModeEdit} = this.state;
        return (
            <>
                <div id="main">
                    <div className="row">
                        <div className="col-md-12 col-xs-12">
                            <div className="row" style={{ height: 'calc(100vh - 300px)', overflowY: 'auto', display: this.state.dataloaded == false ? '' : 'none' }}>
                                <div className="col-md-12 col-xs-12">
                                  <div className="text-center">{LOADER_RED}</div>
                                    : null}
                                </div>
                            </div>
                            <div className="row mt-2" style={{ display: this.state.dataloaded == true ? '' : 'none' }}>
                                {objective_data_loaded ? (
                                    <>
                                        <div className="col-12 mb-1">
                                            <div className="card rounded-0 cardbg p-2">
                                                    <Form
                                                        id="filter"
                                                        onFinish={this.applySearchFilter}
                                                        ref={this.searchFormRef}
                                                        layout="vertical"
                                                        autoComplete='off'
                                                        // initialValues={{to_date: moment()}}
                                                        className='emrfrm'
                                                    >
                                                        {this.props.bannerHide == "patientChartSumry" ? '' :
                                                            <div className='row mt-2'>
                                                            <div className='col-12'>
                                                                <h6 className="page-title">Vitals</h6>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className='row mt-2'>
                                                           
                                                            <div className='col-2'>
                                                                <div id="from_date" className="antInputItem hasdata form-group position-relative w-100">
                                                                    <Form.Item name="from_date" id="from_date" label="From Date/Time" rules={[{
                                                                        required: true,
                                                                        message: 'from date is required!'
                                                                    }]} >
                                                                        <DatePicker
                                                                            scrollableYearDropdown
                                                                            showTime
                                                                            yearDropdownItemNumber={100}
                                                                            format='DD MMM, YYYY | hh:mm A'
                                                                            disabledDate={(current) => this.disabledSearchDate(current, 'from')}
                                                                            placeholder={''}
                                                                            // disabled={this.state.cumulativeBalance}
                                                                            className="form-control datetimepicker"
                                                                            onChange={(date, dateString) => this.handleDatePickerChange(date, dateString, 'from_date')}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className='col-2'>
                                                                <div id="to_date" className="antInputItem hasdata form-group position-relative w-100">
                                                                    <Form.Item name="to_date" id="to_date" label="To Date/Time"
                                                                        rules={[{
                                                                            required: true,
                                                                            message: 'to date is required!'
                                                                        }]}
                                                                    >
                                                                        <DatePicker
                                                                            scrollableYearDropdown
                                                                            showTime
                                                                            format='DD MMM, YYYY | hh:mm A'
                                                                            yearDropdownItemNumber={100}
                                                                            selected={moment()}
                                                                            disabledDate={(current) => this.disabledSearchDate(current, 'to')}
                                                                            placeholder={''}
                                                                            className="form-control datetimepicker"
                                                                            onChange={(date, dateString) => this.handleDatePickerChange(date, dateString, 'to_date')}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>

                                                        <div className='col-5'>
                                                            <button form='filter' className="btn btn-outline-primary px-4 text-uppercase ml-2"  type="submit" disabled={this.state.spin_loading}>
                                                                Search{this.state.spin_loading ? LOADER : ''}
                                                            </button>
                                                            <button form='filter' className="btn btn-outline-primary px-4 text-uppercase mx-3"  onClick={() => this.onClearSearch()}>
                                                                Clear
                                                            </button>
                                                        </div>

                                                        <div className='col-3 text-right'>
                                                        
                                                            <button type="button" className="btn top-chart-btn mx-2"
                                                                onClick={(e) => this.handleModalPopup(e, 'chartModal', { type: 'all', name: 'Trend Graphs' })}>
                                                                <i className="icon-chart"></i>
                                                            </button>
                                                            
                                                        </div>

                                                            
                                                        </div>
                                                    </Form>
                                                </div>
                                                {this.state.vitals_data_formatted > 0 ? (
                                                    <Vitals
                                                        dataLoaded={this.state.vitals_data_formatted}
                                                        constants={this.state.constants}
                                                        visitVitalsFormatted={this.state.visitVitalsFormatted}
                                                        handleModalPopupStateLift={this.handleModalPopup}
                                                    />
                                                ) : null}
                                            
                                        </div>
                                    </>
                                ) : (
                                    <div className="w-100 align-items-center justify-content-center mt-4 d-flex">
                                        {LOADER_RED}
                                    </div>
                                )}
                                {/* || CHART MODAL */}
                                {(this.props.pageFrom === 'CS') ?
                                    //   <Modal
                                    //   // title={`${this.state.chartsType === 'all' ? 'Vital' : this.state.chartsType} Graphs`}
                                    //   title={this.state.chartHeading}
                                    //   visible={this.state.chartModal}
                                    //   onCancel={(e) => this.handleModalPopup(e, 'chartModal')}
                                    //   style={{ top: '40px', maxWidth: this.state.chartsType === 'all' ? '1200px' : '600px' }}
                                    //   width="100%"
                                    //   destroyOnClose={true}
                                    //   className={`${this.state.chartsType === 'all' ? 'all_vital' : 'single_vital'}`}
                                    //   bodyStyle={{ maxHeight: '500px', overflowY: 'auto' }}
                                    //   footer={null}>
                                    <div style={{
                                        width: this.state.chartsType === 'all' ? '1200px' : '600px', height: 'auto', border: '1px solid #ccc', background: 'white', padding: '10px',
                                        boxShadow: '0px 0px 5px #eee', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', maxHeight: '500px', overflowY: 'auto',
                                        zIndex: 99999
                                    }} className={this.state.chartModal ? 'd-block' : 'd-none'}>
                                        <div className="d-flex justify-content-between">
                                            <h5 className="font-weight-normal">{this.state.chartHeading}</h5>
                                            <i className="his-icon icon-cancel cursor-p " style={{ top: '0px', fontSize: '40px', color: '#ccc', marginTop: '0px' }} onClick={(e) => this.handleModalPopup(e, 'chartModal')}></i>
                                        </div>
                                        <div className="">
                                            <ul className="chart_filter">
                                                <li>
                                                    <button type="button" className={`btn btn-default chart-day ${this.state.chartsTab === '0' ? 'active' : ''}`}
                                                        onClick={(e) => this.handleGraphClick(e, '0')}>DAY</button>
                                                </li>
                                                <li>
                                                    <button type="button" className={`btn btn-default chart-week ${this.state.chartsTab === '1' ? 'active' : ''}`}
                                                        onClick={(e) => this.handleGraphClick(e, '1')}>WEEK</button>
                                                </li>
                                                <li>
                                                    <button type="button" className={`btn btn-default chart-month ${this.state.chartsTab === '2' ? 'active' : ''}`}
                                                        onClick={(e) => this.handleGraphClick(e, '2')}>MONTH</button>
                                                </li>
                                                <li>
                                                    <button type="button" className={`btn btn-default chart-year ${this.state.chartsTab === '3' ? 'active' : ''}`}
                                                        onClick={(e) => this.handleGraphClick(e, '3')}>YEAR</button>
                                                </li>
                                            </ul>
                                        </div>
                                        {this.state.chartDataLoaded ? (
                                            <Chart
                                                chartData={this.state.chartData}
                                                dataLoaded={this.state.chartDataLoaded}
                                                chartsType={this.state.chartsType}
                                                reference_id={null}
                                                checkBoxDisable={true}
                                                chartGraphSetting={this.state.chartGraphSettings}
                                                chartsTab={this.state.chartsTab}
                                                constants={this.state.constants}
                                                updateData={this.chartStateUpdate} />
                                        ) : null}
                                    </div>
                                    :
                                    <Modal
                                        // title={`${this.state.chartsType === 'all' ? 'Vital' : this.state.chartsType} Graphs`}
                                        title={this.state.chartHeading}
                                        visible={this.state.chartModal}
                                        onCancel={(e) => this.handleModalPopup(e, 'chartModal')}
                                        style={{ top: '40px', maxWidth: this.state.chartsType === 'all' ? '1200px' : '600px' }}
                                        width="100%"
                                        destroyOnClose={true}
                                        className={`${this.state.chartsType === 'all' ? 'all_vital' : 'single_vital'}`}
                                        bodyStyle={{ maxHeight: '500px', overflowY: 'auto' }}
                                        footer={null}>
                                        <div className="col wrap_chart_filter">
                                            <ul className="chart_filter">
                                                <li>
                                                    <button type="button" className={`btn btn-default chart-day ${this.state.chartsTab === '0' ? 'active' : ''}`}
                                                        onClick={(e) => this.handleGraphClick(e, '0')}>DAY</button>
                                                </li>
                                                <li>
                                                    <button type="button" className={`btn btn-default chart-week ${this.state.chartsTab === '1' ? 'active' : ''}`}
                                                        onClick={(e) => this.handleGraphClick(e, '1')}>WEEK</button>
                                                </li>
                                                <li>
                                                    <button type="button" className={`btn btn-default chart-month ${this.state.chartsTab === '2' ? 'active' : ''}`}
                                                        onClick={(e) => this.handleGraphClick(e, '2')}>MONTH</button>
                                                </li>
                                                <li>
                                                    <button type="button" className={`btn btn-default chart-year ${this.state.chartsTab === '3' ? 'active' : ''}`}
                                                        onClick={(e) => this.handleGraphClick(e, '3')}>YEAR</button>
                                                </li>
                                            </ul>
                                        </div>
                                        {this.state.chartDataLoaded ? (
                                            <Chart
                                                chartData={this.state.chartData}
                                                dataLoaded={this.state.chartDataLoaded}
                                                chartsType={this.state.chartsType}
                                                reference_id={null}
                                                checkBoxDisable={true}
                                                chartGraphSetting={this.state.chartGraphSettings}
                                                chartsTab={this.state.chartsTab}
                                                constants={this.state.constants}
                                                updateData={this.chartStateUpdate} />
                                        ) : null}
                                    </Modal>}

                                <iframe name="print_frame" id="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>
                            </div>
                            {this.props.pageFrom === 'CS' ?
                                <div className="row mt-2" style={{ display: this.state.dataloaded == true ? '' : 'none' }}>
                                    <div className="col-12 text-right" >
                                        <Button type="primary" onClick={() => this.handleCaseSheetSummaryVitals()} htmlType="button" className="btn btn-primary">
                                            ADD TO DOCUMENTS
                                        </Button>
                                    </div>
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default VitalCharting;
