import React, { Component, Fragment } from 'react';
import {
  LOADER_BLACK,
  STORE_PATIENT_ADMIT_ORDERS,
  GET_PATIENT_ADMIT_ORDERS
} from '../../utils/constant';
import { speechService, speechTextService } from '../../utils/rxjs-sharing';
import { notification } from "antd";
import LS_SERVICE from '../../utils/localStorage';
import axios from 'axios';
import Axios from 'axios';
import { Link } from "react-router-dom";
import { jQueryLabels } from '../../utils/jQueryLabels';

class AdmitOrdersForm extends Component {
  	constructor(props) {
	  	super(props);
	    this.state = {
	    	patient: this.props.patient,
	    	allowEdit: this.props.allowEdit ?? true,
	    	admissionRemarks : '',
	    	admitInstructions : '',
	    	saveBtnDisable: true,
	    	admissionAdvisedDisabled: this.props.admissionAdvisedDisabled,
	    	speech: {
	        	identifier: null,
	        	state: false
	      	},
			admit_order_message: false,
	    }
	}

	componentDidMount = async() => {
	    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get('token');
	    jQueryLabels();	  
	    await this.getAdmitOrders();
	    this.subscription = speechTextService.status().subscribe(async data => {
	      	const { patient } = this.state;
	      	if (this.state.speech.state) {
	        
	        if (this.state.speech.identifier === 'admissionRemarks' || this.state.speech.identifier === 'admitInstructions') {
	          if (this.state.speech.identifier === 'admitInstructions') {
	            this.setState({ admitInstructions: data.text }, () => { this.handleConfirmBtnStatus() });
	            LS_SERVICE.set("admitInstructions_" + patient.patient_id + "_" + patient.qms_token_id, data.text);
	          }
	          if (this.state.speech.identifier === 'admissionRemarks') {
	            this.setState({ admissionRemarks: data.text }, () => { this.handleConfirmBtnStatus() });
	            LS_SERVICE.set("admissionRemarks_" + patient.patient_id + "_" + patient.qms_token_id, data.text);
	          }
	          document.querySelector("#" + this.state.speech.identifier).style.height = document.querySelector("#" + this.state.speech.identifier).scrollHeight + "px";
	        }
	      }
	    });  

  	}

  	async getAdmitOrders() {
        const { patient } = this.state;
        var reference_type = 0;
        if(LS_SERVICE.get('reference_type') == 2){
          reference_type = 2;
        }
        const admit_order_data = await Axios.get(GET_PATIENT_ADMIT_ORDERS({ patient_id: patient.patient_id, reference_type: reference_type, reference_id: patient.qms_token_id}));
        if (typeof admit_order_data.data != 'undefined' && typeof admit_order_data.data.data != 'undefined') {
			await this.setState({
				admissionRemarks: admit_order_data.data.data.admission_remarks,
				admitInstructions: admit_order_data.data.data.admit_instructions,
			});
			if(this.state.admitInstructions != "")
	  		{
	  			this.setState({ saveBtnDisable : false });
	  		}
	  		else
	  		{
	  			this.setState({ saveBtnDisable : true });
	  		}
        }
    }

  	handleConfirmBtnStatus = () => {
  		if(this.state.admitInstructions != "" || this.state.admissionRemarks != "")
  		{
  			this.setState({ saveBtnDisable : false });
  		}
  		else
  		{
  			this.setState({ saveBtnDisable : true });
  		}
    
  	}

  	componentDidUpdate(prevProps, prevState) {
	    setTimeout(() => {
	        let Elem = document.querySelectorAll('.speech-field');
	        Elem.forEach(function (el) {
	            el.style.height = el.scrollHeight + "px";
	        })
	    }, 100);
  	}

  	handleOnBlur = event => {
        const { name, value } = event.currentTarget;
        var identifierValue = event.target.id;

        this.setState((prevState) => ({
          [name]: value,
          speech: {
            ...prevState.speech,
            identifier: identifierValue,
            initialText: value
          }
        }), () => {  this.handleConfirmBtnStatus();  })
    }

    handleOnChange = event => {
        const { name, value } = event.currentTarget;
        var identifierValue = event.target.id;

        this.setState((prevState) => ({
          [name]: value,
          speech: {
            ...prevState.speech,
            identifier: identifierValue,
            initialText: value
          }
        }), () => {  this.handleConfirmBtnStatus();  })
    }

  	componentWillUnmount() {
	    this.subscription.unsubscribe();
  	}
  	autoHeight = (e) => {
	    if (e) {
	      e.target.style.height = "auto";
	      e.target.style.height = e.target.scrollHeight + "px";
	    }
  	}

  	initSpeech(info) {
	    let initialText = '';

	    if (info.identifier === 'admissionRemarks') {
	      initialText = this.state.admissionRemarks != undefined ? this.state.admissionRemarks : '';
	    }
	    if (info.identifier === 'admitInstructions') {
	      initialText = this.state.admitInstructions != undefined ? this.state.admitInstructions : '';
	    }

	    if (info.identifier === this.state.speech.identifier) {

	      	this.setState({
		        speech: {
		          identifier: info.identifier,
		          state: !this.state.speech.state,
		          initialText: initialText
		        }
	      	}, () => {
		        speechService.init(this.state.speech);
	      });
	      console.log(this.state.speech);
	    }
    	else {
			// console.log("IN Different MIC Previous Unclosed")
			this.setState({
				speech: {
					identifier: this.state.speech.identifier,
					state: false,
					initialText: ''
				}
			}, () => {
			speechService.init(this.state.speech);

			if (!this.state.speech.state) {
				this.handleRestartMic(info, initialText)
			}

			});

    	}

  	}

  	handleRestartMic = (info, initialText) => {
	    // debugger
	    this.setState({
	      speech: {
	        identifier: info.identifier,
	        state: true,
	        initialText: initialText
	      }
	    }, () => {
	      speechService.init(this.state.speech);
	    });

  	}

  	handleOnFocus = (e) => {
	    var identifierValue = this.state.speech.identifier === e.target.id ? e.target.id : this.state.speech.identifier;
	    this.setState(prevState => ({
	      speech: {
	        ...prevState.speech,
	        identifier: identifierValue,
	        state: false,
	        initialText: identifierValue === "admissionRemarks" ? this.state.admissionRemarks : this.state.admitInstructions
	      },
	    }), () => {
	      speechService.init(this.state.speech)
	    })

  	}

  	handleFormSubmit = async () => {
        const { admissionRemarks, admitInstructions, patient } = this.state;

        const { qms_token_id } = patient;
        const PARAMS = {
          patient_id: patient.patient_id,
          mhea_patient_id: patient.mhea_patient_id,
          reference_id: qms_token_id,
          admission_remarks: admissionRemarks,
          admit_instructions: admitInstructions,
          added_by : LS_SERVICE.get("staff_id")
        };
        if(LS_SERVICE.get('reference_type') == 2){
          var called_homecare = LS_SERVICE.get('last_called_homecare');
          PARAMS.mhea_bill_no = called_homecare?.mhea_bill_no;
          PARAMS.mhea_bill_id = called_homecare?.mhea_bill_id;
          PARAMS.encounter_no = called_homecare?.encounter_no;
          PARAMS.encounter_id = called_homecare?.enocunter_id;
          PARAMS.reference_type = 2;
        }
        else{
          PARAMS.encounter_id = patient.qmsToken.Booking.encounter_id;
          PARAMS.reference_type = 0;
        }
        let submitPromise;
          submitPromise = await axios.post(STORE_PATIENT_ADMIT_ORDERS, PARAMS);

        if (submitPromise.data?.status) {
        	this.setState({
				admit_order_message: true,
			});
			notification.success({
				message: 'Admit Order Saved Succesfully',
				placement: "topRight",
			});
			this.props.handleAdmitOrderModal();
        }
    }

	render() {
		return (
			<>
				<div className='row'>
	          <div className='col-12'>
	            <div className={ this.state.admissionRemarks != '' ? 'form-group micwrap ant-mic-wrap hasdata' : 'form-group micwrap ant-mic-wrap'}>
	              <Link
	                    onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: 'admissionRemarks' }) }}
	                    className={(this.state.speech.identifier === 'admissionRemarks' && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} >
	                  </Link>
	              <textarea
	                className={"form-control speech-field show-placeholder"}
	                id="admissionRemarks"
	                value={this.state.admissionRemarks}
	                name="admissionRemarks"
	                onFocus={this.handleOnFocus}
	                onBlur={this.handleOnBlur}
	                onChange={this.handleOnChange}
	                rows="1"
	                onInput={(e) => this.autoHeight(e)}>
	              </textarea>
	              <label htmlFor="admissiondesk_rem">Admission Desk Remarks</label>
	            </div>
	          </div>
	      	</div>

	      	<div className='row mt-4'>
	          <div className='col-12'>
	            	<div className={ this.state.admitInstructions != '' ? 'form-group micwrap ant-mic-wrap hasdata' : 'form-group micwrap ant-mic-wrap'}>
	                <Link
	                      onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: 'admitInstructions' }) }}
	                      className={(this.state.speech.identifier === 'admitInstructions' && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} >
	                    </Link>
	                <textarea
	                  className={"form-control speech-field show-placeholder"}
	                  id="admitInstructions"
	              		  value={this.state.admitInstructions}
	                  name="admitInstructions"
	                  onFocus={this.handleOnFocus}
	                  onBlur={this.handleOnBlur}
	                  onChange={this.handleOnChange}
	                  rows="1"
	                  onInput={(e) => this.autoHeight(e)}>
	                </textarea>
	                <label htmlFor="admint_instr">Admit Instructions for Clinical Team</label>
	            	</div>
	          </div>
	      	</div>

	      	<div className='row mt-4'>
	          <div className="col-12 text-right">
	            <button type="button" onClick={this.handleFormSubmit} disabled={(this.state.saveBtnDisable && this.state.allowEdit) || this.state.admissionAdvisedDisabled} on className="btn btn-primary small px-4 ">SAVE</button>
	          </div>
	      	</div>
	      	<div className="text-center mt-3">
					{this.state.admit_order_message ?
						<span style={{ color: '#548f3a' }}>{this.state.message_success}</span>
						:
						null
					}
				</div>
			</>
		);
  }
}

export default AdmitOrdersForm;