import React, { Component, Fragment } from 'react';
import {
    LAB_TEST_SEARCH, APP_ENV, DRUGS_CONSTANT, LAB_TEST_FAVOURITE, USERTYPES, LAB_TEST_CONSTANTS,
    PLAN_TEST_ADVICED_STORE, PLAN_TEST_ADVICED_LIST, LOADER_BLACK, HANDLETEXTAREAAUTOHEIGHT, NON_FORMULARY_TEST_MAIL, SERVICE_CHARGE_DATA
} from '../../utils/constant';
import LS_SERVICE from '../../utils/localStorage';
import 'antd/dist/antd.css';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import { jQueryLabels, AntSelectLabels, ClearAntList } from '../../utils/jQueryLabels';
import { Form, Modal, Input, Button, Select, AutoComplete, Tooltip } from 'antd';
import debounce from 'lodash/debounce';
const { Option } = Select;
class TestForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTest: [],
            searchResultLoaded: false,
            saveBtnDisable: true,
            editData: null,
            eventBased: props.eventBased ? props.eventBased : false,
            fromPage : props.formPage ? props.formPage : 'normal',
            constants: this.props.constants ?? [],
            testId: null,
            testType: null,
            selectTestValue: '',
            testUrgency: '',
            testInstructions: '',
            testName: '',
            serviceCategoryCode: 'T',
            testViewId: '',
            test_event: '',
            test_lmp_event_duration: '',
            test_lmp_event_duration_type: '',
            start_date: null,
            testRequisitionComments: null,
            testIsrequistion:false,
            isTestFavChecked: 0,
            TestAddListed: [],
            isAlertTestMVisible: false,
            patient: props.isPlanAdviced ? props.patient : null,
            testAdvisedDuplicateOrders: this.props.testAdvisedDuplicateOrders ?? [],
            testDuplicateModal: false,
            selectedTest: [],
            resetForm: props.resetForm,
            allowEdit:this.props.allowEdit??true,
            showAddTestButton: true,
            testFormValidate: true,
            favouriteList: props.favouriteList,
            selectedPackageServices : [],
            ServicePackageModal: false
        }
        this.testSelectRef = React.createRef();
        
        this.TestSearch = debounce(this.TestSearch, 800);
        this.setState({ selectTestValue: '' });
    }
    showAlertTestModal = () => { this.setState({ isAlertTestMVisible: true }) };
    handleAlertTestOk = () => { this.setState({ isAlertTestMVisible: false }); this.TestOndelete(this.state.deleteTestId) };
    handleAlertTestCancel = () => { this.setState({ isAlertTestMVisible: false }) };

    async componentDidMount() {
        axios.defaults.headers.common["Authorization"] = LS_SERVICE.get('token');
        jQueryLabels();
        setTimeout(() => this.TestSearch(''), 500);
        if(this.props.editData != null && this.props.actionText == 'Edit'){
            let {favouriteList} = this.state;
            console.log(this.props.editData);
            this.setState({
                saveBtnDisable: false, 
                editData : this.props.editData,
                testId: this.props.editData.test_id,
                testName: this.props.editData.test_name,
                serviceCategoryCode: this.props.editData.package_test_type,
                testType: this.props.editData.type,
                testUrgency: (this.props.editData.urgency)? this.props.editData.urgency:  (this.state.showAddTestButton)? 49 : '',
                testInstructions: this.props.editData.instructions,
                selectTestValue: this.props.editData.test_name
            })
            if (favouriteList && favouriteList.length > 0) {
                for (let index = 0; index < favouriteList.length; index++) {
                    const test = favouriteList[index];
                    if(test.serviceId == this.props.editData.test_id ){
                        this.setState({isTestFavChecked : 1 });
                        break;
                    }   
                }
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
      if(this.state.eventBased != prevState.eventBased){
        this.checkTestSaveBtn();
      }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            eventBased: nextProps.eventBased,
            testAdvisedDuplicateOrders: nextProps.testAdvisedDuplicateOrders
        };
    }

    handleFormReset = _ => {
        this.setState({
            resetForm: false
        }, () => this.Formclear())
    }

    testInstructionChange = (e) => {
        this.setState({ testInstructions: e.target.value }, () => { HANDLETEXTAREAAUTOHEIGHT('test-instructions') })
    }
    TestFavorite = (id, Lab, urgency, instructions) => {
        this.setState({
            isTestFavChecked: this.state.isTestFavChecked ? 0 : 1
        })

        const elem = document.querySelector('#test-name').parentElement?.nextSibling?.querySelector('.desc');
        let searchKeys = elem !== undefined ? elem.innerText : document.querySelector('#test-name').value;
        searchKeys = searchKeys.replace(/[^\w\s]/gi, ' ');

        axios.post(LAB_TEST_FAVOURITE, qs.stringify({
            id: id,
            type: 'Service',
            urgency,
            instructions,
            hospital_id: LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('slot_hospital_id') : null
        })).then(success => {
            if (success.data.status) {
                let searchResults = this.state.searchTest;
                searchResults.map((obj, i) => {
                    if (obj.serviceId == id) {
                        searchResults[i].fav = (searchResults[i].fav && searchResults[i].fav == 1) ? 0 : 1
                    }
                })
                this.setState({
                    searchTest: searchResults
                })
                // this.onDrugTestssearch(searchKeys);
            }
        }).catch(err => {
            this.setState({
                isTestFavChecked: this.state.isTestFavChecked ? 0 : 1
            })
        })
    }

    optionTestLoad() {
        if (this.state.searchTest !== undefined && this.state.searchTest.length > 0) {
            return this.state.searchTest.map((obj, i) => {
                const isRequisition = !(obj.isRequistion === null || obj.isRequistion === false || obj.isRequistion === undefined || obj.isRequisition === 'N');
                return (<Option key={i} testid={obj.testId} value={obj.testId} disabled={this.props.addedTestList && this.props.addedTestList.includes(obj.testId)}
                    isrequistion={isRequisition ? 'true' : 'false'}
                    urgency={obj.urgency}
                    instructions={obj.instructions}
                    type={obj.type} fav={obj.fav} title={obj.name}>
                    <span className="desc">
                        {obj.name}
                    </span>
                    {
                      (LS_SERVICE.get('user_type') == USERTYPES.doctor)
                    ?
                      <span className="float-right text-primary">
                          <i className={obj.fav == 1 ? 'icon_unique icon_star favourite align-star' : 'icon_unique icon_star align-star'}></i>
                      </span>
                    : 
                      null
                    }
                </Option>
                )
            })
        } else return null;
    }
    optionReqViewLoad() {
        if (!this.state.TestConstants || this.state.TestConstants.length === 0) {
            return (<Option value={0}><span>No Result Found</span></Option>)
        }
        return this.state.TestConstants.map((obj, i) => {
            return (<Option key={i} value={obj.id} title={obj.description}>{obj.description}</Option>
            )
        })
    }
    optionPriorityLoad() {
        if (!this.state.constants.drugUrgency || this.state.constants.drugUrgency.length === 0) {
            return (<Option value={0}><span>No Result Found</span></Option>)
        }
        return this.state.constants.drugUrgency.map((obj, i) => {
            return (<Option key={i} value={obj.id} title={obj.description}>{obj.description}</Option>
            )
        })
    }

    optionEventLoad() {
        if (!this.state.constants.drugEvent || this.state.constants.drugEvent.length === 0) {
            return (<Option value={0}><span>No Result Found</span></Option>)
        }
        return this.state.constants.drugEvent.map((obj, i) => {
            return (<Option key={i} value={obj.id} title={obj.description}>{obj.description}</Option>
            )
        })
    }
    onselectPriorityChange = (val, props) => {
        this.setState({ testUrgency: val })
    }
    onselectReqViewChange = (val, props) => {
        this.setState({ view_id: val })
    }

    onreqcommentschange = (e) => {
        this.setState({ testRequisitionComments: e.target.value })
    }


    autoHeight = (e) => {
        e.target.style.height = e.target.scrollHeight + "px";
    }

    TestSearch = (search) => {
        this.setState({
            searchResultLoaded: true,
            saveBtnDisable: this.state.editData!= null ? false : true
        })
        let params = {search: search};
        params.distinct = 'Y';
        if(LS_SERVICE.get("user_type") == USERTYPES.admin){
            params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('admin_hospital_id') : null
        } else if(LS_SERVICE.get("user_type") == USERTYPES.superadmin){
            params.hospital_id = LS_SERVICE.get('master_hospital_id')??null;
        } else {
            params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('slot_hospital_id') : null;
        }
        if(APP_ENV == 'mhea'){
          params.active = true;
          params.service_name = search;
          params.facility_id = LS_SERVICE.get('FACILITY_ID');
          params.filter_service_type = 'I,P,O';
          if(this.state.fromPage == 'main_page')
          {
            params.filter_service_type = 'I,P,O,OPP';
          }
          params.doctor_id = LS_SERVICE.get('staff_id')
        }
        axios.post(LAB_TEST_SEARCH, qs.stringify(params)).then(success => {
            var testList = success.data?.test_list || [];
            var finaltestlist = []
            if(testList.length >0)
            {
                for (var index = 0; index < testList.length; index++) {
                if(testList[index].patientClassOp == true){
                    finaltestlist.push(testList[index])
                } 
                }
            }
            this.setState({
                searchTest: finaltestlist,
                searchResultLoaded: false,
                showAddTestButton: (search.length > 2 && finaltestlist.length === 0 && this.props.formType != 'template') ? false : true,
                testFormValidate: true,
            }, () => { AntSelectLabels(); });
        }).catch(err => console.log(err))
    }

    Formclear = () => {

        if(this.state.editData != null){
            this.setState({
                saveBtnDisable: true,
                testUrgency: 49,
                testInstructions: ''
            }, () => { AntSelectLabels(); this.TestSearch(''); HANDLETEXTAREAAUTOHEIGHT('test-instructions', true) })
        }else{
            this.setState({
                saveBtnDisable: true,
                selectTestValue: '',
                testUrgency: '',
                testInstructions: '',
                testName: '',
                serviceCategoryCode: 'T',
                testViewId: '',
                testIsrequistion:false,
                testRequisitionComments: '',
                test_lmp_event_duration: '',
                test_lmp_event_duration_type: '',
                
                isTestFavChecked: 0,
                testId: null,
                testType: null,
                selectedPackageServices: [],
            }, () => { AntSelectLabels(); this.TestSearch(''); HANDLETEXTAREAAUTOHEIGHT('test-instructions', true) })
        }
    }

    checkTestSaveBtn = () => {
      let event = this.state.test_event;
      let lmp_event_duration = this.state.test_lmp_event_duration;
      let lmp_event_duration_type = this.state.test_lmp_event_duration_type;
      if(this.props.formType == 'template'){
        if(this.props.eventBased == true) {
          if (this.checkEmptyValue(event)) {
            this.setState({saveBtnDisable: true})
          }
          else{
            if(event == 1 || event == 2){
              if (this.checkEmptyValue(lmp_event_duration)) this.setState({saveBtnDisable: true})
              else if (this.checkEmptyValue(lmp_event_duration_type)) this.setState({saveBtnDisable: true})
              else{this.setState({saveBtnDisable: false})}
            }
            else{
              this.setState({saveBtnDisable: false})
            }
          }
        }
        else {this.setState({saveBtnDisable: false})}
      }
      else {
        //   this.setState({saveBtnDisable: false});
        }
    }

    checkEmptyValue(val) {
      return typeof val == 'undefined' || val == null || val == '' ? true : false;
    }

    getServiceCharge = async () => {
        let PARAMS = {
          active: 1,
          facilityId: LS_SERVICE.get('FACILITY_ID'),
          payorId: LS_SERVICE.get('PATIENT_REG_DATA')?.registrationInsuranceList ? LS_SERVICE.get('PATIENT_REG_DATA').registrationInsuranceList[0].payorId : 0,
          sponsorId: LS_SERVICE.get('PATIENT_REG_DATA')?.registrationInsuranceList ? LS_SERVICE.get('PATIENT_REG_DATA').registrationInsuranceList[0].sponsorId : 0,
          payorPlanId: LS_SERVICE.get('PATIENT_REG_DATA')?.registrationInsuranceList ? LS_SERVICE.get('PATIENT_REG_DATA').registrationInsuranceList[0].planId : 0,
          serviceId: this.state.testId,
          billCategoryId: 0,
          serviceCategoryCode: 'OPP',
          doctorId: 0,
          registrationId: LS_SERVICE.get('PATIENT_REG_DATA')?.registrationid ? LS_SERVICE.get('PATIENT_REG_DATA').registrationid : 0,
          encounterId: 0,
          encounterTypeCode: 'OP',
          enterpriseId: LS_SERVICE.get('ENTERPRISE_ID'),
          registration_type_id: LS_SERVICE.get('PATIENT_REG_DATA')?.registration_type_id ? LS_SERVICE.get('PATIENT_REG_DATA').registration_type_id : 0,
          speciality_id: 0,
          marital_status_id: LS_SERVICE.get('PATIENT_REG_DATA')?.marital_status_id ? LS_SERVICE.get('PATIENT_REG_DATA').marital_status_id : 0,
          gender_code: "",
          occupation_id: LS_SERVICE.get('PATIENT_REG_DATA')?.occupation_id ? LS_SERVICE.get('PATIENT_REG_DATA').occupation_id : 0,
          state_id: LS_SERVICE.get('PATIENT_REG_DATA')?.state_id ? LS_SERVICE.get('PATIENT_REG_DATA').state_id : 0,
          city_id: LS_SERVICE.get('PATIENT_REG_DATA')?.city_id ? LS_SERVICE.get('PATIENT_REG_DATA').city_id : 0,
          area_id: LS_SERVICE.get('PATIENT_REG_DATA')?.area_id ? LS_SERVICE.get('PATIENT_REG_DATA').area_id : 0,
          serviceOrderDate: moment(new Date()).format(),
          admissionDate: moment(new Date()).format(),
        }
        axios.post(SERVICE_CHARGE_DATA, qs.stringify(PARAMS))
        .then(async success => {
            let PackageServicesData = success.data.data.filter(psd => psd.underPackage == true);
            if(PackageServicesData.length > 0)
            {
                await this.setState({ selectedPackageServices : PackageServicesData });
            }
        })
        .catch(err => console.log(err))
    }

    onTestSelect = (value, props) => {
        var result;
        if(props.children.length > 0){
        if(props.children[0].props.children != undefined){
            var propsValue = props.children[0].props.children;
            result = propsValue.substring(0,5);
        }
        }else{
        result = "";
        }
        this.setState({testIsrequistion : false}) 
        let params = {searchMultiServiceId: ''};
        if(APP_ENV == 'mhea'){
          params.active = true;
          params.service_name = result;
          params.facility_id = LS_SERVICE.get('FACILITY_ID');
          params.filter_service_type = 'I,P,O';
          if(this.state.fromPage == 'main_page')
          {
            params.filter_service_type = 'I,P,O,OPP';
          }
          params.doctor_id = LS_SERVICE.get('staff_id')
        }
        axios.post(LAB_TEST_SEARCH, qs.stringify(params)).then(async success => {
            var testList  =  success.data.test_list;
            if(testList && testList.length > 0){
                for (var index = 0; index < testList.length; index++) {
                    if(value == testList[index].serviceId){
                        this.setState({serviceCategoryCode : testList[index].serviceCategoryCode}) 
                        if(testList[index].serviceCategoryCode == 'OPP' && this.state.fromPage == 'main_page'){
                            await this.getServiceCharge();
                        }
                        else
                        {
                            this.setState({selectedPackageServices : []})
                        }
                        if(testList[index].requisitionFormRequired == true && testList[index].requisition_form == 'Y')
                            this.setState({testIsrequistion : true}) 
                        }
                    }

            }
            
        }).catch(err => console.log(err))


        this.setState({
            testId: value,
            testName: props.title,
            testType: props.type,
            isTestFavChecked: props.fav,
            testUrgency: (props.urgency)? props.urgency:  (this.state.showAddTestButton)? 49 : '',
            //testInstructions: props.instructions,
            selectTestValue: props.title,
            saveBtnDisable: false,
            
        }, () => {
            // if (this.props.formType == "soap-full") this.handleDuplicateTest(value, props.title)
            this.handleDuplicateTest(value, props.title)
            AntSelectLabels();
            this.checkTestSaveBtn();
            HANDLETEXTAREAAUTOHEIGHT('test-instructions')
        });
    };

    handleDuplicateTest = (test_id, test_name) => {
        let { selectedTest } = this.state;
        let testAdvisedDuplicateOrders = this.state.testAdvisedDuplicateOrders;
        if (testAdvisedDuplicateOrders !== null && testAdvisedDuplicateOrders?.length > 0) {
            selectedTest = testAdvisedDuplicateOrders.filter(tad => tad.test_id == test_id);
            selectedTest = selectedTest[selectedTest.length - 1]
            if (selectedTest !== undefined) {
                selectedTest['title'] = test_name;
                this.setState({
                    selectedTest
                }, () => {
                    this.handleModalPopup(null, 'testDuplicateModal');
                })
            }
        }

    }

    onTestSearch = (value) => {
        this.TestSearch(value);
        this.setState({ selectTestValue: value });
    }

    addTestlist = async (val) => {



        if (this.props.isPracticing === false) {
            this.props.practiceModal('isPracticingModal');
            return;
        }

        if(this.state.eventBased == true){
          if(!this.state.test_event){
            await this.setState({
              test_event: 3,
              test_lmp_event_duration: null,
              test_lmp_event_duration_type: null,
              // start_date: moment().format('YYYY-MM-DD')
            })
          }
        }

        let testObj = {
            test_id: this.state.testId,
            test_name: this.state.testName,
            urgency: this.state.testUrgency,
            type: this.state.testType,
            view_id: this.state.testViewId,
            requisition_comments: this.state.testRequisitionComments,
            is_requistion: this.state.testIsrequistion,
            instructions: this.state.testInstructions,
            id: this.state.editData != null ? this.state.editData.id : null,
            event: this.state.test_event || null,
            lmp_event_duration: this.state.test_lmp_event_duration || null,
            lmp_event_duration_type: this.state.test_lmp_event_duration_type || null,
            // start_date: this.state.start_date,
            start_date: null,
            i: this.props.editIndex,
            fav: this.state.isTestFavChecked,
            isEdit: this.state.editData != null ? true : false,
            package_test_type : this.state.serviceCategoryCode,
        }

        if(this.state.fromPage == 'main_page')
        {
            testObj.selectedPackageServices = this.state.selectedPackageServices;
        }

        if(!this.state.testFormValidate){
            let testUrgency = null;
            this.state.constants.drugUrgency.filter((element) => {
                if(element.id == this.state.testUrgency){ testUrgency = element.description }
            })

            let params = {
              hospital_id: this.props.hospital_id,
              name: this.state.testName,
              instructions: this.state.testInstructions,
              priority: testUrgency
            }
      
            axios.post(NON_FORMULARY_TEST_MAIL, params)
            .then(success => {
              console.log(success);
            }).catch(err => {
              console.log(err);
            })
        }
        
        this.props.updateData(testObj);
        this.Formclear();

        document.getElementById('test-name').focus();
    }

    handleModalPopup = (e, popup) => {
        if (e !== null)
            e.preventDefault();

        this.setState({
            [popup]: !this.state[popup]
        }, () => {
            const { testDuplicateModal, retestApproved } = this.state;
            if (!testDuplicateModal && !retestApproved) {
                this.Formclear();
            }
        })
    }

    handleServicePackages = async () => {
        this.setState({ ServicePackageModal: true })
    }

    handleModalCancel = async () => {
        this.setState({
          ServicePackageModal: false
        });
    }

    handleReTestApproval = e => {
        this.setState({
            retestApproved: true,
        }, () => { this.handleModalPopup(null, 'testDuplicateModal'); this.addTestlist(); })
    }

    optionLMPEventDurationTypeLoad() {
        return (
          <>
            <Option key={1} value="Days" title="Days">Days</Option>
            <Option key={2} value="Weeks" title="Weeks">Weeks</Option>
            <Option key={3} value="Months" title="Months">Months</Option>
          </>
        )
    }

    onChangeLMPEventDuration = (val) => {
      this.setState({ 
        test_lmp_event_duration: !isNaN(val.target.value) ? val.target.value : null 
      }, () => {
        this.checkTestSaveBtn();
      })
    }

    onChangeLMPEventDurationType = (val) => {
      this.setState({ test_lmp_event_duration_type: val }, () => { this.checkTestSaveBtn() });
    }  

    // handleTextareaAutoHeight = (textarea, clear = false) => {
    //     let Elem = document.querySelectorAll('.' + textarea);
    //     if (!clear) {
    //         Elem.forEach((el) => { el.style.height = el.scrollHeight + "px"; });
    //     } else {
    //         Elem.forEach((el) => { el.style.height = "40px"; });
    //     }
    // }

    onChangeTestEvent = (val) => {
      this.setState({ test_event: val }, () => { this.checkTestSaveBtn() });
    }

    handleAddTest(){
        this.setState({
            showAddTestButton : true,
            saveBtnDisable : false,
            testFormValidate: false,
            testId: null,
            testName: this.state.selectTestValue,
            serviceCategoryCode: this.state.serviceCategoryCode,
            testUrgency: 49
        })
        AntSelectLabels();
    }

    render() {
        const textareaSizeDefault = { minRows: 3, maxRows: 6 };
        return (
            <Fragment>
                <div className="col-12 px-0">
                    <div className="emrfrm">
                        <div className="row">
                            {/* <h2 className="col-12 text-heading">Tests</h2> */}
                            <div className="col-lg-12">
                                <div className="row mt-2">
                                    <div className="col-lg-6 medicine_star">
                                        <div className="form-group add_buttons">
                                            {
                                              this.state.showAddTestButton || this.props.formType == 'template'
                                            ?
                                              null
                                            :
                                              <div className={"btn btn-outline-primary"} onClick={() => this.handleAddTest()}>ADD</div>
                                            }
                                            <AutoComplete
                                                id="test-name"
                                                name="test_id"
                                                style={{ width: 100 + '%' }}
                                                placeholder=" "
                                                showSearch
                                                showArrow={this.state.showAddTestButton}
                                                ref={this.testSelectRef}
                                                notFoundContent={this.state.searchResultLoaded ? LOADER_BLACK : null}
                                                loading={this.state.searchResultLoaded}
                                                // placeholder="Test Name"
                                                // optionFilterProp="children"
                                                onSelect={this.onTestSelect}
                                                onSearch={this.onTestSearch}
                                                className="select-drugs add_button"
                                                //onChange={this.onselectTestChange}
                                                value={this.state.selectTestValue}
                                                filterOption={false}
                                                autoFocus={this.state.selectTestValue != null && this.state.selectTestValue != '' ? false : true}
                                                showAction={this.state.selectTestValue != null && this.state.selectTestValue != '' ? [] : ['focus', 'click']}
                                                disabled={this.state.editData != null}
                                            // filterOption={(input, option) => {
                                            //     return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                                            // }}
                                            >
                                                {this.optionTestLoad()}
                                            </AutoComplete>
                                           
                                            <label htmlFor="test-name">Test Name/Procedure<span className="text-danger">*</span></label>

                                        </div>
                                    </div>
                                    {this.state.selectedPackageServices.length > 0 ? 
                                        <>
                                        <div className="col-lg-1">
                                            <Tooltip placement="topLeft" title="Package Services">
                                                <i className='icon_info cursor-pointer' onClick={() => this.handleServicePackages()}></i>
                                            </Tooltip>
                                        </div>
                                        </>
                                        :
                                        null
                                    }
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <Select showSearch id="urgency" name="urgency" style={{ width: 100 + '%' }}
                                                placeholder="Priority"
                                                onChange={this.onselectPriorityChange}
                                                className="drug-input Priority"
                                                value={this.state.testUrgency}
                                            >
                                                {this.optionPriorityLoad()}
                                            </Select>
                                            <label htmlFor="urgency">Priority{(this.state.testFormValidate)? <span className="text-danger">*</span> : null}</label>
                                        </div>
                                    </div>
                                    {
                                      this.state.eventBased == true && this.props.formType == 'template'
                                    ?
                                      <>
                                        <div className="col-lg-2">
                                          <div className="form-group">
                                            <Select showSearch id="event" name="test_event" style={{ width: 100 + '%' }}
                                              placeholder="Event"
                                              onChange={this.onChangeTestEvent}
                                              className="drug-input Priority"
                                              value={this.state.test_event}
                                            >
                                              {this.optionEventLoad()}
                                            </Select>
                                            <label htmlFor="event">Event<span className="text-danger">*</span></label>
                                          </div>
                                        </div>
                                        {
                                          this.state.test_event == 1 || this.state.test_event == 2
                                        ?
                                          <div className="col-lg-1">
                                            <div className="form-group">
                                              <input
                                                type="text"
                                                className="form-control onlynumbers"
                                                maxLength="2"
                                                data-name="lmp_event_duration"
                                                name="test_lmp_event_duration"
                                                id="lmp_event_duration"
                                                onChange={(e) => { this.onChangeLMPEventDuration(e) }}
                                                value={this.state.test_lmp_event_duration}
                                                placeholder="Due From"
                                              />
                                              <label htmlFor="lmp_event_duration">Due From<span className="text-danger">*</span></label>
                                            </div>
                                          </div>
                                        : null}
                                        {
                                          this.state.test_event == 1 || this.state.test_event == 2
                                        ?
                                          <div className="col-lg-1">
                                            <div className="form-group">
                                              <Select showSearch id="lmp_event_duration_type" name="test_lmp_event_duration_type" style={{ width: 100 + '%' }}
                                                placeholder="Duration"
                                                onChange={this.onChangeLMPEventDurationType}
                                                className="drug-input"
                                                value={this.state.test_lmp_event_duration_type}
                                              >
                                                {this.optionLMPEventDurationTypeLoad()}
                                              </Select>
                                              <label htmlFor="lmp_event_duration_type">Duration<span className="text-danger">*</span></label>
                                            </div>
                                          </div>
                                        : null}
                                        {/* <div className="col-8"></div> */}
                                      </>
                                    :
                                      null
                                    }
                                    <div className="col-lg-10">
                                        <div className="form-group mb-0">
                                            <textarea name="instructions" data-name="instructions" onChange={this.testInstructionChange}
                                                onInput={HANDLETEXTAREAAUTOHEIGHT('test-instructions')} value={this.state.testInstructions}
                                                className="form-control drug-input input-template mandatory-field test-instructions" id="instructions" rows="1" placeholder="Test Instructions"></textarea>
                                            <label htmlFor="instructions">Test Instructions</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 d-flex align-self-center">
                                        {
                                            (LS_SERVICE.get('user_type') == USERTYPES.doctor && this.state.testFormValidate)
                                                ?
                                                <div className="ccustom-control custom-checkbox-favourite fav_drug custom-control-inline border-0 pl-0" id="favourite-test">
                                                    <input type="checkbox" className="custom-control-input" id="favourite" name="favourite" />
                                                    <label className={this.state.isTestFavChecked ? 'custom-control-label text-secondary text-underline cursor-pointer favorite label-star' : 'custom-control-label text-secondary text-underline cursor-pointer label-star'} htmlFor="favourite">
                                                        <Button style={{ width: 116 + 'px', textAlign: 'right' }} className="nostyle-link text-secondary text-underline p-0" onClick={() => this.TestFavorite(this.state.testId, this.state.testType, this.state.testUrgency, this.state.testInstructions)}>FAVOURITE</Button>
                                                    </label>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>

                                </div>
                                <div className="row mt-2" id="requisition-form-container" style={{ display:this.state.testIsrequistion == true ? 'flex' : 'none' }}>
                                    <div className="col-12 my-3">
                                        <div className="card bg-grey">
                                            <div className="card-body">
                                                <h6>Requisition Form</h6>
                                                <div className="row">
                                                    {/* <div className="col-12 col-md-6">
                                                        <div className="form-group">
                                                            <Select 
                                                                id="requisition_test_name" 
                                                                name="requisition_test_name" 
                                                                style={{ width: 100 + '%' }}
                                                                showSearch
                                                                placeholder="Test Name"
                                                                optionFilterProp="children"
                                                                onSearch={this.onDrugTestssearch}
                                                                className="select-drugs"
                                                                onChange={this.onselectTestChange}
                                                                value={this.state.selectTestValue}
                                                                disabled={true}
                                                                filterOption={(input, option) => {
                                                                    return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                                                                }}
                                                            >
                                                                {this.optionTestLoad()}
                                                            </Select>
                                                            <label htmlFor="requisition_test_name">Test Name<span>*</span></label>
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="col-12 col-md-6">
                                                        <div className="form-group bg-white">
                                                            <Select
                                                                id="requisition_view_id"
                                                                name="view_id"
                                                                style={{ width: 100 + '%' }}
                                                                placeholder="View"
                                                                onChange={this.onselectReqViewChange}
                                                                className="drug-input"
                                                                value={this.state.testViewId}
                                                            >
                                                                {this.optionReqViewLoad()}
                                                            </Select>
                                                            <label htmlFor="requisition_view_id">View</label>
                                                        </div>
                                                    </div> */}
                                                    
                                                    <div className="col-12">
                                                        <div className={"hasdata"}>
                                                            <Form.Item 
                                                                name="noi_comments"
                                                                id="noi_comments"
                                                                onChange={(e) => this.onreqcommentschange(e)}
                                                                >
                                                                <Input.TextArea autoSize={textareaSizeDefault}  defaultValue={this.state.testRequisitionComments} showCount maxLength={255} />
                                                            </Form.Item>
                                                            {/* <textarea name="noi-comments" id="noi-comments" data-name="noi-comments" onChange={this.handleChangeComment} value={commentValue} className="form-control drug-input input-template mandatory-field" placeholder="Comments" rows="1"></textarea>
                                                            <label htmlFor="noi-comments">Comments</label> */}
                                                        </div>
                                                    </div>
                                                    {/* {this.props.formType != 'template' ? (
                                                        <>
                                                            <div className="col-md-4 mt-4 mt-md-0">
                                                                <h6 className="mb-3">Diabetic</h6>
                                                                <div className="custom-control custom-radio custom-control-inline mr-5">
                                                                    <input type="radio" id="diabetic-yes" name="is_diabetic" className="custom-control-input" defaultValue={1} autoComplete="off" />
                                                                    <label className="custom-control-label" htmlFor="diabetic-yes">Yes</label>
                                                                </div>
                                                                <div className="custom-control custom-radio custom-control-inline mr-5">
                                                                    <input type="radio" id="diabetic-no" name="is_diabetic" className="custom-control-input" defaultValue={0} defaultChecked autoComplete="off" />
                                                                    <label className="custom-control-label" htmlFor="diabetic-no">No</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 mt-4 mt-md-0">
                                                                <h6 className="mb-3">On Metformin</h6>
                                                                <div className="custom-control custom-radio custom-control-inline mr-5">
                                                                    <input type="radio" id="metformin-yes" name="is_metformin" className="custom-control-input" defaultValue={1} autoComplete="off" />
                                                                    <label className="custom-control-label" htmlFor="metformin-yes">Yes</label>
                                                                </div>
                                                                <div className="custom-control custom-radio custom-control-inline mr-5">
                                                                    <input type="radio" id="metformin-no" name="is_metformin" className="custom-control-input" defaultValue={0} defaultChecked autoComplete="off" />
                                                                    <label className="custom-control-label" htmlFor="metformin-no">No</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 mt-4 mt-md-0">
                                                                <h6 className="mb-3">Risk</h6>
                                                                <div className="custom-control custom-radio custom-control-inline mr-5">
                                                                    <input type="radio" id="risk-yes" name="risk" className="custom-control-input" defaultValue={1} autoComplete="off" />
                                                                    <label className="custom-control-label" htmlFor="risk-yes">Yes</label>
                                                                </div>
                                                                <div className="custom-control custom-radio custom-control-inline mr-5">
                                                                    <input type="radio" id="risk-no" name="risk" className="custom-control-input" defaultValue={0} defaultChecked autoComplete="off" />
                                                                    <label className="custom-control-label" htmlFor="risk-no">No</label>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : null} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-3 mb-0">
                              
                                <div className="text-right">
                                    <Button className="btn-outline-secondary px-4 small text-uppercase mr-3"
                                        id="drug-clear" onClick={() => this.Formclear()}
                                    >Clear</Button>
                                    <Button
                                        type="button"
                                        className="btn btn-primary px-4 small text-uppercase mandatory-save"
                                        id="drug-save"
                                        disabled={!this.state.allowEdit?true:this.state.saveBtnDisable}
                                        onClick={() => this.addTestlist()}
                                    >{this.state.editData != null ? "Update" : "Add"}</Button>
                                </div>
                            </div>
                        </div>
                    </div>



                    {/* || VITAL MODAL */}
                    <Modal
                        title=""
                        visible={this.state.testDuplicateModal}
                        onCancel={(e) => this.handleModalPopup(e, 'testDuplicateModal')}
                        style={{ top: 40 }}
                        closable={false}
                        closeIcon={false}
                        width={800}
                        footer={[
                            <div className="justify-content-center text-center">
                                <div tabindex="20" className="btn btn-outline-secondary cursor-pointer"
                                    onClick={e => this.handleModalPopup(e, 'testDuplicateModal')}>No</div>
                                <button type="submit" className="btn btn-primary text-uppercase ml-2 mandatory-save"
                                    onClick={this.handleReTestApproval}>Yes</button>
                            </div>
                        ]}
                    >
                        <div className="row">
                            <div className="col-12 text-center" id="duplicate-test-text">
                                This order for {this.state.selectedTest.title} has already been placed on{" "}
                                {moment(this.state.selectedTest.createdAt).format('DD MMM, yyyy')}<br />Do you want to proceed?</div>

                            {/*<hr />
                           
                             <div className="row mt-4 test-row" id="test_0">
                                <div className="col-4">
                                    <strong>Hemoglobin A1c Test (HbA1c)</strong>
                                </div>
                                <div className="col-8">
                                    <div className="table-responsive">
                                        <table className="w-100">
                                            <tbody>
                                                <tr><td width="8%">Ordering Physician</td><td width="35%">: <strong>Dr PROF. SATYENDRA NATH BURMAN(MEDI)</strong></td><td width="9%">Test Date</td><td width="10%">: <strong>13 Jan 2020</strong></td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="table-responsive mt-4">
                                        <table className="table table-bordered nob">
                                            <thead>
                                                <tr><td width="20%">Result</td><td width="40%">Reference Interval</td><td width="20%">Unit</td><td width="20%">Trend</td></tr>
                                            </thead>
                                            <tbody>
                                                <tr><td><strong className="text-danger">8</strong></td><td><strong>6.3--8.2</strong></td><td>gm / dl</td><td className="testGraphWidget"><a href="#" data-toggle="modal" id="month_data" className="active"><img src="http://dev.woodlands.opdemr.myhealthcare.co/assets/images/icon-chart.png" alt="Chart" /></a></td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                         */}
                        </div>


                    </Modal>

                     {/* Service Package List Modal */}

                    <Modal
                      visible={this.state.ServicePackageModal}
                      onCancel={() => this.handleModalCancel()}
                      destroyOnClose={true}
                      footer={false}
                      width="980px"
                      className='appointment_modal'
                      title={this.state.testName}
                      
                    >
                      <Form
                        className='emrfrm'
                        layout="vertical"
                      >
                        <div className='row mt-3'>
                          <div className='col-12'>
                            <table className='table rounded-0'>
                              <thead>
                                <tr>
                                  <th width="5%">SR. NO.</th>
                                  <th width="95%">Service Name</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.selectedPackageServices?.map((data, i) => {
                                    return(<tr>
                                      <td className='text-center'>
                                        {i+1}
                                      </td>
                                      <td>{data.serviceName}</td>
                                    </tr>)
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Form>
                    </Modal>
                </div>
            </Fragment>
        );
    }
}

export default TestForm;