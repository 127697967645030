import React, { Component, createRef } from 'react';
import Axios from 'axios';
import { DURATION_TYPE, INFORMED_BY, MEDICATION_ON, FETAL_MOVEMENT, PRESENTATION, RELATIONSHIP_TO_PP_BRIM } from "../../utils/constant";
import moment from "moment";
import ReactToPrint from "react-to-print";
import GPALE from "../../components/gynaecology/GPALE";
import { DatePicker, Radio, Modal, Select, Button, Form, Checkbox, Input, Tooltip, AutoComplete } from 'antd';
import AsideLeft from '../../components/aside/asideleft';
import LS_SERVICE from "../../utils/localStorage";
import AncCard from './anccard';
const { Option } = Select;

export default class Printpreviousanc extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            get_prev_anc_details: props.get_prev_anc_details || [],
            patientInfo: props.patientInfo || [],
            riskfactor: props.riskfactor || [],
            risk_factors_options: props.risk_factors_options || [],
            allergies: props.allergy || [],
            conditions: props.conditions || [],
            menstrualHistoryList: props.menstrualHistoryList || [],
            physicalExamination: props.physicalExaminationData || [],
            // obsHistoryListStatus:props.obsHistoryListStatus ||false,
            gpaleLactating: props.gpaleLactating || [],
            obsHistoryList: props.obsHistoryList || [],
            fetalParameters: props.physicalExaminationFPData || [],
            Immunoprophylaxis: props.Immunoprophylaxis || [],
            tests: props.tests || [],
        }
    }
    getPatientAge(dob) {
        var ageD = Math.floor(moment().diff(moment(dob, 'YYYY-MM-DD'), 'D') / (1000 * 3600 * 24));
        var ageM = moment().diff(moment(dob, 'YYYY-MM-DD'), 'M');
        var ageY = ageM / 12;
        var remainM = ageM % 12;
        ageY = Math.floor(ageY);
        var ageText = '';
        if (ageM == 0)
            ageText = ageD + ' Day' + (ageD == 1 ? '' : 's');
        else if (ageY < 1)
            ageText = ageM + ' Mon' + (ageM == 1 ? '' : 's');
        else if (ageY <= 5) {
            if (remainM != 0) {
                ageText = ageY + ' Yr' + (ageY == 1 ? '' : 's ') + remainM + ' Mon' + (remainM == 1 ? '' : 's');
            } else {
                ageText = ageY + ' Yr' + (ageY == 1 ? '' : 's');
            }
        }
        else
            ageText = ageY + ' Yrs';
        return ageText;
    }

    getListValue(selectedValue, OptionObj = null) {
        if (selectedValue == 0 || selectedValue === null) {
            return "-";
        } else if (OptionObj) {
            return OptionObj[selectedValue];
        } else {
            return selectedValue;
        }
    }

    render() {
        const { get_prev_anc_details, patientInfo, patientDetails, risk_factors_options, riskfactor, physicalExamination, fetalParameters, Immunoprophylaxis, tests, gpaleLactating } = this.state;
        const { radiology_tests } = this.props;
        return (
            <>

                <div className="container-fluid emrfrm anc-container mb-4 ">
                    <div className='row'>
                        <div className='col-12 mb-3'>
                            <div className="float-left">
                                <h1 className="page-title mb-0">ANC Card</h1>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='card anc-cardgrey'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <span className='heading'>Patient Name :&nbsp;</span>
                                        {(patientInfo) ?
                                            <span className='patient_name'>{patientInfo?.first_name !== undefined ? patientInfo?.first_name : null}&nbsp;{patientInfo?.middle_name !== undefined ? patientInfo?.middle_name : null}&nbsp;{patientInfo?.last_name !== undefined ? patientInfo?.last_name : null}, {patientInfo?.dob && patientInfo?.age !== null ? this.getPatientAge(patientInfo?.dob) : null}, {patientInfo?.gender !== undefined && patientInfo?.gender !== null ? (patientInfo?.gender == 'Male' ? ' (M)' : ' (F)') : null}</span>
                                            : null}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <span className='heading'>Address :&nbsp;</span>
                                        {(patientInfo) ?
                                            <span className='patient_name'>{patientInfo?.address_1 !== undefined ? patientInfo?.address_1 : null}, {patientInfo?.city !== undefined ? patientInfo?.city : null},   {patientInfo?.state !== undefined ? patientInfo?.state : null}</span>
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mt-4'>
                        {this.props.total_riskfactor_length != 0 ?
                            <div className='col-6' >
                                <span style={{ color: "#FF0000" }}>Risk Factors</span>
                            </div>
                            : null}
                        {get_prev_anc_details?.riskFactorsManagement != null && get_prev_anc_details.riskFactorsManagement != "" ?
                            <div className='col-6'>
                                <span style={{ color: "#FF0000" }}>Risk Factors Management</span>
                            </div>
                            : null}
                    </div>
                    <div className='row '>
                        <div className='col-6'>
                            <table>
                                {
                                    risk_factors_options?.map(obj => {
                                        return riskfactor?.map(
                                            item => {
                                                if (obj.id == item) {
                                                    return (
                                                        <tr>{obj?.description}</tr>
                                                    )
                                                }
                                            }
                                        )
                                    })

                                }
                            </table>
                        </div>

                        <div className='col-6'>
                            <span>{get_prev_anc_details.riskFactorsManagement}</span>
                        </div>
                    </div>


                    {(this.state.allergies !== undefined && this.state.allergies.length > 0) ?
                        <>
                            <div className='row mt-4'>
                                <div className='col-12'>
                                    <span className='title'> Allergies</span>
                                </div>
                            </div>

                            <table className=" table-bordered mt-3" >
                                <thead>
                                    <tr>
                                        <td width="28%">ALLERGY NAME</td>
                                        <td width="22%">REACTION</td>
                                        <td width="15%">DURATION</td>
                                        <td width="15%">INTENSITY</td>
                                        <td width="15%">INFORMED BY</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.allergies.length > 0 ?
                                        this.state.allergies.map((obj) => (
                                            <tr>
                                                <td>{obj.allergy_name}</td>
                                                <td>{obj.reaction ? obj.reaction : '-'}</td>
                                                <td>{obj.duration ? obj.duration + ' ' + DURATION_TYPE[obj.durationType] : '-'}</td>
                                                <td>{obj.intensity === 0 ? "Low" : obj.intensity === 1 ? "Medium" : obj.intensity === 2 ? "High" : ''}</td>
                                                <td>{obj.informedBy ? INFORMED_BY[obj.informedBy] : '-'}</td>
                                            </tr>
                                        ))
                                        : null}
                                </tbody>
                            </table>
                        </>
                        : null}

                    {this.state?.conditions?.length > 0 ?
                        <>
                            <div className='row mt-4'>
                                <div className='col-12'>
                                    <span className='title'>Existing Condition</span>
                                </div>
                            </div>

                            <table className=" table-bordered mt-3" >
                                <thead>
                                    <tr>
                                        <td width="30%">CONDITIONS</td>
                                        <td width="20%">DURATION</td>
                                        <td width="20%">ON MEDICATION</td>
                                        <td width="30%">MEDICINE NAME</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.conditions.map((c, i) => (
                                        <tr>
                                            <td>{c.condition_description}</td>
                                            <td>{(c.duration) ? `${c.duration} ${DURATION_TYPE[c.durationType]}` : '-'}</td>
                                            <td>{MEDICATION_ON[c.onMedication] ? MEDICATION_ON[c.onMedication] : '-'}</td>
                                            <td>{c.medicineName || "NA"}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                        : null}

                    {this.state.menstrualHistoryList.length > 0 ?
                        <>
                            <div className='row mt-4'>
                                <div className='col-3'>
                                    <span className='title'>Menstrual Hx</span>
                                </div>
                                <div className='col-4 text-center'>
                                    {get_prev_anc_details.lmp != null && get_prev_anc_details.lmp != "" ?
                                        <>
                                            <span>LMP:</span>  <span className='title'>{get_prev_anc_details.lmp}</span >
                                        </> : null}
                                </div>
                                {this.state?.menstrualHistoryList.length > 0 ?
                                    <div className='col-5 text-right'>
                                        <span className='title'>Last Updated ({this.state?.menstrualHistoryList?.map((data, index) => (index == 0 ? moment(data.createdAt).format("DD MMM, YYYY | h:mm A") : ''))})</span>
                                    </div>
                                    : null}
                            </div>

                            <table className=" table-bordered mt-3" >
                                <thead>
                                    <tr>
                                        <td width="10%">DATE</td>
                                        <td width="20%">CYCLE LENGTH</td>
                                        {/* <td width="20%">DURATION</td>
                                        <td width="10%">FLOW</td>
                                        <td width="10%">REGULARITY</td>
                                        <td width="10%">PAIN</td>
                                        <td width="20%">CHANGES IN CYCLE</td> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.menstrualHistoryList !== undefined && this.state.menstrualHistoryList && this.state.menstrualHistoryList.length > 0 ?
                                        this.state.menstrualHistoryList.map((m, i) => (
                                            <tr>
                                                <td>{m.created_at}</td>
                                                <td>{(m.cycle_length ? m.cycle_length : '-')}</td>
                                                {/* <td>{(m.duration_of_period) ? m.duration_of_period : '-'} Days</td>
                                                <td>{(m.flow) ? m.flow : '-'}</td>
                                                <td>{(m.regularity) ? m.regularity : '-'}</td>
                                                <td>{(m.pain_score) ? m.pain_score : '-'}</td>
                                                <td>{(m.changes_in_cycle) ? m.changes_in_cycle : '-'}</td> */}
                                            </tr>
                                        ))
                                        : null}
                                </tbody>
                            </table>
                        </>

                        : null}

                    <div className='row mt-4 d-flex align-items-center'>
                        <div className='col-2'>
                            <span className='title'>Obstetrics Hx</span>
                        </div>
                        <div className='col-3'>
                            {get_prev_anc_details.eddByLmp != null && get_prev_anc_details.eddByLmp != "" ?
                                <>
                                    <span>EDD by LMP:</span><span className='title'>{get_prev_anc_details.eddByLmp}</span >
                                </>
                                : null}
                        </div>
                        {get_prev_anc_details.eddByUSG != null && get_prev_anc_details.eddByUSG != "" ?
                            <div className='col-auto'>
                                <div className='card obs_card'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <span className='title_red'>EDD by USG:</span><span >{get_prev_anc_details.eddByUSG}</span >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}
                        <div className='col-3 text-right'>
                            <span className='title'>Lactating Mother</span >
                        </div>
                        <div className='col text-right'>
                            <span className='title'><GPALE values={gpaleLactating} /></span>
                        </div>
                    </div>

                    {get_prev_anc_details?.obstetricsRemarks && get_prev_anc_details?.obstetricsRemarks != "" && get_prev_anc_details?.obstetricsRemarks != null ?
                        <div className='row mt-4'>
                            <div className='col-2'>
                                <span className='title'>Obstetrics Remarks</span>
                            </div>
                            <div className='col-10'>
                                <pre style={{ fontSize: "15px", border: 'none' }}>{get_prev_anc_details?.obstetricsRemarks}</pre>
                            </div>
                        </div>
                        : null}

                    {/* {this.state.obsHistoryList.length > 0 ? <>
                        <table className=" table-bordered mt-3" >
                            <thead>
                                <tr>
                                    <td width="20%">YEAR OF OUTCOME</td>
                                    <td width="20%">MODE OF CONCEPTION</td>
                                    <td width="15%">PREGNANCY TYPE</td>
                                    <td width="15%">DELIVERY TYPE</td>
                                    <td width="30%">OUTCOME</td>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.obsHistoryList !== undefined && this.state.obsHistoryList && this.state.obsHistoryList.length > 0 ?
                                    this.state.obsHistoryList.map((data, i) => (
                                        <tr>
                                            <td>{data.pregnancy_year}</td>
                                            <td>{data.mode_text}</td>
                                            <td>{data.pregnancy_type_text}</td>
                                            <td>{data.delivery_type_text}</td>
                                            <td>{data.outcome_text}</td>
                                        </tr>
                                    ))
                                    : null}
                            </tbody>
                        </table>
                    </> : null} */}


                    {physicalExamination?.length > 0 ?
                        <>
                            <div className='row mt-4'>
                                <div className='col-12'>
                                    <span className='title'> Physical Examination</span>
                                </div>
                            </div>

                            <div className='row mt-3 '>
                                <div className='col-4'>
                                    <span className='title'>Mother's Parameters</span>
                                </div>
                                {physicalExamination.length > 0 ?
                                    <div className='col-8 text-right'>
                                        <span className='title'>Last Updated({physicalExamination?.map((data, index) => (index == 0 ? moment(data.createdAt).format("DD MMM, YYYY | h:mm A") : ''))} )</span>
                                    </div>
                                    : null}
                            </div>
                            <table className=" table-bordered mt-3" >
                                <thead>
                                    <tr>
                                        <td width="15%">PARAMETERS</td>
                                        <td width="8%">POG</td>
                                        <td width="10%">Weight</td>
                                        <td width="5%">BP</td>
                                        <td width="10%">PULSE RATE</td>
                                        <td width="10%">FUNDAL HT</td>
                                        <td width="10%">EDEMA</td>
                                        <td width="10%">PALLOR</td>
                                        <td width="12%">CERVIX EXAM</td>
                                        <td width="15%">PRETEM LABOUR</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {physicalExamination.length > 0 ?
                                        <>
                                            {physicalExamination.map(obj => {
                                                return (
                                                    <tr>
                                                        <td>{moment(obj.createdAt).format("D MMM, YYYY ")}</td>
                                                        <td>{obj.pogWeeks ? obj.pogWeeks : '-'}</td>
                                                        <td>{obj.weight ? obj.weight : '-'}</td>
                                                        <td>{obj.bpmm ? obj.bpmm : '-'}</td>
                                                        <td>{obj.pulseRate ? obj.pulseRate : '-'}</td>
                                                        <td>{(obj.fundalHeight) ? obj.fundalHeight : '-'}</td>
                                                        <td>{(obj.edema) ? obj.edema : '-'}</td>
                                                        <td>{(obj.pallor) ? obj.pallor : '-'}</td>
                                                        <td>{obj.cervixExam ? obj.cervixExam : '-'}</td>
                                                        <td>{obj.pretermLabour ? obj.pretermLabour : '-'}</td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </>
                                        : <tr>
                                            <td colSpan="6">No data available</td>
                                        </tr>}
                                </tbody>
                            </table>
                        </> : null}

                    {this.props.total_count_fetal != 0 ? <>
                        <div className='row mt-4 '>
                            <div className='col-4'>
                                <span className='title'>Fetal Parameters</span>
                            </div>
                            {this.state?.fetalParameters?.length > 0 ?
                                <div className='col-8 text-right'>
                                    <span className='title'>Last Updated({this.state?.fetalParameters?.map((data, index) => (index == 0 ? moment(data.updatedAt).format("DD MMM, YYYY | h:mm A") : ''))})</span>
                                </div>
                                : null}
                        </div>
                        <table className=" table-bordered mt-3" >
                            <thead>
                                <tr>
                                    <td width="10%">PARAMETERS</td>
                                    <td width="10%">FETUS NO.</td>
                                    <td width="10%">FETAL MOVEMENT</td>
                                    <td width="10%">FHR(PER MINUTE)</td>
                                    <td width="10%">PRESENTATION</td>
                                    <td width="10%">RELATION OF PP TO BRIM</td>
                                    <td width="10%">HEAD CC (CM)</td>
                                    <td width="10%">ABDOMINAL CC (CM)</td>
                                    <td width="10%">FEMUR LENGTH(CM)</td>
                                    <td width="10%">REMARKS</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    fetalParameters.map((data, i) => (
                                        data.fetal_params.map((d, j) => (
                                            <tr key={d.id} className={j != 0 ? 'highlight_bg' : ''}>
                                                <td >{(data?.createdAt) ? moment(data?.createdAt).format("D MMM, YYYY") : '-'}</td>
                                                <td>{data.noOfFetus ? data.noOfFetus : '-'}</td>
                                                <td className="text-center">{this?.getListValue(d.fetalMovement, FETAL_MOVEMENT)}</td>
                                                <td className="text-center">{d.fetalHeartRate || '-'}</td>
                                                <td className="text-center">{this?.getListValue(d.presentation, PRESENTATION)}</td>
                                                <td className="text-center">{this?.getListValue(d.relPPtoBrim, RELATIONSHIP_TO_PP_BRIM)}</td>
                                                <td className="text-center">{d.headCc || '-'}</td>
                                                <td className="text-center">{d.abdominalCc || '-'}</td>
                                                <td className="text-center">{d.femurLength || '-'}</td>
                                                <td style={{ wordBreak: "break-all" }} className="text-center">{data.remarks || '-'}</td>
                                            </tr>
                                        ))
                                    ))
                                }
                            </tbody>
                        </table>
                    </> : null}

                    {Immunoprophylaxis.length > 0 ?
                        <>
                            <div className='row mt-4 '>
                                <div className='col-12'>
                                    <span className='title'>Immunoprophylaxis</span>
                                </div>
                            </div>

                            <div className='row mt-3'>
                                {
                                    Immunoprophylaxis.map((obj) => {
                                        return (
                                            <div className='col-3'>
                                                <span>{obj.name}</span>
                                                <b>: {obj.date}</b>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </> : null}

                    {get_prev_anc_details?.ImmunoprophylaxisRemarks ?
                        <div className='row mt-2'>
                            <div className='col-12'>
                                <label htmlFor="immuno_remarks">Immunoprophylaxis Remarks: </label>
                                <b> {get_prev_anc_details?.ImmunoprophylaxisRemarks}</b>
                            </div>
                        </div>
                        : null}

                    {/* {tests?.length > 0 ? <>
                        <div className='row mt-4 '>
                            <div className='col-12'>
                                <span className='title'>Lab Tests</span>
                            </div>
                        </div>
                        <table className=" table-bordered mt-3" >
                            <thead>
                                <tr>
                                    <td width="15%">DATE</td>
                                    <td width="19%">TEST NAME</td>
                                    <td width="41%">RESULT/IMPRESSION</td>
                                    <td width="15%">REFERENCE RANGE</td>
                                    <td width="10%">VIEW REPORT</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    tests.map(obj => {
                                        return (
                                            <tr>
                                                <td>
                                                    <span> {obj.date}</span>
                                                </td>
                                                <td>
                                                    <span> {obj.name}</span>
                                                </td>
                                                <td>
                                                    <span> {obj.result}</span>
                                                </td>
                                                <td>
                                                    <span style={{ height: '41px' }}> {obj.refernce}</span>
                                                </td>
                                                <td>
                                                    <span style={{ height: '41px' }}> - </span>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </> : null} */}

                    {/* {radiology_tests?.length > 0 ? <>
                        <div className='row mt-4 '>
                            <div className='col-12'>
                                <span className='title'>Radiology Tests</span>
                            </div>
                        </div>
                        <table className=" table-bordered mt-3" >
                            <thead>
                                <tr>
                                    <td width="15%">DATE</td>
                                    <td width="19%">TEST NAME</td>
                                    <td width="41%">RESULT/IMPRESSION</td>
                                    <td width="15%">REFERENCE RANGE</td>
                                    <td width="10%">VIEW REPORT</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    radiology_tests.map(obj => {
                                        return (
                                            <tr>
                                                <td>
                                                    <span> {obj.date}</span>
                                                </td>
                                                <td>
                                                    <span> {obj.name}</span>
                                                </td>
                                                <td>
                                                    <span> {obj.result}</span>
                                                </td>
                                                <td>
                                                    <span style={{ height: '41px' }}> {obj.refernce}</span>
                                                </td>
                                                <td>
                                                    <span style={{ height: '41px' }}> - </span>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </> : null} */}

                    <div className='row mt-4 '>
                        <div className='col-12'>
                            <span className='title'>Delivery Details</span>
                        </div>
                    </div>

                    <div className='row mt-3'>
                        {/* <div className='col-4'>
                            <span>Date & Time:</span>
                            <b> {get_prev_anc_details?.deliveryDateTime ? moment(get_prev_anc_details.deliveryDateTime).format("DD MMM, YYYY HH:mm") : null}</b>
                        </div> */}
                        {/* <div className='col-3'>
                            <span>Place:</span>
                            <b> {get_prev_anc_details?.deliveryPlace === "1" ? "Hospital" : get_prev_anc_details?.deliveryPlace === "2" ? "Home" : get_prev_anc_details?.deliveryPlace === "3" ? "PHC" : get_prev_anc_details?.deliveryPlace === "4" ? "Others" : ''}</b>
                        </div> */}
                        <div className='col-4'>
                            <span>Mode:</span>
                            <b> {get_prev_anc_details?.deliveryMode === "1" ? "Natural" : get_prev_anc_details?.deliveryMode === "2" ? "IVF" : get_prev_anc_details?.deliveryMode === "3" ? "IUI" : ''}</b>
                        </div>
                        {/* <div className='col-3'>
                            <span>Outcome:</span>
                            <b> {get_prev_anc_details?.deliveryOutcome === "1" ? "Live Term" : get_prev_anc_details?.deliveryOutcome === "2" ? "Live Pre-Term" : get_prev_anc_details?.deliveryOutcome === "3" ? "Still born Term" : get_prev_anc_details?.deliveryOutcome === "4" ? "Still born Pre-Term" : get_prev_anc_details?.deliveryOutcome === "5" ? "Spontaneous Abortion" : get_prev_anc_details?.deliveryOutcome === "6" ? "MTP" : ''}</b>
                        </div> */}
                        <div className='col-4'>
                            <span>Pregnancy Type:</span>
                            <b> {get_prev_anc_details?.pregnancyType == "1" ? "Single" :
                                get_prev_anc_details?.pregnancyType == "2" ? "Multiple" :
                                    get_prev_anc_details?.pregnancyType == "3" ? "Ectopic" : ''}</b>
                        </div>
                    </div>

                    {this.props?.outcomeGroup.length > 0 ?
                        <div className='row mt-3'>
                            <div className='col-12'>
                                <span>Outcome:</span>
                                {this.props?.outcomeGroup?.map(obj => {
                                     return (
                                        <div className='row mt-2  ml-2'>
                                            <b> <span> {obj.outcome == "1" ? "Live Term" :
                                                obj.outcome == "2" ? "Live Pre-Term" :
                                                    obj.outcome == "3" ? "Still born Term" :
                                                        obj.outcome == "4" ? "Still born Pre-Term" :
                                                            obj.outcome == "5" ? "Spontaneous Abortion" :
                                                                obj.outcome == "6" ? "MTP" : '-'} ,</span> </b> &nbsp;
                                            {obj?.delivery_type ? <> <b><span>{obj.delivery_type == "1" ? "Normal" :
                                                    obj.delivery_type == "2" ? "LSCS" :
                                                        obj.delivery_type == "3" ? "Forceps" :
                                                            obj.delivery_type == "4" ? "Water Birthing" : '-'} ,</span> </b> &nbsp;  </> : null}
                                            {obj?.birth_date_time ? <b><span>Birth Date & Time:- </span> {moment(obj?.birth_date_time).format("DD MMM, YYYY HH:mm")} , </b> : null}
                                            {obj?.gender ? <> <b> <span> {obj.gender == "1" ? "Male" :
                                                obj.gender == "2" ? "Female" :
                                                    obj.gender == "3" ? "Transgender" : '-'} ,</span> </b> &nbsp;  </> : null}
                                            {obj?.birth_weight ? <> <span>Brith wt<b> {obj.birth_weight ? obj.birth_weight : "-"}</b> kg</span> , &nbsp;  </> : null}
                                            {obj?.place_of_delivery ? <> <b><span>{obj.place_of_delivery === "1" ? "Hospital" :
                                                obj.place_of_delivery === "2" ? "Home" :
                                                    obj.place_of_delivery === "3" ? "PHC" :
                                                        obj.place_of_delivery === "4" ? "Others" : '-'} ,</span> </b> &nbsp;  </> : null}
                                            <b><span>Congenital Abnormality:- </span> {obj?.abnormality == true ? "Yes" : "No"} , </b> &nbsp;
                                            {obj?.abnormality == true ?
                                                <b><span>Congenital Abnormality Remark:- </span> {obj?.abnormality_remark} </b>
                                                : null}
                                        </div>
                                    )
                                })
                                }
                            </div>
                        </div>
                        : null}

                    <div className='row mt-3'>
                        {get_prev_anc_details?.deliveryIndication != null && get_prev_anc_details?.deliveryIndication != "" ?
                            <div className='col-6'>
                                <span>Indication for C-Section:</span>
                                <b> {get_prev_anc_details?.deliveryIndication}</b>
                            </div>
                            : null}
                        {get_prev_anc_details?.deliveryDetails != null && get_prev_anc_details?.deliveryDetails != "" ?
                            <div className='col-6'>
                                <span>Details:</span>
                                <b> {get_prev_anc_details?.deliveryDetails}</b>
                            </div>
                            : null}
                    </div>
                </div>

            </>
        );
    }

}
