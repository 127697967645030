// import axios from 'axios';
import LS_SERVICE from './localStorage';

class SoapFlowInterface {
    soaplist = [];
    SUBJECTIVE_INDEX = 1;
    OBJECTIVE_INDEX = 2;
    ASSESSMENT_INDEX = 3;
    PLAN_INDEX = 4;
    HOSPITAL_ID = null;

    setSoapList() {
        this.soaplist = LS_SERVICE.get('soap_conf_list');
        
        if (this.HOSPITAL_ID !== null) {
            const listInfo = LS_SERVICE.get('soap_conf_list_' + this.HOSPITAL_ID);
            if (listInfo != null) {
                this.soaplist = listInfo;
            }
        }
        
        return;
    }

    hospital(hid) {
        this.HOSPITAL_ID = hid;
    }

    show(type) {
        this.setSoapList();

        const flags = {
            'subjective': this.SUBJECTIVE_INDEX,
            'objective': this.OBJECTIVE_INDEX,
            'assessment': this.ASSESSMENT_INDEX,
            'plan': this.PLAN_INDEX,
            'paediatric': this.OBJECTIVE_INDEX,
            'obsgynae': this.OBJECTIVE_INDEX,
        };
        const index = flags[type];
        let show = false;


        if (this.soaplist[index] == undefined) {
            return show;
        }

        for (const element of this.soaplist[index]) {
            if (element.display === 'Y') {
                show = true;
                break;
            }
        }

        return show;
    }

    showElement(type, element) {
        this.setSoapList();

        let show = false;
        const flags = {
            'subjective': this.SUBJECTIVE_INDEX,
            'objective': this.OBJECTIVE_INDEX,
            'assessment': this.ASSESSMENT_INDEX,
            'plan': this.PLAN_INDEX,
            'paediatric': this.OBJECTIVE_INDEX,
            'obsgynae': this.OBJECTIVE_INDEX,
        };
        const index = flags[type];


        console.log("saurabh>>>>>>>>>soaplist", this.soaplist);

        if (this.soaplist[index] == undefined) {
            return show;
        }
        
        var listitem = this.soaplist[index].find(item => item.name === element);
        if (listitem != undefined && listitem.display != undefined && listitem.display === 'Y') {
            return true;
        }

        return show;
    }

    printElement(type, element) {
        this.setSoapList();

        let print = false;
        const flags = {
            'subjective': this.SUBJECTIVE_INDEX,
            'objective': this.OBJECTIVE_INDEX,
            'assessment': this.ASSESSMENT_INDEX,
            'plan': this.PLAN_INDEX,
            'paediatric': this.OBJECTIVE_INDEX,
            'obsgynae': this.OBJECTIVE_INDEX,
        };
        const index = flags[type];

        if (this.soaplist[index] == undefined) {
            return print;
        }
        
        var listitem = this.soaplist[index].find(item => item.name === element);
        if (listitem != undefined && listitem.print != undefined && listitem.print === 'Y') {
            return true;
        }

        return print;
    }

}

const SoapFlow = new SoapFlowInterface();

export default SoapFlow;