import lactatingImage from "../../assets/images/mother-with-baby.svg";
import React, { Component } from 'react';
import Axios from 'axios';
import qs from 'qs';
import $ from 'jquery';
import LS_SERVICE from '../../utils/localStorage';
import API_SERVICE from '../../utils/apiService';
import {
  PAST_VISIT_PRIMARY_DETAILS,
  PAST_VISIT_COMPLETE_DETAILS,
  LOADER_RED,
  PATIENT_DATA,
  HOSPITALDEFAULTS,
  VITAL_GRAPH_DATE,
  COPY_PRESCRIPTION,
  SOAP,
  GOBACK,
  ISALLOWEDITSOAP,
  VITALS_GRAPH,
  DISABLED_FUTURE_DATES,
  MENSTRUAL_HX_TREND_GRAPH,
  TOGGLE_PRACTICE,
  DIAGNOSIS_DO_NOT_PRINT
} from '../../utils/constant';
import moment from "moment";
import AsideLeft from '../../components/aside/asideleft';
import copyImg from '../../assets/images/copy.png';
import Chart from '../../components/chart/chart';
import ChartPastVisit from '../../components/chart/chart-past-visit';
import { Pagination, Modal, Badge, DatePicker } from 'antd';
import Vitals from "../../components/vitals/vitals";
import MenstrualHxList from "../../pages/patient-detail/gynae-menstrual-hx-list";
import MedicineTable from "../../components/drugs/medicine-table";
import { headerService$, practiceService } from '../../utils/rxjs-sharing';

import { CDC, WHO, IAP, WHOIAP } from '../../utils/growthchartdata';
import IndividualPaediatricChart from '../../components/chart/paediatrics-chart-individual';
import TrendGraph from "../../components/chart/trend-graph";

const { RangePicker } = DatePicker;
class PastVisit extends Component {

  cardOpened = false;
  cardOpenedId = null;

  constructor(props) {
    super(props);
    // const call_qms_token_id = LS_SERVICE.get("call_patient_qms_token_id");

    this.state = {
      cardOpened: false,
      cardOpenedId: null,
      DataLoaded: false,
      detailsDataLoaded: true,
      patient: null,
      constants: null,
      visitVitals: [],
      vitals_data_formatted: false,
      visitVitalsFormatted: [],
      visitMenstrualHistory: [],
      showVitalsTable: false,
      vitalGroups: [],
      selectedVitalGroups: [],
      displayVitals: [],
      currentPage: 1,
      countPage: '',
      sameRxdate: '',
      primaryDetailsData: {},
      primaryBasicDetailsData: {},
      primaryDetailsMessage: '',
      primaryBasicDetailsMessage: '',
      completeDetailsData: {},
      openVisit: '',
      call_qms_token_id: null,
      growthChartSettings: {},
      otheroptions: [],
      growthChartData: {},
      growthData:[],
      showGrowthChart: false,
      chartModal: false,
      chartData: {},
      chartGraphSettings: '',
      chartDataLoaded: false,
      chartsTab: '2',
      chartsType: 'all',
      chartHeading: 'Vital',
      chartsTabSet: false,
      hospital_id: null,
      isPracticing: false,
      isPracticingModal: false,
      allowEdit: true,
      range: [],
      vaccinationList: [],
      filterMilestoneData: [],
      patientDetails : {}
    };
    this.navigateToPage = this.navigateToPage.bind(this);
  }

  static getDerivedStateFromProps() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    return null;
  }

  async componentDidMount() {
    const { match: { params } } = this.props;
    const { hospital_id, patient_id, reference_id } = params;

    const isPracticingHospitalId = LS_SERVICE.get('practicing')?.hospital_id;

    if (+isPracticingHospitalId === +hospital_id) {
      this.setState({
        isPracticing: true
      })
    }
    if (this.props.location?.state?.patient) {
      await this.setState({
        patientData: this.props.location.state.patient
      })
    } else {
      await this.getPatientData(reference_id);
    }

    const patient = {
      hospital_id,
      patient_id,
      qms_token_id: reference_id
    }
    let hospitalConfigData = LS_SERVICE.get('hospitalConfigData') ?? [];
    hospitalConfigData = hospitalConfigData.find(item => item.hospital_id == hospital_id);
    let allowEdit = true;
    if(this.props.match.params.openVisit)
    {
      allowEdit = this.props.match.params.openVisit? false : (hospitalConfigData ? ISALLOWEDITSOAP(hospitalConfigData.pres_modification, hospitalConfigData.allow_hours, LS_SERVICE.get('appointmentCompletedTime')[reference_id], 'button') : true);
    }
    this.setState({ hospital_id, patient_id, patient, allowEdit }, () => {
      if (LS_SERVICE.has('hospitalConfigData')) {
        const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
        let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
        headerService$.soapConfig({ soapConfig: hospitalConfigObj?.opd_layout?hospitalConfigObj.opd_layout:'soap' }); // IMPORTANT TO HIT AS PER THE VIEW
        
      }
      if (LS_SERVICE.get("soapConfig") === SOAP) {
        this.primaryLoadData();
      } else {
        this.basicLoadData();
      }
    });

    /*if (this.props.location.state !== undefined) {
      if (this.props?.location?.state?.patient !== undefined) {
        this.setState({
          hospital_id: hospital_id?? null,
          patient_id: patient_id?? null,
          // patient: this.props?.location?.state?.patient
        }, () => {
          this.handleSoapOrBasicDataLoad()
        })
      } else {
        this.getPatientData()
      }
    } else {
      this.getPatientData()
    }*/
  }

  // handleSoapOrBasicDataLoad = _ => {

  //   if (LS_SERVICE.get("soapConfig") === SOAP) {
  //     this.primaryLoadData();
  //   } else {
  //     this.basicLoadData();
  //   }
  // }

  // getPatientData = _ => {
  //   const { match: { params } } = this.props;
  //   const { patient_id, reference_id } = params;
  //   const { call_qms_token_id } = this.state;
  //   var reference_type = await LS_SERVICE.get('reference_type');
  //   Axios.get(PATIENT_DATA({ reference_id, reference_type, patient_id }))
  //     .then(success => {
  //       const patient = {
  //         ...success.data.data.results,
  //         qms_token_id: success.data.data.results.qmsToken ? success.data.data.results.qmsToken.qms_token_id : call_qms_token_id
  //       }
  //       this.setState({
  //         patient,
  //         hospital_id: this.props?.location?.state?.hospital_id !== undefined ? this.props?.location?.state?.hospital_id : null,
  //       }, () => {
  //         this.handleSoapOrBasicDataLoad()
  //       })
  //     })
  //     .catch(err => console.log(err))
  // }

  // handlePastVisitSoapBasic = _ => {
  // const { match: { params } } = this.props;
  // const { reference_id, patient_id } = params;

  // if (LS_SERVICE.has('soapConfigData')) {
  //   const soapConfigData = LS_SERVICE.get('soapConfigData');
  //   let isSoapOrBasic;
  //   if (reference_id !== undefined) {
  //     isSoapOrBasic = soapConfigData.find(scd => scd.reference_id === +reference_id);
  //   } else {
  //     isSoapOrBasic = soapConfigData.find(scd => scd.patient_id === +patient_id);
  //   }
  //   if (isSoapOrBasic !== undefined) {
  //     this.setState({
  //       call_qms_token_id: isSoapOrBasic.reference_id
  //     })
  //     headerService$.soapConfig({ soapConfig: isSoapOrBasic.soapConfig });
  //     LS_SERVICE.set('soapConfig', isSoapOrBasic.soapConfig);
  //   }
  // } else {
  // let call_qms_token_id = LS_SERVICE.get("call_patient_qms_token_id");
  // this.setState({
  //   call_qms_token_id: call_qms_token_id !== null ? LS_SERVICE.get("call_patient_qms_token_id") : null
  // })
  // }
  // }

  // goBack = (event) => {
  //   event.preventDefault();
  //   this.props.history.goBack();
  // }

  getPatientData = async (reference_id) => {
    var reference_type = await LS_SERVICE.get('reference_type') || 0;
    await Axios.get(PATIENT_DATA({ reference_id, reference_type }))
      .then(success => {
        const patient = {
          ...success.data.data.results,
          qms_token_id: success.data.data.results?.qmsToken?.qms_token_id || reference_id
        }
        this.setState({
          patientData: patient
        })
      })
  }

  primaryLoadData = () => {
    const { patient_id } = this.state;
    const PARAMS = {
      patient_id,
      page: this.state.currentPage,
    }

    let openVisitType = this.props.match.params.openVisit;

    Axios.post(PAST_VISIT_PRIMARY_DETAILS, qs.stringify(PARAMS))
      .then(response => {
        if (response.data.hasOwnProperty("past_visit") === true) {
          let openVisit = null;
          if (openVisitType == 'self') {
            response.data.past_visit.map((obj, k) => {
              openVisit = openVisit == null && obj.doctor_id == LS_SERVICE.get('staff_id') ? obj.reference_id : openVisit
            });
          } else if (openVisitType == 'any') {
            openVisit = response.data.past_visit[0].reference_id
          }

          this.setState({
            openVisit,
            primaryDetailsData: response.data.past_visit,
            countPage: response.data.count,
            DataLoaded: true,
          }, () => {
            if (openVisit != null) {
              $('#pastvisitcard_' + openVisit + ' button.past-visit-container-button').click();
              // this.goToDetails(null, openVisit)
            }
          })
        }
        this.setState({
          primaryDetailsMessage: response.data.message,
          DataLoaded: true
        })
        //debugger;
      })
  }

  basicLoadData = () => {
    const { patient, currentPage } = this.state;

    const PARAMS = {
      patient_id: patient.patient_id,
      doctor_id: LS_SERVICE.get('staff_id'),
      page: currentPage
    }

    Axios.post(COPY_PRESCRIPTION, qs.stringify(PARAMS))
      .then(response => {
        if (response.data.data.hasOwnProperty("visits") === true) {
          this.setState({
            // primaryDetailsData: response.data.data.visits,
            primaryBasicDetailsData: response.data.data.visits,
            countPage: response.data.data.current_page,
            DataLoaded: true
          })
        }
        this.setState({
          primaryBasicDetailsMessage: response.data.data.message || 'No past visit for this patient',
          DataLoaded: true
        })
      })
  }

  navigateToPage(page) {
    this.setState({
      currentPage: page
    }, () => {
      if (LS_SERVICE.get("soapConfig") === SOAP) {
        this.primaryLoadData();
      } else {
        this.basicLoadData();
      }
    }
    )
  }

  makeVitalsData() {
    let vitalDetails = [];
    this.setState({
      patientHeight: null,
      patientWeight: null
    });
    let { patient, patientHeight, patientWeight } = this.state;
    let allVitalIds = [];
    this.state.constants.vitals_details.map((v, k) => {
      allVitalIds.push(v.id);
      vitalDetails[v.id] = [];
      this.setState({ [v.id]: null });
    });
    vitalDetails['date'] = [];
    vitalDetails['source'] = [];
    vitalDetails['update_date'] = [];
    vitalDetails['source_name'] = [];
    allVitalIds.push('date');
    allVitalIds.push('source');
    allVitalIds.push('update_date');
    allVitalIds.push('source_name');

    if (Object.keys(this.state.visitVitals).length > 0) {
      let index = 0;
      this.state.visitVitals.map(function (temp_vitals, k) {
        // Object.keys(temp_vitals).forEach(j => {
        allVitalIds.forEach(j => {
          if (vitalDetails[j]) vitalDetails[j][index] = temp_vitals[j] ?? '';
          if (j == 'V001' && patientHeight == null && patient.age > HOSPITALDEFAULTS.age && moment().diff(moment(temp_vitals.date[j]), 'months') < 2 && temp_vitals[j]) patientHeight = temp_vitals[j];
          if (j == 'V002' && patientWeight == null && patient.age > HOSPITALDEFAULTS.age && moment().diff(moment(temp_vitals.date[j]), 'months') < 2 && temp_vitals[j]) patientWeight = temp_vitals[j];
        });
        index++;
      });

    }
    this.setState({
      patientHeight: patientHeight,
      patientWeight: patientWeight,
      V001: patientHeight,
      V002: patientWeight,
    });
    let vitals_data = {};
    let newVitalEmptyArray = vitalDetails['date'] ? Array.from({ length: vitalDetails['date'].length }, i => '') : [];
    this.state.constants.vitals_details.map((v, k) => {
      vitals_data[v.id] = vitalDetails[v.id] && vitalDetails[v.id].length > 0 ? vitalDetails[v.id] : newVitalEmptyArray;
    });
    vitals_data.date = vitalDetails['date'];
    vitals_data.source = vitalDetails['source'];
    vitals_data.update_date = vitalDetails['update_date'];
    vitals_data.source_name = vitalDetails['source_name'];
    this.state.visitVitalsFormatted = vitals_data;

    //vitals groups
    let vitalGroupData = [];
    const doctorSpeciality = LS_SERVICE.get('speciality_id');
    let allVital = null;
    for (const [key, vitalGroup] of Object.entries(this.state.constants.vital_groups)) {
      if (vitalGroup.speciality_id == doctorSpeciality) {
        var vitalSignIds = [];
        for (const [key, vitalSign] of Object.entries(vitalGroup.vital_sign)) {
          vitalSignIds.push(vitalSign.vital_id);
        }
        vitalGroup.vitalSignIds = vitalSignIds.toString().replace(/(^,)|(,$)/g, "");
        vitalGroupData.push(vitalGroup);
      }
      if (vitalGroup.id == 1) {
        var vitalSignIds2 = [];
        for (const [key, vitalSign] of Object.entries(vitalGroup.vital_sign)) {
          vitalSignIds2.push(vitalSign.vital_id);
        }
        vitalGroup.vitalSignIds = vitalSignIds2.toString().replace(/(^,)|(,$)/g, "");
        allVital = vitalGroup;
      }
    }
    if (vitalGroupData.length == 0)
      vitalGroupData.push(allVital);


    this.setState({ selectedVitalGroups: vitalGroupData, vitalsType: vitalGroupData[0].id, displayVitals: vitalGroupData[0].vitalSignIds.split(',') })
    this.setState({ vitals_data_formatted: true })
    // this.makeVitalsHTML();
    // return vitals_data;
  }

  handleGraphApi = (vital_date) => {
    const { patient } = this.state;
    let date = moment(vital_date, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss');
    Axios.get(VITAL_GRAPH_DATE({ patient_id: patient.patient_id, end_date:date }))
      .then(success => {
        const { data } = success;
        this.setState({
          chartData: data.details,
          chartGraphSettings: data.constants.vitals.map(vD => vD.vital_code).toString(),
        })
      })
      .catch(err => console.log(err))
  }

  goToDetails = (e, id) => {
    // e.preventDefault();
    const { patient_id } = this.state;
    const PARAMS = {
      reference_id: id,
      reference_type: LS_SERVICE.get('reference_type'),
      patient_id
    }
    
    this.setState({
      detailsDataLoaded: false,
      growthChartSettings: {},
      otheroptions: [],
      growthChartData: {},
      growthData:[],
      showGrowthChart: false
    })

    Axios.post(PAST_VISIT_COMPLETE_DETAILS, qs.stringify(PARAMS))
      .then(async response => {
        const { data } = response;
        this.setState({
          completeDetailsData: data,
          visitVitals: data.visit_details.vitals_list ? Object.values(data.visit_details.vitals_list) : [],
          vitalsRange: Object.values(data.constants.vitals_range),
          constants: data.constants,
          detailsDataLoaded: true,
          vaccinationList: data.visit_details.vaccination_list,
          visitMenstrualHistory: data.visit_details.menstrualHistoryDetails,
          visitSexualHistory: data.visit_details.sexualHistoryDetails,
        })

        const { qms_token_id } = this.state.patient;
        let filtr_data = [];
        data.visit_details.milestones_list.forEach((ele, i) => {
            if(ele.milestone_categories.length > 0){
                let age = '';
                let cat_data = [];
              ele.milestone_categories.forEach((el, j) => {
                    age = el.child_milestones.age_label;
                    let combine = {cat_id: el.child_milestones.parent_category.id, cat: el.child_milestones.parent_category.category, sub_cat: el.child_milestones.category};
                    cat_data.push(combine);
                  
              });
                filtr_data[i] = {age: age, data: cat_data};
            }
          });

        let update_data = [];
        filtr_data.forEach((ele,i)=>{
            if(ele.age!=""){
                update_data.push(ele)  
            }
        });

        let final_data = [];
        update_data.forEach((el,index)=>{
                let new_obj = [];
                let temp_data = [];
                new_obj['age'] = el.age;
                el.data.forEach((ele,i) => {
                    if(temp_data[ele.cat_id]){
                        temp_data[ele.cat_id].sub_cat.push(ele.sub_cat)
                    }
                    else{
                        temp_data[ele.cat_id] = {...ele,sub_cat:[ele.sub_cat]}
                    }
                })
                new_obj['data'] = temp_data;
                final_data.push(new_obj);
        })
          this.setState({filterMilestoneData: final_data})

        if (data?.visit_details?.growthChartSettings?.growthchart_type && data?.visit_details?.growthChartSettings?.growthchart_age) {
          await this.setState({
            gender: this.state.patientData.gender == "Female" ? 'girls' : 'boys',
            growthChartSettings: data.visit_details.growthChartSettings,
            growthChartData: Object.values(data.visit_details.vitalsDetails.details)
          });

          if (this.state.patientData.cmonthsage != undefined && this.state.patientData.cmonthsage < 24 && this.state.patientData.cmonthsage >= 0) {
            await this.setState(prevState => {
              let prevItems = { ...prevState.patientData };
              prevItems.monthsage = prevItems.cmonthsage;
              return { patientData: prevItems };
            });
          }
          await this.setGrowthChartData();
        }
        
        this.handleGraphApi(data.visit_details.visit.token_date);
        this.makeVitalsData();

        const tabId = document.getElementById('pastvisitcard_' + id);
        setTimeout(() => {
          if (tabId !== null) {
            window.scrollTo({ top: (tabId.offsetTop + 200), behavior: 'smooth' });
          }
        }, 800)
      })



  }

  async setGrowthChartData() {
    let paedVitalsConstant = this.state.constants ? (this.state.constants.vital_groups['VG002'] ? this.state.constants.vital_groups['VG002'].vital_sign : this.state.constants.vitals_details) : [];

    let type = this.state.growthChartSettings.growthchart_type;
    let age = this.state.growthChartSettings.growthchart_age;
    let data = [];
    let otheroptions = [];
    if (type == 'CDC') {
      type = CDC;
    } else if (type == 'WHO') {
      type = WHO;
    } else if (type == 'IAP') {
      type = IAP;
    } else if (type == 'WHOIAP') {
      type = WHOIAP;
    } else {
      type = null;
    }
    let gendertype = this.state.gender;
    if (type && type[gendertype]) {
      const findex = type[gendertype].findIndex(x => x.age == age);
      if (findex !== -1) {

        let options = {};
        options.XLeft = type[gendertype][findex].XLeft;
        options.YBottom = type[gendertype][findex].YBottom;
        options.ageLabel = type[gendertype][findex].ageLabel;

        let ageToConsider;
        if (this.state.patientData.cdob && this.state.patientData.monthsage == this.state.patientData.cmonthsage) {
          if (options.ageLabel == 'months') {
            if (this.state.patientData.cmonthsage >= 12) {
              ageToConsider = this.state.patientData.cmonthsage + 1;
            } else {
              ageToConsider = 12 + 1;
            }
          } else {
            if (this.state.patientData.cmonthsage >= 12) {
              ageToConsider = (this.state.patientData.cmonthsage / 12).toFixed(2);
            } else {
              ageToConsider = 1;
            }
          }
        } else {
          if (options.ageLabel == 'months') {
            if (this.state.patientData.monthsage >= 12) {
              ageToConsider = this.state.patientData.monthsage + 1;
            } else {
              ageToConsider = 12 + 1;
            }
          } else {
            if (this.state.patientData.monthsage >= 12) {
              ageToConsider = (this.state.patientData.monthsage / 12).toFixed(2);
            } else {
              ageToConsider = 1;
            }
          }
        }
        if (type[gendertype][findex].datasets.height) {
          let datasets = [];
          type[gendertype][findex].datasets.height.forEach((ele, i) => {
            let data2 = []
            try {
              ele.data.forEach((ele2, i) => {
                if (ele2.x <= ageToConsider) {
                  data2.push(ele2);
                } else {
                  if (ele.data[i]) {
                    data2.push(ele.data[i]);
                  }
                  throw new Error(); // break
                }
              });
            }
            catch (e) {
            }
            let obj = {
              type: 'line',
              label: ele.label,
              label2: ele.label2,
              data: data2,
              labeltype: ele.labeltype,
              group: 'Height for age',
              xAxisID: 'bottom',
              yAxisID: 'L',
              borderWidth: 1,
              borderColor: ele.borderColor,
              fill: false,
              pointRadius: 0
            }
            if (ele.labeltype == '97') {
              otheroptions.push({ ...options, label: 'Height for age', YTop: Math.ceil(obj.data[obj.data.length - 1].y + 10), XRight: obj.data[obj.data.length - 1].x });
            }
            datasets.push(obj);
          });
          data.push({ datasets: datasets });
        }

        if (type[gendertype][findex].datasets.weight) {
          let datasets = [];
          type[gendertype][findex].datasets.weight.forEach((ele, i) => {
            let data2 = []
            try {
              ele.data.forEach((ele2, i) => {
                if (ele2.x <= ageToConsider) {
                  data2.push(ele2);
                } else {
                  if (ele.data[i]) {
                    data2.push(ele.data[i]);
                  }
                  throw new Error(); // break
                }
              });
            }
            catch (e) {
            }
            let obj = {
              type: 'line',
              label: ele.label,
              label2: ele.label2,
              data: data2,
              labeltype: ele.labeltype,
              group: 'Weight for age',
              xAxisID: 'bottom',
              yAxisID: 'L',
              borderWidth: 1,
              borderColor: ele.borderColor,
              fill: false,
              pointRadius: 0
            }
            if (ele.labeltype == '97') {
              otheroptions.push({ ...options, label: 'Weight for age', YTop: Math.ceil(obj.data[obj.data.length - 1].y + 10), XRight: obj.data[obj.data.length - 1].x });
            }
            datasets.push(obj);
          });
          data.push({ datasets: datasets });
        }

        if (type[gendertype][findex].datasets.headcc) {
          let datasets = [];
          type[gendertype][findex].datasets.headcc.forEach((ele, i) => {
            let data2 = []
            try {
              ele.data.forEach((ele2, i) => {
                if (ele2.x <= ageToConsider) {
                  data2.push(ele2);
                } else {
                  if (ele.data[i]) {
                    data2.push(ele.data[i]);
                  }
                  throw new Error(); // break
                }
              });
            }
            catch (e) {
            }
            let obj = {
              type: 'line',
              label: ele.label,
              label2: ele.label2,
              data: data2,
              labeltype: ele.labeltype,
              group: 'Head CC for age',
              xAxisID: 'bottom',
              yAxisID: 'L',
              borderWidth: 1,
              borderColor: ele.borderColor,
              fill: false,
              pointRadius: 0
            }
            if (ele.labeltype == '97') {
              otheroptions.push({ ...options, label: 'Head CC for age', YTop: Math.ceil(obj.data[obj.data.length - 1].y + 10), XRight: obj.data[obj.data.length - 1].x });
            }
            datasets.push(obj);
          });
          data.push({ datasets: datasets });
        }

        let vitals = [];
        paedVitalsConstant.forEach((data, i) => {
          let obj = {};
          obj.id = data.id;
          obj.display_name = data.display_name;
          obj.values = [];
          this.state.growthChartData.forEach((d, j) => {
            if (d[obj.id] && d[obj.id] != undefined && d[obj.id] != '') {
              let findind;
              let findvalue;
              if (type[gendertype][findex].ageLabel == 'months') {
                if (this.state.patientData.cdob && this.state.patientData.monthsage == this.state.patientData.cmonthsage) {
                  findvalue = moment(new Date(d.date)).diff(new Date(this.state.patientData.cdob), 'months', true).toFixed(1);
                } else {
                  findvalue = moment(new Date(d.date)).diff(new Date(this.state.patientData.dob), 'months', true).toFixed(1);
                }
              }
              if (type[gendertype][findex].ageLabel == 'years') {
                if (this.state.patientData.cdob && this.state.patientData.monthsage == this.state.patientData.cmonthsage) {
                  findvalue = moment(new Date(d.date)).diff(new Date(this.state.patientData.cdob), 'years', true).toFixed(1);
                } else {
                  findvalue = moment(new Date(d.date)).diff(new Date(this.state.patientData.dob), 'years', true).toFixed(1);
                }
              }
              findind = obj.values.findIndex(x => x.age == findvalue);
              if (findind === -1) {
                let objvalues = {};
                objvalues.val = d[obj.id];
                objvalues.date = d.date;
                objvalues.age = findvalue;
                objvalues.reference_id = d.reference_id;
                obj.values.push(objvalues);
              }
            }
          });
          vitals.push(obj);
        });
        vitals.forEach((dat, i) => {
          let vitals_max_value = 0;
          let scatterdata = [];
          dat.values.forEach((d, j) => {
            if (parseInt(d.val) > vitals_max_value) {
              vitals_max_value = parseInt(d.val);
            }
            scatterdata.push({ x: d.age, y: d.val, z: d.reference_id })
          })
          let scatterobj = {
            type: 'scatter',
            label: dat.display_name,
            data: scatterdata,
            xAxisID: 'bottom',
            yAxisID: 'L',
            backgroundColor: 'rgba(255, 0, 0, 1)',
            borderWidth: 1,
            radius: 4
          }
          if (dat.id == "V001" && type[gendertype][findex].datasets.height && data[0]) {
            let ytop = Math.max(otheroptions[0].YTop, vitals_max_value);
            let divider = 5;
            if (ytop >= 100) {
              divider = 10;
            }
            let remainder = ytop % divider;
            ytop = ytop + (divider - remainder);
            otheroptions[0].YTop = ytop;
            data[0].datasets.push(scatterobj);
          }

          if (dat.id == "V002" && type[gendertype][findex].datasets.weight && data[1]) {
            let ytop = Math.max(otheroptions[1].YTop, vitals_max_value);
            let divider = 5;
            if (ytop >= 100) {
              divider = 10;
            }
            let remainder = ytop % divider;
            ytop = ytop + (divider - remainder);
            otheroptions[1].YTop = ytop;
            data[1].datasets.push(scatterobj);
          }
          if (dat.id == "V016" && type[gendertype][findex].datasets.headcc && data[2]) {
            let ytop = Math.max(otheroptions[2].YTop, vitals_max_value);
            let divider = 5;
            if (ytop >= 100) {
              divider = 10;
            }
            let remainder = ytop % divider;
            ytop = ytop + (divider - remainder);
            otheroptions[2].YTop = ytop;
            data[2].datasets.push(scatterobj);
          }
        });
      }

      await this.setState({
        growthData: data,
        otheroptions,
        showGrowthChart: true
      })
    }
  }

  goToScroll = (e, id) => {
    const tabId = document.getElementById('pastvisitcard_' + id);
    setTimeout(() => {
      if (tabId !== null) {
        window.scrollTo({ top: (tabId.offsetTop + 200), behavior: 'smooth' });
      }
    }, 200)
  }

  goToContinue = (e, type, reference_id, date) => {
    e.preventDefault();
    const { patient, isPracticing } = this.state;
    const { hospital_id, patient_id, qms_token_id } = patient;
    // const qms_token_id = patient.qmsToken ? patient.qmsToken.qms_token_id : call_qms_token_id

    if (!isPracticing) {
      this.handleModalPopup(e, 'isPracticingModal');
      return;
    }

    // debugger;
    const REFERENCE_TYPE = LS_SERVICE.get('reference_type');
    if (type === 'SAME Rx') {
      if(LS_SERVICE.get('soap_type') == 'single'){
        this.props.history.push({
          pathname: `/copy-visit/selectedss/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${reference_id}/${REFERENCE_TYPE}/${qms_token_id}?type=continue`,
          state: { sameRxdate: date, patient }
        });
      }
      else{
        this.props.history.push({
          pathname: `/copy-visit/selected/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${reference_id}/${REFERENCE_TYPE}/${qms_token_id}?type=continue`,
          state: { sameRxdate: date, patient }
        });
      }
    } else {
      if(LS_SERVICE.get('soap_type') == 'single'){
        this.props.history.push({
          pathname: `/copy-visit/selectedss/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${reference_id}/${REFERENCE_TYPE}/${qms_token_id}`,
          state: { patient }
        });
      }
      else{
        this.props.history.push({
          pathname: `/copy-visit/selected/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${reference_id}/${REFERENCE_TYPE}/${qms_token_id}`,
          state: { patient }
        });
      }
    }
  }


  handleModalPopup = (e, popup, data = null) => {
    this.setState({
      [popup]: !this.state[popup]
    }, () => {
      const { chartModal } = this.state;
      if (chartModal) {
        this.setState({
          chartDataLoaded: true,
          chartsType: data.type,
          chartHeading: data.name
        })
      }
    })
  }

  getOpenCardClass(Obj, openVisit, index) {

    // console.log('>> cardsetting 111 jj', this.cardOpened)
    // console.log('>> cardsetting 111 kk', Obj, LS_SERVICE.get("staff_id"))
    // console.log('>> cardsetting 111 kk', openVisit, moment(Obj.token_date).format('D-MMM-YYYY'))
    // Obj.doctor_id
    let doctor_id = '';
    if (Obj.doctor_id !== undefined) {
      doctor_id = Obj.doctor_id;
    } else if (Obj.doctorDetails !== undefined || (Obj.doctorDetails !== undefined && Obj.doctorDetails.staff_id !== undefined)) {
      doctor_id = Obj.doctorDetails.staff_id;
    } else {
      return 'btn text-left page-title btn-bg-accordion-red past-visit-container-button w-100 collapsed';
    }
    // console.log('>> cardsetting 111 doctor_id', doctor_id)

    if (
      this.cardOpenedId === null && (
        (doctor_id === LS_SERVICE.get("staff_id") &&
          (openVisit === '?open_visit=self')) ||
        (openVisit === '?open_visit=any' && index === 0))
    ) {
      // console.log('>> cardsetting highlight')
      // this.setState({cardOpened: true, cardOpenedId: Obj.reference_id});
      this.cardOpened = true;
      if (Obj.referenceId !== undefined) {
        this.cardOpenedId = Obj.referenceId;
      } else if (Obj.reference_id !== undefined) {
        this.cardOpenedId = Obj.reference_id;
      }

      // console.log('>> cardsetting highlight this.cardOpenedId', this.cardOpenedId)
      return 'btn text-left page-title btn-bg-accordion-red past-visit-container-button w-100';
      // return {
      //   expanded: true,
      //   class: 'btn text-left page-title btn-bg-accordion-red past-visit-container-button w-100'
      // };
    } else {
      // console.log('>> cardsetting 2')
      return 'btn text-left page-title btn-bg-accordion-red past-visit-container-button w-100 collapsed';
      // return {
      //   expanded: false,
      //   class: 'btn text-left page-title btn-bg-accordion-red past-visit-container-button w-100 collapsed'
      // }
    }
  }

  enablePractice = async e => {
    e.preventDefault();
    practiceService.init({ initiater: 'SOAP Pages', status: 'start' });
    const practice = await API_SERVICE.post(TOGGLE_PRACTICE, {
      hospital_id: this.state.hospital_id,
      practice_session: 'start'
    });

    let is_practicing;
    if (practice.data && practice.data.is_practicing === 'Y') {
      is_practicing = true;
      LS_SERVICE.set('practicing', {
        status: true,
        hospital_id: this.state.hospital_id
      });
    } else {
      is_practicing = false;
    }

    this.setState({
      isPracticing: is_practicing,
    });
    this.handleModalPopup(null, 'isPracticingModal');
  }

  handleGraphClick = (e, type = '2') => {

    // if (this.state.range.length > 0)
    //   this.handleDateRangeGraphData(true);

    this.setState({
      // range: [],
      chartDataLoaded: false,
      chartsTab: type
    }, () => this.setState({ chartDataLoaded: true }))
  }


  handleDateRangeGraphData = async (reset = false) => {
    const { patient_id, range } = this.state;

    const PARAMS = {
      patient_id,
      start_date: !reset ? moment(range[0]._d).format('YYYY-MM-DD HH:mm:ss') : undefined,
      end_date: !reset ? moment(range[1]._d).format('YYYY-MM-DD HH:mm:ss') : undefined
    }

    const vitalGraphRangePromise = await Axios.get(VITALS_GRAPH(PARAMS))

    if (vitalGraphRangePromise.data.status === "success") {

      const { data } = vitalGraphRangePromise;

      this.setState({
        chartDataLoaded: false,
        chartData: data.details,
        vitalsRange: Object.values(data.constants.range),
      }, () => this.setState({
        chartDataLoaded: true,
      }))
    }

  }

  handleRangeChange = dates => {
    // let range = [];
    // if (dates !== null)
    //   range = [...dates]
    this.setState({
      range: dates !== null ? dates : []
    }, () => {
      this.handleDateRangeGraphData(dates == null)
    })
  }

  render() {

    const { patient_id, patient, openVisit, primaryDetailsData, primaryDetailsMessage,primaryBasicDetailsData, primaryBasicDetailsMessage, completeDetailsData, constants, vitalsRange, vitals_list, } = this.state;
    // const { vitals_list } = completeDetailsData;
    const loggedInUserId = LS_SERVICE.get('staff_id');

    return (
      <>

        {/* <!-- Page sidebar--> */}
        {(patient != undefined && patient.patient_id != undefined) ? <AsideLeft patient_id={patient.patient_id} reference_id={patient.qms_token_id} /> : null}


        <div className="content-wrapper">
          <div className="container-fluid emrfrm">

            <div className="row">
              <div className="col-12 mb-3">
                <div className="float-left">
                  <h1 className="page-title mb-0">Past Visit Information</h1>
                </div>
                <div className="float-right">
                  <button className="btn btn-outline-secondary px-4 small text-uppercase" onClick={e => GOBACK(e, this.props)}>BACK</button>
                </div>
              </div>
            </div>

            {this.state.DataLoaded === true ? (
              <div className="row">
                <div className="col-lg-12" id="PastVisitContainerFluid">
                  <div className="accordion" id="accordionPastVisit">
                    {
                      // For SOAP past visit data
                      LS_SERVICE.get("soapConfig") === SOAP &&
                        (Object.keys(primaryDetailsData).length > 0) ? (
                        primaryDetailsData.map((Obj, index) =>
                          <div className="card border-0 mb-4" id={'pastvisitcard_' + Obj.reference_id} key={index}>
                            <div className="row">
                              <div className="col-md-6">
                                <button
                                  role="button"
                                  data-toggle="collapse"
                                  data-target={'#collapse_' + index}
                                  aria-controls={'collapse_' + index}
                                  disabled = {Obj.confidential_prescription}
                                  onClick={e => this.goToDetails(e, Obj.reference_id)}
                                  // className={this.getOpenCardClass(Obj, openVisit, index)}
                                  className={`btn text-left page-title btn-bg-accordion-red past-visit-container-button w-100 ${(typeof Obj.reference_id != 'undefined' && openVisit == Obj.reference_id) || (typeof Obj.referenceId != 'undefined' && openVisit == Obj.referenceId) ? '' : 'collapsed'}`}
                                  // className={(Obj.doctor_id === LS_SERVICE.get("staff_id") && (openVisit === '?open_visit=self-'+ moment(Obj.token_date).format('D-MMM-YYYY'))) || (openVisit === '?open_visit=any' && index === 0) ?
                                  //   'btn text-left page-title btn-bg-accordion-red past-visit-container-button w-100' : 'btn text-left page-title btn-bg-accordion-red past-visit-container-button w-100 collapsed'
                                  // }
                                  // className="btn text-left page-title btn-bg-accordion-red past-visit-container-button w-100 collapsed"
                                  // aria-expanded={(Obj.doctor_id === LS_SERVICE.get("staff_id") && (openVisit === '?open_visit=self')) || (openVisit === '?open_visit=any' && index === 0) ? "true" : "false"}
                                  aria-expanded={this.state.cardOpenedId === Obj.referenceId ? "true" : "false"}

                                >
                                  <div className="float-left">
                                    <strong className="font-weight-normal">{Obj.doctor_name}</strong> - <small>{Obj.speciality}</small>
                                    <br />
                                    <small className="mt-1">{Obj.token_date} | {Obj.token_time}</small>
                                  </div>
                                  <div className="float-right mt-2">
                                    {
                                      Obj.confidential_prescription == false
                                    ?
                                      <button className="btn btn-sm float-right btn-primary text-uppercase show-rx-modal">VIEW RX</button>
                                    :
                                      <p class="text-uppercase text-secondary">Confidential</p>
                                    }
                                  </div>
                                </button>
                              </div>

                              {this.state.allowEdit ? <div className="col-md-6 text-right">
                                {Obj.doctor_id === LS_SERVICE.get("call_patient_detail")['doctor_id'] && this.state.patient.qms_token_id !== null && this.state.patient.qms_token_id == LS_SERVICE.get('call_patient_qms_token_id') ?
                                  <>
                                    {
                                      (
                                        (LS_SERVICE.get('slot_hospital_master') == 'Y' && Obj.own_master == 'Y' && LS_SERVICE.get('slot_hospital_id') == Obj.hospital_id)
                                        ||
                                        (LS_SERVICE.get('slot_hospital_master') == 'N' && Obj.own_master == 'N')
                                      )
                                    ?
                                      <>
                                        {index === 0 && this.state.currentPage == 1 ?
                                          <button className="btn btn-outline-primary text-uppercase mt-3 small d-inline-flex align-items-center icon-hover-white" onClick={e => this.goToContinue(e, 'SAME Rx', Obj.reference_id, Obj.token_date)}>
                                            {/* <img height="20" src={copyImg} alt="Copy" />   */}
                                            <i className="icon-copy"></i>
                                            &nbsp;CONTINUE SAME RX
                                          </button>
                                          :
                                          <button className="btn btn-outline-primary text-uppercase mt-3 small d-inline-flex align-items-center icon-hover-white" onClick={e => this.goToContinue(e, 'PRESCRIPTION', Obj.reference_id, '')}>
                                            {/* <img height="20" src={copyImg} alt="Copy" />   */}
                                            <i className="icon-copy"></i>
                                            &nbsp;COPY FROM THIS PRESCRIPTION
                                          </button>
                                        }
                                      </>
                                    :
                                      null
                                    }
                                  </>
                                  : ''}
                              </div> : null}
                            </div>


                            {/* For Card Details */}
                            <div id={'collapse_' + index} data-parent="#accordionPastVisit"
                              className={(Obj.referenceId != undefined && this.cardOpenedId === Obj.referenceId) || (Obj.reference_id != undefined && this.cardOpenedId === Obj.reference_id) ? "collapse row show" : "collapse row"}
                            >

                              {
                                (typeof this.state.completeDetailsData !== 'undefined' && Object.keys(this.state.completeDetailsData).length > 0 && this.state.detailsDataLoaded) ?

                                  // For Subjective Details
                                  <>
                                    {typeof this.state.completeDetailsData.visit_details.subjective_list !== 'undefined' || this.state.completeDetailsData.visit_details.subjective_list ?
                                      <div className="col-lg-12 mt-3">
                                        <h2 className="page-title">Subjective</h2>
                                        <div className="row">
                                          <div className="col-12 mt-3">
                                            {this.state.completeDetailsData.visit_details.subjective_list.map((Subjective, index) =>
                                              Subjective.description !== '' && Subjective.description !== null ?
                                                ((Obj.doctor_id === loggedInUserId && Subjective.subjective_id == 3) || Subjective.subjective_id != 3) ? <div className="form-group normal subjective_info" key={index}>
                                                  <label className="control-label mb-0">{Subjective.subjective_title}</label>
                                                  <pre>{Subjective.description}</pre>
                                                </div> : null
                                                : ''
                                            )}
                                          </div>
                                        </div>
                                      </div>

                                      : ''}

                                    {/* VITALS */}

                                    {this.state.detailsDataLoaded && Object.keys(this.state.visitVitals).length > 0 ?
                                      <div className="col-12 mb-4">
                                        <div className="card rounded-0 cardbg">
                                          <div className="card-header card-flex">
                                            <label className="float-left">Vitals</label>

                                            <div className="vital-right ml-auto d-flex align-content-center">
                                              <Badge className="mr-2 vr-badge" color={'#ffe6e8'} text={'Vitals entered by Patient'} />
                                              <button type="button" className="btn top-chart-btn btn-sm p-0"
                                                onClick={(e) => this.handleModalPopup(e, 'chartModal', { type: 'all', name: 'Trend Graphs' })}>
                                                <i className="icon-chart"></i>
                                              </button>
                                            </div>
                                          </div>
                                          {/* {this.state.vitals_data_formatted > 0 ? ( */}
                                            <Vitals
                                              dataLoaded={true}
                                              constants={this.state.constants}
                                              visitVitalsFormatted={this.state.visitVitalsFormatted}
                                              handleModalPopupStateLift={this.handleModalPopup}
                                              comp='pastvisit'
                                            />
                                          {/* ) : null} */}
                                        </div>
                                      </div>
                                      : null}

                                      {this.state.completeDetailsData.visit_details != undefined && this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails ?
                                        <div className="col-12 mt-4">
                                          <div className="row">
                                            <h2 className="page-title col-12 mb-3">
                                            Menstrual Hx <small className="text-muted">({moment(this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.createdAt).format("DD MMM, YYYY | hh:mm A")})</small>
                                            </h2>
                                                <>
                                                {(this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.cycleLength && this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.cycleLength !== "") ?
                                                <div key={this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.id} className="col text-dark border-right">
                                                  {'Cycle Length (Days)'}
                                                  <span className="text-muted d-block mt-2">
                                                    {this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.cycleLength && this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.cycleLength !== "" ? this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.cycleLength : "-"}
                                                  </span>
                                                </div>
                                                :''}
                                                {(this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.durationOfPeriod && this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.durationOfPeriod !== "") ?
                                                <div key={this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.id} className="col text-dark border-right">
                                                  {'Duration of Period (Days)'}
                                                  <span className="text-muted d-block mt-2">
                                                    {this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.durationOfPeriod && this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.durationOfPeriod !== "" ? this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.durationOfPeriod : "-"}
                                                  </span>
                                                </div>
                                                :''}
                                                {(this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.flow && this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.flow != "") ? (
                                                <div key={this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.id} className="col text-dark border-right">
                                                  {'Flow'}
                                                  <span className="text-muted d-block mt-2">
                                                    {(this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.flow) ? this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.flow.charAt(0).toUpperCase() + this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.flow.slice(1) : '-'}
                                                  </span>
                                                </div>
                                                )
                                                :''}
                                                {(this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.regularity && this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.regularity !="") ?
                                                <div key={this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.id} className="col text-dark border-right">
                                                  {'Regularity'}
                                                  <span className="text-muted d-block mt-2">
                                                    {(this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.regularity) ? this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.regularity.charAt(0).toUpperCase() + this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.regularity.slice(1) : '-'}
                                                  </span>
                                                </div>
                                                :''}
                                                {(this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.painScore && this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.painScore !== "") ?
                                                <div key={this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.id} className="col text-dark border-right">
                                                  {'Pain Score'}
                                                  <span className="text-muted d-block mt-2">
                                                    {this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.painScore && this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.painScore !== "" ? this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.painScore : "-"}
                                                  </span>
                                                </div>
                                                :null}
                                                {(this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.changesInCycle && this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.changesInCycle !="") ?
                                                <div key={this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.id} className="col text-dark border-right">
                                                  {'Change in Cycle'}
                                                  <span className="text-muted d-block mt-2">
                                                    {this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.changesInCycle && this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.changesInCycle !== "" ? (this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.changesInCycle === '1') ? `Yes, ${this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.since}` : `No` : "-"}
                                                  </span>
                                                </div>
                                                :''}
                                                {(this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.remarks && this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.remarks !== "") ?
                                                <div key={this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.id} className="col text-dark border-right">
                                                  {'Remarks'}
                                                  <span className="text-muted d-block mt-2" title={this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.remarks}>
                                                  {(this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.remarks) ? this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.remarks.charAt(0).toUpperCase() + this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.remarks.slice(1).substring(0, 40) : '-'} {(this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryDetails.remarks.length > 40?'...':'') }
                                                  </span>
                                                </div>
                                                :''}
                                                </>
                                          </div>
                                      </div>
                                    : null }

                                    <div className="col-12 fix-height-right col-md-12" style={{ marginTop: "20px" }}>
                                    {MENSTRUAL_HX_TREND_GRAPH !== undefined && MENSTRUAL_HX_TREND_GRAPH.length > 0 ? MENSTRUAL_HX_TREND_GRAPH.map((d, i) => (
                                        (this.state.completeDetailsData.visit_details != undefined && this.state.completeDetailsData.visit_details.menstrualHistoryDetails?.menstrualHistoryPdfDetails.checkedMenstrualCycleLength == true && d.val == 'cycle_length' || this.state.completeDetailsData.visit_details != undefined && this.state.completeDetailsData.visit_details.menstrualHistoryDetails?.menstrualHistoryPdfDetails.checkedMenstrualDurationOfPeriod == true && d.val == 'duration_of_period' || this.state.completeDetailsData.visit_details != undefined && this.state.completeDetailsData.visit_details.menstrualHistoryDetails?.menstrualHistoryPdfDetails.checkedMenstrualPainScore == true && d.val == 'pain_score')?
                                        <TrendGraph
                                            chartData={this.state.completeDetailsData.visit_details != undefined && this.state.completeDetailsData.visit_details.menstrualHistoryDetails.menstrualHistoryChartsData}
                                            dataLoaded={true}
                                            chartType={d.val}
                                            chartTypes={'all'}
                                            chartTitle={'Trend Graph'}
                                            title={d.des}
                                            chartsTab={2}
                                            checkBoxVisible={false}
                                        />
                                        : null
                                    )) : null}
                                    </div>

                                    {/* Sexual History */}
                                    {this.state.visitSexualHistory != undefined && this.state.visitSexualHistory.sexualHistoryDetails && this.state.visitSexualHistory.sexualHistoryDetails.detailed_description ? 
                                    <div className="col-12 mt-4">
                                        <div className="row">
                                          <h2 className="page-title col-12 mb-3">
                                          Sexual Hx <small className="text-muted">({moment(this.state.visitSexualHistory.sexualHistoryDetails.createdAt).format("DD MMM, YYYY | hh:mm A")})</small>
                                          </h2>
                                              <p className="page-title col-12 mb-3">{this.state.visitSexualHistory.sexualHistoryDetails.detailed_description}</p>
                                        </div>
                                    </div>
                                  : null }

                                    {/*For Growth Charts*/}
                                    {Object.keys(this.state.growthChartSettings).length && this.state.showGrowthChart ?
                                      <div className="row col-12 mt-3">
                                        <div className="col-12 mb-2">
                                          <div className="float-left">
                                            <h3 className="page-title">
                                              <a href="#" className="nostyle-link">Growth Charts</a>
                                            </h3>
                                          </div>
                                        </div>
                                        {this.state.growthData.map((data, i) => {
                                          if (Object.keys(data).length) {
                                            return (<div key={i} className={`mb-3 bg-grey ${this.state.growthData.length == 2 ? 'col-6' : 'col-4'}`} style={{ height: '350px' }}>
                                              <IndividualPaediatricChart data={data} otheroptions={this.state.otheroptions[i]} />
                                            </div>)
                                          }
                                        }
                                        )}

                                        <ul className="legend_unit_label">
                                          {this.state.growthChartSettings.growthchart_type == 'CDC' ? <>
                                            <li><span className="fst"></span>3rd Percentile</li>
                                            <li><span className="thrd"></span>5th Percentile</li>
                                            <li><span className="fif"></span>50th Percentile</li>
                                            <li><span className="nint"></span>97th Percentile</li>
                                          </> : this.state.growthChartSettings.growthchart_type == 'WHO' ? <>
                                            <li><span className="fst"></span>1st Percentile</li>
                                            <li><span className="thrd"></span>3rd Percentile</li>
                                            <li><span className="fif"></span>50th Percentile</li>
                                            <li><span className="nint"></span>97th Percentile</li>
                                          </> : (this.state.growthChartSettings.growthchart_type == 'IAP' || this.state.growthChartSettings.growthchart_type == 'WHOIAP') ? <>
                                            <li><span className="fst"></span>3rd Percentile</li>
                                            {/* <li><span className="thrd"></span>5th Percentile</li> */}
                                            <li><span className="fif"></span>50th Percentile</li>
                                            <li><span className="nint"></span>97th Percentile</li>
                                          </> : null}

                                        </ul>
                                      </div> : null}

                                    {/*For Development Milestone*/}

                                    <div className="row col-12 mt-3 dev-miles">
                                        {this.state.filterMilestoneData && this.state.filterMilestoneData.length > 0 ? 
                                            <>
                                                {this.state.filterMilestoneData?.map((el,i) => (
                                                    <>
                                                        <div className="col-12 mb-2">
                                                            <h3 className="page-title" style={{color: '#5c5c5c'}}>
                                                                {i==0 ? "Developmental Milestones : " : ""}<span className="text-danger">{el.age}</span>
                                                                </h3>
                                                        </div>
                                                        {
                                                            el.data.map((el1,j) => (   
                                                                <div className="col-6 float-left">
                                                                    <h6 className="mb-3" style={{color: '#5c5c5c'}}>{el1.cat}: </h6>
                                                                    <ul>
                                                                    {el1.sub_cat?.map((el2,k) => (
                                                                            <li key={k}>{el2}</li>
                                                                    ))}
                                                                    </ul>
                                                                </div>
                                                            ))
                                                        }
                                                    </>
                                                    )) 
                                                }
                                            </> : ''
                                        }
                                    </div>

                                    {/* For Assessment Details  */}
                                    {typeof this.state.completeDetailsData.visit_details.assessment_list !== 'undefined' || this.state.completeDetailsData.visit_details.assessment_list ?
                                      <div className="col-lg-12 mt-3">
                                        <h2 className="page-title">Assessment</h2>
                                        <table className="table table-bordered" id="diagnosis-list">
                                          <thead>
                                            <tr>
                                              <td width="40%">DIAGNOSIS</td>
                                              <td width="100px">ICD 10</td>
                                              <td width="300px">NOTES</td>
                                              <td width="200px">IMAGE REFERENCE</td>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {this.state.completeDetailsData.visit_details.assessment_list.map((assessment, index) =>
                                              assessment?.diagnosisInfo?.diagnose_description !== '' && assessment?.diagnosisInfo?.diagnose_description !== null ? (
                                                <tr key={index}>
                                                  <td className="text-primary">
                                                    {(DIAGNOSIS_DO_NOT_PRINT.includes(assessment?.diagnosisInfo?.diagnosis_id) == true || !assessment?.diagnosisInfo?.icd_code || assessment?.diagnosisInfo?.icd_code == "" || assessment?.diagnosisInfo?.icd_code == null) ? assessment.diagnosisNotes : assessment?.diagnosisInfo?.diagnose_description}
                                                  </td>
                                                  <td><span className="text-primary">{assessment?.diagnosisInfo?.icd_code}</span></td>
                                                  <td><span className="text-primary">
                                                    {(DIAGNOSIS_DO_NOT_PRINT.includes(assessment?.diagnosisInfo?.diagnosis_id) == true || !assessment?.diagnosisInfo?.icd_code || assessment?.diagnosisInfo?.icd_code == "" || assessment?.diagnosisInfo?.icd_code == null) && assessment.diagnosisNotes ? '-' : (assessment.diagnosisNotes ? assessment.diagnosisNotes : '-')}
                                                    </span></td>
                                                  <td>
                                                    {(assessment.VisitAssessmentImage.length > 0 && assessment.VisitAssessmentImage[0].imagePath !== null) ?
                                                      <img src={assessment.VisitAssessmentImage[0].imagePath} className="img-fluid" alt={assessment?.diagnosisInfo?.icd_code} />
                                                      : ''}
                                                  </td>
                                                </tr>
                                              ) : ''
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                      : ''}

                                    {/* For Plan Details  */}

                                    {
                                      (typeof this.state.completeDetailsData.visit_details.medicine_list !== 'undefined' && this.state.completeDetailsData.visit_details.medicine_list !== null && this.state.completeDetailsData.visit_details.medicine_list.length !== 0) ||
                                        (typeof this.state.completeDetailsData.visit_details.test_list !== 'undefined' && this.state.completeDetailsData.visit_details.test_list !== null && this.state.completeDetailsData.visit_details.test_list.length !== 0) || 
                                        (typeof this.state.completeDetailsData.visit_details.vaccination_list !== 'undefined' && this.state.completeDetailsData.visit_details.vaccination_list !== null && this.state.completeDetailsData.visit_details.vaccination_list.length !== 0) ?

                                        <div className="col-lg-12 mt-3">
                                          <h2 className="page-title">Plan</h2>

                                          {typeof this.state.completeDetailsData.visit_details.vaccination_list !== 'undefined' && this.state.completeDetailsData.visit_details.vaccination_list !== null && this.state.completeDetailsData.visit_details.vaccination_list.length>0 ?
                                            <div className="row mb-3">
                                              <div className="col-12"><h3 className="page-title mt-2">Vaccines Administered</h3></div>
                                              <div className="col-12">
                                                <div className="tab-content">
                                                      <div className="tab-pane fade show active" role="tabpanel">
                                                          <div className="table-responsive">
                                                              <table className="table table-default">
                                                                  <thead>
                                                                      <tr>
                                                                          <th style={{ "width": "190px" }}>VACCINE</th>
                                                                          <th style={{ "width": "200px" }}>VACCINE Name</th>
                                                                          <th style={{ "width": "140px" }}>DUE DATE</th>
                                                                          <th style={{ "width": "140px" }}>Given Outside</th>
                                                                          <th style={{ "width": "140px" }}>Given On</th>
                                                                          <th style={{ "width": "125px" }}>Batch</th>
                                                                          <th style={{ "width": "140px" }}>EXPIRY DATE</th>
                                                                          <th style={{ "width": "140px" }}>Remarks</th>
                                                                      </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                      
                                                                      {this.state.vaccinationList?.map((iterable, i) => {
                                                                              
                                                                              return (
                                                                                  <>
                                                                                      <tr >
                                                                                          <td>{`${iterable.name} - ${iterable.dose_number}`}</td>
                                                                                          <td>{ iterable.vac_name }</td>
                                                                                          <td>{iterable.duedate && iterable.type == 'O' ? "" : iterable.due_date} </td>
                                                                                          <td>{iterable.given_outside == 1 ? "Yes" : "No"}</td>
                                                                                          <td className="datepicker">{iterable.given_on ? moment(iterable.given_on).format('DD/MM/YYYY') : iterable.given_on}</td>
                                                                                          <td>
                                                                                              <input type="text" disabled readOnly className="table-form-control remark" placeholder="Type Batch"  value={iterable.batch} />
                                                                                          </td>
                                                                                          <td>{iterable.expiry_date ? moment(iterable.expiry_date).format('MM/YYYY') : iterable.expiry_date} </td>
                                                                                          <td>
                                                                                              <input type="text" className="table-form-control remark" disabled readOnly  value={iterable.remarks} placeholder="Type Remark" />
                                                                                          </td>
                                                                                      </tr>
                                                                                  </>
                                                                              )
                                                                      })}
                                                                  </tbody>
                                                              </table>
                                                          </div> 
                                                      </div>
                                                  </div>
                                                </div>
                                            </div>
                                            : ''}

                                          {typeof this.state.completeDetailsData.visit_details.medicine_list !== 'undefined' && this.state.completeDetailsData.visit_details.medicine_list !== null ?
                                            <div className="row mb-3">
                                              <div className="col-12"><h3 className="page-title mt-2">Medicines</h3></div>
                                              <div className="col-12">
                                                <MedicineTable drugsData={this.state.completeDetailsData.visit_details.medicine_list} viewType={'copy-from-prescription'}
                                                  showAction={false} showSelectCheck={false} constants={this.state.completeDetailsData.constants} />
                                              </div>
                                            </div>
                                            : ''}

                                          {typeof this.state.completeDetailsData.visit_details.test_list !== 'undefined' && this.state.completeDetailsData.visit_details.test_list !== null && this.state.completeDetailsData.visit_details.test_list.length > 0?
                                            <div className="row mb-3">
                                              <div className="col-12"><h3 className="page-title mt-2">Tests</h3></div>
                                              <div className="col-12">
                                                <div className="table-responsive">
                                                  <table className="table table-bordered">
                                                    <thead>
                                                      <tr>
                                                        <td>Name</td>
                                                        <td>Instructions</td>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {this.state.completeDetailsData.visit_details.test_list.map((test, index) =>
                                                        <tr key={index}>
                                                          <td>{test.test_name}</td>
                                                          <td>{test.instructions}</td>
                                                        </tr>
                                                      )}
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </div>
                                            : ''}

                                        </div>

                                        : ''}

                                    {
                                      this.state.completeDetailsData.visit_details.hasOwnProperty("subjective_list") === false &&
                                        this.state.completeDetailsData.visit_details.hasOwnProperty("assessment_list") === false &&
                                        this.state.completeDetailsData.visit_details.hasOwnProperty("medicine_list") === false &&
                                        this.state.completeDetailsData.visit_details.test_list.length === 0
                                        ? <div className="col-6  text-center mt-4">No information recorded for this visit</div> : ''
                                    }
                                  </>
                                  : <div className="col-6  text-center mt-4">{LOADER_RED}</div>

                              }



                            </div>
                          </div>

                        )) : (
                        // For Basic past visit data
                        Object.keys(primaryBasicDetailsData).length > 0 ? (
                          primaryBasicDetailsData.map((Obj, index) =>
                            <div className="card border-0 mb-5" id={'pastvisitcard_' + Obj.id} key={Obj.id}>
                              <div className="row">
                                <div className="col-md-6">
                                  <button
                                    role="button"
                                    data-toggle="collapse"
                                    data-target={'#collapse_' + index}
                                    aria-controls={'collapse_' + index}
                                    // onClick={e => this.goToDetails(e, Obj.reference_id)}
                                    onClick={e => this.goToScroll(e, Obj.id)}
                                    // className="btn text-left page-title btn-bg-accordion-red past-visit-container-button w-100 collapsed"
                                    className={this.getOpenCardClass(Obj, openVisit, index)}
                                    aria-expanded={(Obj.referenceId != undefined && this.cardOpenedId === Obj.referenceId) || (Obj.reference_id != undefined && this.cardOpenedId === Obj.reference_id) ? "true" : "false"}
                                  >
                                    <div className="float-left">
                                      <strong>
                                        {Obj.doctorDetails.first_name}
                                        {Obj.doctorDetails.middle_name !== '' && Obj.doctorDetails.middle_name !== null ? ' ' + Obj.doctorDetails.middle_name : ''}
                                        {Obj.doctorDetails.last_name !== '' && Obj.doctorDetails.last_name !== null ? ' ' + Obj.doctorDetails.last_name : ''}
                                      </strong>
                                      {/* - <small>{Obj.speciality}</small> */}
                                      <br />
                                      <small className="mt-1">{moment(Obj.qmsDetails.token_date).format('D MMM, YYYY')}  | {moment(Obj.qmsDetails.token_time, "HH:mm:ss").format('hh:mm A')}</small>
                                    </div>
                                    <div className="float-right mt-2">
                                      <button className="btn btn-sm float-right btn-primary text-uppercase show-rx-modal">VIEW RX</button>
                                    </div>
                                  </button>
                                </div>

                              </div>


                              {/* For Card Details */}
                              <div id={'collapse_' + index} data-parent="#accordionPastVisit"
                                className={(Obj.referenceId != undefined && this.cardOpenedId === Obj.referenceId) || (Obj.reference_id != undefined && this.cardOpenedId === Obj.reference_id) ? "collapse row show" : "collapse row"}>

                                <div className="col-lg-12 mt-3">
                                  <div className="row">
                                    {Obj.symptomDiagnosis !== null && Obj.symptomDiagnosis !== '' ?
                                      <div className="col-12 mt-2">
                                        <div className="form-group normal subjective_info">
                                          <label className="control-label mb-0">SYMPTOMS AND DIAGNOSIS</label>
                                          <pre>{Obj.symptomDiagnosis}</pre>
                                        </div>
                                      </div>
                                      : ''}

                                    {Obj.medicineTest !== null && Obj.medicineTest !== '' ?
                                      <div className="col-12 mt-2">
                                        <div className="form-group normal subjective_info">
                                          <label className="control-label mb-0">MEDICINES PRESCRIBED</label>
                                          <pre>{Obj.medicineTest}</pre>
                                        </div>
                                      </div>
                                      : ''}


                                    {Obj.testsAdviced !== null && Obj.testsAdviced !== '' ?
                                      <div className="col-12 mt-2">
                                        <div className="form-group normal subjective_info">
                                          <label className="control-label mb-0">TESTS ADVISED</label>
                                          <pre>{Obj.testsAdviced}</pre>
                                        </div>
                                      </div>
                                      : ''}


                                    {Obj.instructionsToPatients !== null && Obj.instructionsToPatients !== '' ?
                                      <div className="col-12 mt-2">
                                        <div className="form-group normal subjective_info">
                                          <label className="control-label mb-0">INSTRUCTIONS FOR PATIENTS</label>
                                          <pre>{Obj.instructionsToPatients}</pre>
                                        </div>
                                      </div>
                                      : ''}


                                    {Obj.isUpdatePrescriptionGen !== null && Obj.isUpdatePrescriptionGen !== '' ?
                                      <div className="col-12 mt-2">
                                        <div className="form-group normal subjective_info">
                                          <label className="control-label mb-0">UPDATE PRESCRIPTION</label>
                                          <pre>{Obj.isUpdatePrescriptionGen}</pre>
                                        </div>
                                      </div>
                                      : ''}

                                    {
                                      Obj.symptomDiagnosis === null &&
                                        Obj.medicineTest === null &&
                                        Obj.testsAdviced === null &&
                                        Obj.instructionsToPatients === null &&
                                        Obj.isUpdatePrescriptionGen === null && Obj.isUpdatePrescriptionGen === '' ?
                                        <div className="col-6 text-center">No information recorded for this visit</div>
                                        : ''}

                                  </div>
                                </div>


                                {/* {
                                this.state.completeDetailsData.visit_details.hasOwnProperty("subjective_list") === false &&
                                  this.state.completeDetailsData.visit_details.hasOwnProperty("assessment_list") === false &&
                                  this.state.completeDetailsData.visit_details.hasOwnProperty("medicine_list") === false &&
                                  this.state.completeDetailsData.visit_details.test_list.length === 0
                                  ? <div className="col-6  text-center mt-4">No information recorded for this visit</div> : ''
                              } */}




                              </div>
                            </div>

                          )) : (null)
                      )}

                    {
                      Object.keys(primaryDetailsData).length === 0 && primaryDetailsMessage !== '' ? (
                        <div className="card bg-light5">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-12 text-center">{primaryDetailsMessage}</div>
                            </div>
                          </div>
                        </div>
                      ) : (null)
                    }
                    {
                      Object.keys(primaryBasicDetailsData).length === 0 && primaryBasicDetailsMessage !== '' ? (
                        <div className="card bg-light5">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-12 text-center">{primaryBasicDetailsMessage}</div>
                            </div>
                          </div>
                        </div>
                      ) : (null)
                    }
                  </div>
                </div>
                <div className="col-12 mb-5">
                  { (primaryDetailsData !== null && this.state.countPage > 10 ) || (primaryBasicDetailsData !== null && this.state.countPage > 10) ?
                    <Pagination defaultCurrent={this.state.currentPage} total={this.state.countPage} onChange={this.navigateToPage} />
                    : null}
                </div>
              </div>
            ) : (
                (Object.keys(primaryDetailsData).length === 0 && primaryDetailsMessage !== '') || (Object.keys(primaryBasicDetailsData).length === 0 && primaryBasicDetailsMessage !== '') ?
                null
                :
                <div className="row">
                  <div className="col-12 text-center mt-2">{LOADER_RED}</div>
                </div>
            )

            }
          </div>
        </div>

        {/* || CHART MODAL */}
        {this.state.chartModal?
        <Modal
          // title={`${this.state.chartHeading} Graphs`}
          title={`Trend Graph`}
          visible={this.state.chartModal}
          onCancel={(e) => this.handleModalPopup(e, 'chartModal')}
          style={{ top: '40px', maxWidth: this.state.chartsType === 'all' ? '90%' : '600px' }}
          width="100%"
          className={`${this.state.chartsType === 'all' ? 'all_vital' : 'single_vital'}`}
          bodyStyle={{ maxHeight: '700px', overflowY: 'auto' }}
          footer={this.state.chartsType === 'all' ? (
            <div className="text-center justify-content-center">
              <button type="submit" className="btn btn-primary text-uppercase ml-2"
                onClick={e => this.handleModalPopup(e, 'chartModal')}>
                Confirm
              </button>
            </div>) : null
          }
        >

          <div className={`col wrap_chart_filter d-flex justify-content-around align-items-center ${this.state.chartsType !== 'all' ? 'wrap_chart_filter_small' : ''}`}>
            <ul className="chart_filter">
              <li>
                <button type="button" className={`btn btn-default chart-day ${this.state.chartsTab === '0' ? 'active' : ''}`}
                  onClick={(e) => this.handleGraphClick(e, '0')}>DAY</button>
              </li>
              <li>
                <button type="button" className={`btn btn-default chart-week ${this.state.chartsTab === '1' ? 'active' : ''}`}
                  onClick={(e) => this.handleGraphClick(e, '1')}>WEEK</button>
              </li>
              <li>
                <button type="button" className={`btn btn-default chart-month ${this.state.chartsTab === '2' ? 'active' : ''}`}
                  onClick={(e) => this.handleGraphClick(e, '2')}>MONTH</button>
              </li>
              <li>
                <button type="button" className={`btn btn-default chart-year ${this.state.chartsTab === '3' ? 'active' : ''}`}
                  onClick={(e) => this.handleGraphClick(e, '3')}>YEAR</button>
              </li>
            </ul>

            {this.state.chartsType === 'all' && (
              <RangePicker
                allowClear={true}
                value={this.state.range}
                onChange={this.handleRangeChange}
                disabledDate={DISABLED_FUTURE_DATES}
                format="DD/MM/YYYY"
              />
            )}
          </div>

          {this.state.chartDataLoaded ? ( //update for past visit chart
            <ChartPastVisit
              chartData={this.state.chartData}
              dataLoaded={this.state.chartDataLoaded}
              chartsType={this.state.chartsType}
              reference_id={this.state.patient.qms_token_id}
              chartGraphSetting={this.state.chartGraphSettings}
              chartsTab={this.state.chartsTab}
              constants={this.state.constants}
              checkBoxDisable={true}
            />
          ) : null}
        </Modal>
        : null }

        {/** || isPracticing Modal */}
        <Modal
          title={false}
          closable={false}
          visible={this.state.isPracticingModal}
          footer={false}
        >
          <div className="row">
            <div className="col-12 my-3 text-center">
              <h6>Do you want to Start Practice?</h6>
            </div>
          </div>
          <div className="modal-footer justify-content-center">
            <div className="btn btn-outline-secondary px-5" onClick={e => this.handleModalPopup(e, 'isPracticingModal')}>No</div>
            <div className="btn btn-primary px-5" onClick={this.enablePractice}>Yes</div>
          </div>
        </Modal>

      </>
    )
  }
}

export default PastVisit;
