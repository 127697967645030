import React, { Component, createRef } from 'react';
import Axios from 'axios';
import { DURATION_TYPE, INFORMED_BY, MEDICATION_ON, FETAL_MOVEMENT, PRESENTATION, RELATIONSHIP_TO_PP_BRIM } from "../../utils/constant";
import moment from "moment";
import ReactToPrint from "react-to-print";
import GPALE from "../../components/gynaecology/GPALE";
import { DatePicker, Radio, Modal, Select, Button, Form, Checkbox, Input, Tooltip, AutoComplete } from 'antd';
import AsideLeft from '../../components/aside/asideleft';
import LS_SERVICE from "../../utils/localStorage";
import AncCard from './anccard';
import PrintPreviousAnc from './print-previous-anc';
const { Option } = Select;

export default class AncCardView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            patientInfo: props.patientInfo || [],
            get_prev_anc_details: props.prevAncCardData || [],
            risk_factors_options: props.riskFactorsOptions || [],
            indexKey: props.index,
        }
    }
    autoHeight = (e) => {
        if (e)
            e.target.style.height = e.target.scrollHeight + "px";
    }

    componentDidMount() {
        // this.getPreviousAncData();
    }

    getListValue(selectedValue, OptionObj = null) {
        if (selectedValue == 0 || selectedValue === null) {
            return "-";
        } else if (OptionObj) {
            return OptionObj[selectedValue];
        } else {
            return selectedValue;
        }
    }


    getPatientAge(dob) {
        var ageD = Math.floor(moment().diff(moment(dob, 'YYYY-MM-DD'), 'D') / (1000 * 3600 * 24));
        var ageM = moment().diff(moment(dob, 'YYYY-MM-DD'), 'M');
        var ageY = ageM / 12;
        var remainM = ageM % 12;
        ageY = Math.floor(ageY);
        var ageText = '';
        if (ageM == 0)
            ageText = ageD + ' Day' + (ageD == 1 ? '' : 's');
        else if (ageY < 1)
            ageText = ageM + ' Mon' + (ageM == 1 ? '' : 's');
        else if (ageY <= 5) {
            if (remainM != 0) {
                ageText = ageY + ' Yr' + (ageY == 1 ? '' : 's ') + remainM + ' Mon' + (remainM == 1 ? '' : 's');
            } else {
                ageText = ageY + ' Yr' + (ageY == 1 ? '' : 's');
            }
        }
        else
            ageText = ageY + ' Yrs';
        return ageText;
    }

    getPageMargins = () => {
        return `
            @media print {
                @page { size: A4; margin: 15mm 5mm 10mm 5mm !important; }
            }
        `;
    };

    render() {
        const { patientInfo, get_prev_anc_details, risk_factors_options } = this.state;

        let riskfactor = get_prev_anc_details.riskFactors ? get_prev_anc_details?.riskFactors.split(',').map(Number) : [];
        let Immunoprophylaxis = get_prev_anc_details.Immunoprophylaxis ? JSON.parse(get_prev_anc_details.Immunoprophylaxis) : [];
        let allergy = get_prev_anc_details.allergies ? JSON.parse(get_prev_anc_details.allergies) : [];
        let existingCondition = get_prev_anc_details.existingCondition ? JSON.parse(get_prev_anc_details.existingCondition) : [];
        let menstrualHx = get_prev_anc_details.menstrualHx ? JSON.parse(get_prev_anc_details.menstrualHx) : [];
        let obstetricsHx = get_prev_anc_details.obstetricsHx ? JSON.parse(get_prev_anc_details.obstetricsHx) : [];
        let physicalExamination = get_prev_anc_details.physicalExamination ? JSON.parse(get_prev_anc_details.physicalExamination) : [];
        let fetalParameters = get_prev_anc_details.fetalParameters ? JSON.parse(get_prev_anc_details.fetalParameters) : [];
        let tests = get_prev_anc_details.tests ? JSON.parse(get_prev_anc_details.tests) : [];
        let radiology_tests = get_prev_anc_details.Radiotests ? JSON.parse(get_prev_anc_details.Radiotests) : [];
        let gpaleLactating = get_prev_anc_details.gpaleLactating ? JSON.parse(get_prev_anc_details.gpaleLactating) : [];
        let outcomeGroup = get_prev_anc_details.outcomeGroup ? JSON.parse(get_prev_anc_details.outcomeGroup) : [];
        let total_count_fetal = 0;
        fetalParameters?.map(ele => {
            ele?.fetal_params?.map(obj => {
                total_count_fetal = total_count_fetal + 1;
            })
        })
        let total_riskfactor_length = 0;

        risk_factors_options?.map(obj => {
            return riskfactor?.map(
                item => {
                    if (obj.id == item) {
                        total_riskfactor_length++;
                    }
                }
            )
        })
        return (
            <>
                <div className='mt-3 anc_accordion' id={"accordion" + this.state.indexKey}>
                    <div className="card">
                        <div className="card-header" id={"headingOne" + this.state.indexKey}>
                            <button className="btn btn-block text-left  collapsed arrow float-right anc_button mb-0" data-toggle="collapse" data-target={"#collapseOne" + this.state.indexKey} aria-expanded="true" aria-controls={"collapseOne" + this.state.indexKey}>
                                <div className='row'>
                                    <div className='col-3'>
                                        <span className='title'>ANC Card Details</span>
                                    </div>
                                    <div className='col-4'>
                                        <span className='title'>Delivery Date :&nbsp;</span>
                                        <span className='title'>{get_prev_anc_details.deliveryDateTime ? moment(get_prev_anc_details.deliveryDateTime).format("D MMM, YYYY ") : null}</span>
                                    </div>
                                    <div className='col-4'>
                                        <span className='title'>Last Update :&nbsp;</span>
                                        <span className='title'>{get_prev_anc_details.updatedAt ? moment(get_prev_anc_details.updatedAt).format("D MMM, YYYY ") : null}</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div id={"collapseOne" + this.state.indexKey} className="collapse " aria-labelledby={"headingOne" + this.state.indexKey} data-parent={"#accordion" + this.state.indexKey}>
                            <div className="card-body px-0"></div>

                            <div className="container-fluid emrfrm anc-container mb-4 ">
                                <div className='row'>
                                    <div className='col-12 mb-3'>
                                        <div className="float-left">
                                            <h1 className="page-title mb-0">ANC Card View</h1>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='card anc-cardgrey'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <span className='heading'>Patient Name :&nbsp;</span>
                                                    {(patientInfo) ?
                                                        <span className='patient_name'>{patientInfo?.first_name !== undefined ? patientInfo?.first_name : null}&nbsp;{patientInfo?.middle_name !== undefined ? patientInfo?.middle_name : null}&nbsp;{patientInfo?.last_name !== undefined ? patientInfo?.last_name : null}, {patientInfo?.dob && patientInfo?.age !== null ? this.getPatientAge(patientInfo?.dob) : null}, {patientInfo?.gender !== undefined && patientInfo?.gender !== null ? (patientInfo?.gender == 'Male' ? ' (M)' : ' (F)') : null}</span>
                                                        : null}
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <span className='heading'>Address :&nbsp;</span>
                                                    {(patientInfo) ?
                                                        <span className='patient_name'>{patientInfo?.address_1 !== undefined ? patientInfo?.address_1 : null}, {patientInfo?.city !== undefined ? patientInfo?.city : null},   {patientInfo?.state !== undefined ? patientInfo?.state : null}</span>
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Form
                                    layout="vertical"
                                    className="emrfrm"
                                >

                                    <div className='row mt-4'>
                                        {total_riskfactor_length != 0 ?
                                            <div className='col-6' >
                                                <span style={{ color: "#FF0000" }}>Risk Factors</span>
                                            </div>
                                            : null}
                                        {get_prev_anc_details?.riskFactorsManagement != null && get_prev_anc_details?.riskFactorsManagement != "" ?
                                            <div className='col-6'>
                                                <span style={{ color: "#FF0000" }}>Risk Factors Management</span>
                                            </div>
                                            : null}
                                    </div>
                                    <div className='row '>
                                        <div className='col-6'>
                                            <table>
                                                {
                                                    risk_factors_options?.map(obj => {
                                                        return riskfactor?.map(
                                                            item => {
                                                                if (obj.id == item) {
                                                                    return (
                                                                        <tr>{obj.description}</tr>
                                                                    )
                                                                }
                                                            }
                                                        )
                                                    })

                                                }
                                            </table>
                                        </div>

                                        <div className='col-6'>
                                            <span>{get_prev_anc_details.riskFactorsManagement}</span>
                                        </div>
                                    </div>

                                    {allergy?.length > 0 ? <>
                                        <div className='row mt-4'>
                                            <div className='col-12'>
                                                <span className='title'> Allergies</span>
                                            </div>
                                        </div>

                                        <table className="table table-bordered mt-3" >
                                            <thead>
                                                <tr>
                                                    <td width="28%">ALLERGY NAME</td>
                                                    <td width="22%">REACTION</td>
                                                    <td width="15%">DURATION</td>
                                                    <td width="15%">INTENSITY</td>
                                                    <td width="15%">INFORMED BY</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allergy.map(obj => {
                                                    return (
                                                        <tr>
                                                            <td>{(obj.allergy_name) ? obj.allergy_name : '-'}</td>
                                                            <td>{(obj.reaction) ? obj.reaction : '-'}</td>
                                                            <td>{obj.duration ? obj.duration + ' ' + DURATION_TYPE[obj.durationType] : '-'}</td>
                                                            <td>{obj.intensity === 0 ? "Low" : obj.intensity === 1 ? "Medium" : obj.intensity === 2 ? "High" : ''}</td>
                                                            <td>{obj.informedBy ? INFORMED_BY[obj.informedBy] : '-'}</td>
                                                        </tr>
                                                    )
                                                })
                                                }
                                            </tbody>
                                        </table>
                                    </> : null}


                                    {existingCondition?.length > 0 ? <>
                                        <div className='row mt-4'>
                                            <div className='col-12'>
                                                <span className='title'>Existing Condition</span>
                                            </div>
                                        </div>

                                        <table className="table table-bordered mt-3" >
                                            <thead>
                                                <tr>
                                                    <td width="30%">CONDITIONS</td>
                                                    <td width="20%">DURATION</td>
                                                    <td width="20%">ON MEDICATION</td>
                                                    <td width="30%">MEDICINE NAME</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {existingCondition.map(obj => {
                                                    return (
                                                        <tr>
                                                            <td>{obj.condition_description}</td>
                                                            <td>{(obj.duration) ? `${obj.duration} ${DURATION_TYPE[obj.durationType]}` : '-'}</td>
                                                            <td>{MEDICATION_ON[obj.onMedication] ? MEDICATION_ON[obj.onMedication] : '-'}</td>
                                                            <td>{obj.medicineName || "NA"}</td>
                                                        </tr>
                                                    );
                                                })
                                                }
                                            </tbody>
                                        </table>
                                    </> : null}
                                    {menstrualHx?.length > 0 ? <>
                                        <div className='row mt-4'>
                                            <div className='col-3'>
                                                <span className='title'>Menstrual Hx</span>
                                            </div>
                                            <div className='col-4 text-center'>
                                                {get_prev_anc_details.lmp != null && get_prev_anc_details.lmp != "" ?
                                                    <>
                                                        <span>LMP:</span>  <span className='title'>{get_prev_anc_details.lmp}</span >
                                                    </> : null}
                                            </div>
                                            <div className='col-5 text-right'>
                                                {menstrualHx[0]?.created_at ?
                                                    <span className='title'>Last Updated ({menstrualHx[0] ? menstrualHx[0].created_at : null} | {menstrualHx[0] ? menstrualHx[0].created_at_time : null})</span >
                                                    : null}
                                            </div>
                                        </div>

                                        <table className="table table-bordered mt-3" >
                                            <thead>
                                                <tr>
                                                    <td width="10%">DATE</td>
                                                    <td width="20%">CYCLE LENGTH</td>
                                                    {/* <td width="20%">DURATION</td>
                                                    <td width="10%">FLOW</td>
                                                    <td width="10%">REGULARITY</td>
                                                    <td width="10%">PAIN</td>
                                                    <td width="20%">CHANGES IN CYCLE</td> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {menstrualHx.map(obj => {
                                                    return (
                                                        <tr>
                                                            <td>{obj.created_at}</td>
                                                            <td>{(obj.cycle_length) ? obj.cycle_length : '-'}</td>
                                                            {/* <td>{(obj.duration_of_period) ? obj.duration_of_period : '-'} Days</td>
                                                            <td>{(obj.flow) ? obj.flow : '-'}</td>
                                                            <td>{(obj.regularity) ? obj.regularity : '-'}</td>
                                                            <td>{(obj.pain_score) ? obj.pain_score : '-'}</td>
                                                            <td>{(obj.changes_in_cycle) ? obj.changes_in_cycle : '-'}</td> */}
                                                        </tr>
                                                    )
                                                })
                                                }

                                            </tbody>
                                        </table>
                                    </> : null}

                                    <div className='row mt-4 d-flex align-items-center'>
                                        <div className='col-2'>
                                            <span className='title'>Obstetrics Hx</span>
                                        </div>
                                        <div className='col-3'>
                                            {get_prev_anc_details.eddByLmp != null && get_prev_anc_details.eddByLmp != "" ?
                                                <>
                                                    <span>EDD by LMP:</span><span className='title'>{get_prev_anc_details.eddByLmp}</span >
                                                </>
                                                : null}
                                        </div>
                                        {get_prev_anc_details?.eddByUSG != null && get_prev_anc_details?.eddByUSG != "" ?
                                            <div className='col-auto'>
                                                <div className='card obs_card'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <span className='title_red'>EDD by USG:</span><span >{get_prev_anc_details?.eddByUSG}</span >
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            : null}
                                        <div className='col-3 text-right'>
                                            <span className='title'>Lactating Mother</span >
                                        </div>
                                        <div className='col text-right'>
                                            <span className='title'><GPALE values={gpaleLactating} /></span>
                                        </div>
                                    </div>
                                    {get_prev_anc_details?.obstetricsRemarks && get_prev_anc_details?.obstetricsRemarks != "" && get_prev_anc_details?.obstetricsRemarks != null ?
                                        <div className='row mt-4 d-flex align-items-center'>
                                            <div className='col-2'>
                                                <span className='title'>Obstetrics Remarks</span>
                                            </div>
                                            <div className='col-10'>
                                                <pre style={{ fontSize: "15px" }}>{get_prev_anc_details?.obstetricsRemarks}</pre>
                                            </div>
                                        </div>
                                        : null}

                                    {/* {obstetricsHx?.length > 0 ? <>
                                        <table className="table table-bordered mt-3" >
                                            <thead>
                                                <tr>
                                                    <td width="20%">YEAR OF OUTCOME</td>
                                                    <td width="20%">MODE OF CONCEPTION</td>
                                                    <td width="15%">PREGNANCY TYPE</td>
                                                    <td width="15%">DELIVERY TYPE</td>
                                                    <td width="30%">OUTCOME</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {obstetricsHx.map(obj => {
                                                    return (
                                                        <tr>
                                                            <td>{obj.pregnancy_year ? obj.pregnancy_year : '-'}</td>
                                                            <td>{(obj.mode_text) ? obj.mode_text : '-'}</td>
                                                            <td>{(obj.pregnancy_type_text) ? obj.pregnancy_type_text : '-'}</td>
                                                            <td>{(obj.delivery_type_text) ? obj.delivery_type_text : '-'}</td>
                                                            <td>{(obj.outcome_text) ? obj.outcome_text : '-'}</td>
                                                        </tr>
                                                    )
                                                })
                                                }
                                            </tbody>
                                        </table>
                                    </> : null} */}




                                    {physicalExamination?.length > 0 ? <>
                                        <div className='row mt-4'>
                                            <div className='col-12'>
                                                <span className='title'>Physical Examination</span>
                                            </div>
                                        </div>
                                        <div className='row mt-3 '>
                                            <div className='col-4'>
                                                <span className='title'>Mother's Parameters</span>
                                            </div>
                                            <div className='col-8 text-right'>
                                                {physicalExamination[0]?.createdAt ?
                                                    <span className='title'>Last Updated ({physicalExamination[0] ? moment(physicalExamination[0].createdAt).format("DD MMM, YYYY | h:mm A") : null})</span >
                                                    : null}
                                            </div>
                                        </div>

                                        <table className="table table-bordered mt-3" >
                                            <thead>
                                                <tr>
                                                    <td width="15%">PARAMETERS</td>
                                                    <td width="8%">POG</td>
                                                    <td width="10%">Weight</td>
                                                    <td width="5%">BP</td>
                                                    <td width="10%">PULSE RATE</td>
                                                    <td width="10%">FUNDAL HT</td>
                                                    <td width="10%">EDEMA</td>
                                                    <td width="10%">PALLOR</td>
                                                    <td width="12%">CERVIX EXAM</td>
                                                    <td width="15%">PRETEM LABOUR</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {physicalExamination.map(obj => {
                                                    return (
                                                        <tr>
                                                            <td>{moment(obj.createdAt).format("D MMM, YYYY ")}</td>
                                                            <td>{obj.pogWeeks ? obj.pogWeeks : '-'}</td>
                                                            <td>{obj.weight ? obj.weight : '-'}</td>
                                                            <td>{obj.bpmm ? obj.bpmm : '-'}</td>
                                                            <td>{obj.pulseRate ? obj.pulseRate : '-'}</td>
                                                            <td>{(obj.fundalHeight) ? obj.fundalHeight : '-'}</td>
                                                            <td>{(obj.edema) ? obj.edema : '-'}</td>
                                                            <td>{(obj.pallor) ? obj.pallor : '-'}</td>
                                                            <td>{obj.cervixExam ? obj.cervixExam : '-'}</td>
                                                            <td>{obj.pretermLabour ? obj.pretermLabour :  '-'}</td>
                                                        </tr>
                                                    )
                                                })
                                                }
                                            </tbody>
                                        </table>
                                    </> : null}
                                    {total_count_fetal != 0 ? <>
                                        <div className='row mt-4 '>
                                            <div className='col-4'>
                                                <span className='title'>Fetal Parameters</span>
                                            </div>
                                            <div className='col-8 text-right'>
                                                {fetalParameters[0]?.createdAt ?
                                                    <span className='title'>Last Updated ({fetalParameters[0] ? moment(fetalParameters[0].createdAt).format("DD MMM, YYYY | h:mm A") : null})</span >
                                                    : null}
                                            </div>
                                        </div>

                                        <table className="table table-bordered mt-3" >
                                            <thead>
                                                <tr>
                                                    <td width="10%">PARAMETERS</td>
                                                    <td width="10%">FETUS NO.</td>
                                                    <td width="10%">FETAL MOVEMENT</td>
                                                    <td width="10%">FHR(PER MINUTE)</td>
                                                    <td width="10%">PRESENTATION</td>
                                                    <td width="10%">RELATION OF PP TO BRIM</td>
                                                    <td width="10%">HEAD CC (CM)</td>
                                                    <td width="10%">ABDOMINAL CC (CM)</td>
                                                    <td width="10%">FEMUR LENGTH(CM)</td>
                                                    <td width="10%">REMARKS</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    fetalParameters.map((data, i) => (
                                                        data.fetal_params.map((d, j) => (
                                                            <tr key={d.id} className={j != 0 ? 'highlight_bg' : ''}>
                                                                <td >{(data?.createdAt) ? moment(data?.createdAt).format("D MMM, YYYY") : '-'}</td>
                                                                <td>{data.noOfFetus ? data.noOfFetus : '-'}</td>
                                                                <td className="text-center">{this?.getListValue(d.fetalMovement, FETAL_MOVEMENT)}</td>
                                                                <td className="text-center">{d.fetalHeartRate || '-'}</td>
                                                                <td className="text-center">{this?.getListValue(d.presentation, PRESENTATION)}</td>
                                                                <td className="text-center">{this?.getListValue(d.relPPtoBrim, RELATIONSHIP_TO_PP_BRIM)}</td>
                                                                <td className="text-center">{d.headCc || '-'}</td>
                                                                <td className="text-center">{d.abdominalCc || '-'}</td>
                                                                <td className="text-center">{d.femurLength || '-'}</td>
                                                                <td style={{ wordBreak: "break-all" }} className="text-center">{data.remarks || '-'}</td>
                                                            </tr>
                                                        ))
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </> : null}

                                    {Immunoprophylaxis?.length > 0 ? <>
                                        <div className='row mt-4 '>
                                            <div className='col-12'>
                                                <span className='title'>Immunoprophylaxis</span>
                                            </div>
                                        </div>

                                        <div className='row mt-3'>
                                            {
                                                Immunoprophylaxis.map((obj) => {
                                                    return (
                                                        <div className='col-3'>
                                                            <span>{obj.name}</span>
                                                            <b>: {obj.date}</b>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </> : null}

                                    {get_prev_anc_details?.ImmunoprophylaxisRemarks ?
                                        <div className='row mt-2'>
                                            <div className='col-12'>
                                                <label htmlFor="immuno_remarks">Immunoprophylaxis Remarks: </label>
                                                <b> {get_prev_anc_details?.ImmunoprophylaxisRemarks}</b>
                                            </div>
                                        </div>
                                        : null}

                                    {/* {tests?.length > 0 ? <>
                                        <div className='row mt-4 '>
                                            <div className='col-12'>
                                                <span className='title'>Lab Tests</span>
                                            </div>
                                        </div>

                                        <table className="table table-bordered mt-3" >
                                            <thead>
                                                <tr>
                                                    <td width="15%">DATE</td>
                                                    <td width="19%">TEST NAME</td>
                                                    <td width="41%">RESULT/IMPRESSION</td>
                                                    <td width="15%">REFERENCE RANGE</td>
                                                    <td width="10%">VIEW REPORT</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    tests.map(obj => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <span> {obj.date}</span>
                                                                </td>
                                                                <td>
                                                                    <span> {obj.name}</span>
                                                                </td>
                                                                <td>
                                                                    <span> {obj.result}</span>
                                                                </td>
                                                                <td>
                                                                    <span style={{ height: '41px' }}> {obj.refernce}</span>
                                                                </td>
                                                                <td>
                                                                    <span style={{ height: '41px' }}> - </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </> : null}

                                    {radiology_tests?.length > 0 ? <>
                                        <div className='row mt-4 '>
                                            <div className='col-12'>
                                                <span className='title'>Radiology Tests</span>
                                            </div>
                                        </div>

                                        <table className="table table-bordered mt-3" >
                                            <thead>
                                                <tr>
                                                    <td width="15%">DATE</td>
                                                    <td width="19%">TEST NAME</td>
                                                    <td width="41%">RESULT/IMPRESSION</td>
                                                    <td width="15%">REFERENCE RANGE</td>
                                                    <td width="10%">VIEW REPORT</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    radiology_tests.map(obj => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <span> {obj.date}</span>
                                                                </td>
                                                                <td>
                                                                    <span> {obj.name}</span>
                                                                </td>
                                                                <td>
                                                                    <span> {obj.result}</span>
                                                                </td>
                                                                <td>
                                                                    <span style={{ height: '41px' }}> {obj.refernce}</span>
                                                                </td>
                                                                <td>
                                                                    <span style={{ height: '41px' }}> - </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </> : null} */}

                                    <div className='row mt-4 '>
                                        <div className='col-12'>
                                            <span className='title'>Delivery Details</span>
                                        </div>
                                    </div>

                                    <div className='row mt-3'>
                                        {/* <div className='col-4'>
                                            <span>Date & Time:</span>
                                            <b> {get_prev_anc_details?.deliveryDateTime ? moment(get_prev_anc_details.deliveryDateTime).format("DD MMM, YYYY HH:mm") : null}</b>
                                        </div> */}
                                        <div className='col-4'>
                                            <span>Mode:</span>
                                            <b> {get_prev_anc_details?.deliveryMode === "1" ? "Natural" : get_prev_anc_details?.deliveryMode === "2" ? "IVF" : get_prev_anc_details?.deliveryMode === "3" ? "IUI" : ''}</b>
                                        </div>
                                        <div className='col-4'>
                                            <span>Pregnancy Type:</span>
                                            <b> {get_prev_anc_details?.pregnancyType == "1" ? "Single" :
                                                get_prev_anc_details?.pregnancyType == "2" ? "Multiple" :
                                                    get_prev_anc_details?.pregnancyType == "3" ? "Ectopic" : ''}</b>
                                        </div>
                                    </div>

                                    {outcomeGroup.length > 0 ?
                                        <div className='row mt-3'>
                                            <div className='col-12'>
                                                <span>Outcome:</span>
                                                {outcomeGroup?.map(obj => {
                                                    return (
                                                        <div className='row mt-2  ml-2'>
                                                            <b> <span> {obj.outcome == "1" ? "Live Term" :
                                                                obj.outcome == "2" ? "Live Pre-Term" :
                                                                    obj.outcome == "3" ? "Still born Term" :
                                                                        obj.outcome == "4" ? "Still born Pre-Term" :
                                                                            obj.outcome == "5" ? "Spontaneous Abortion" :
                                                                                obj.outcome == "6" ? "MTP" : '-'} ,</span> </b> &nbsp;
                                                            {obj?.delivery_type ? <> <b><span>{obj.delivery_type == "1" ? "Normal" :
                                                                obj.delivery_type == "2" ? "LSCS" :
                                                                    obj.delivery_type == "3" ? "Forceps" :
                                                                        obj.delivery_type == "4" ? "Water Birthing" : '-'} ,</span> </b> &nbsp;  </> : null}
                                                            {obj?.birth_date_time ? <b><span>Birth Date & Time:- </span> {moment(obj?.birth_date_time).format("DD MMM, YYYY HH:mm")} , </b> : null}
                                                            {obj?.gender ? <> <b> <span> {obj.gender == "1" ? "Male" :
                                                                obj.gender == "2" ? "Female" :
                                                                    obj.gender == "3" ? "Transgender" : '-'} ,</span> </b> &nbsp;  </> : null}
                                                            {obj?.birth_weight ? <> <span>Brith wt<b> {obj.birth_weight ? obj.birth_weight : "-"}</b> kg</span> , &nbsp;  </> : null}
                                                            {obj?.place_of_delivery ? <> <b><span>{obj.place_of_delivery === "1" ? "Hospital" :
                                                                obj.place_of_delivery === "2" ? "Home" :
                                                                    obj.place_of_delivery === "3" ? "PHC" :
                                                                        obj.place_of_delivery === "4" ? "Others" : '-'} ,</span> </b> &nbsp;  </> : null}
                                                            <b><span>Congenital Abnormality:- </span> {obj?.abnormality == true ? "Yes" : "No"} , </b> &nbsp;
                                                            {obj?.abnormality == true ?
                                                                <b><span>Congenital Abnormality Remark:- </span> {obj?.abnormality_remark} </b>
                                                                : null}
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>
                                        </div>
                                        : null}

                                    <div className='row mt-3'>
                                        {get_prev_anc_details?.deliveryIndication != null && get_prev_anc_details?.deliveryIndication != "" ?
                                            <div className='col-6'>
                                                <span>Indication for C-Section:</span>
                                                <b> {get_prev_anc_details?.deliveryIndication}</b>
                                            </div>
                                            : null}
                                        {get_prev_anc_details?.deliveryDetails != null && get_prev_anc_details?.deliveryDetails != "" ?
                                            <div className='col-6'>
                                                <span>Details:</span>
                                                <b> {get_prev_anc_details?.deliveryDetails}</b>
                                            </div>
                                            : null}
                                    </div>

                                    <div className='row mt-3 '>
                                        <div className='col-12 text-center'>
                                            <ReactToPrint
                                                pageStyle={this.getPageMargins()}
                                                trigger={() => (
                                                    <a className="btn btn-primary px-3 small text-uppercase ml-2" href="#">
                                                        PRINT
                                                    </a>
                                                )}
                                                content={() => this.componentRef}
                                            />

                                            <div style={{ overflow: 'hidden', height: '0' }}>

                                                < PrintPreviousAnc
                                                    ref={(el) => (this.componentRef = el)}
                                                    get_prev_anc_details={this.state.get_prev_anc_details}
                                                    patientInfo={patientInfo}
                                                    riskfactor={riskfactor}
                                                    risk_factors_options={this.state.risk_factors_options}
                                                    allergy={allergy}
                                                    conditions={existingCondition}
                                                    menstrualHistoryList={menstrualHx}
                                                    physicalExaminationData={physicalExamination}
                                                    gpaleLactating={gpaleLactating}
                                                    obsHistoryList={obstetricsHx}
                                                    physicalExaminationFPData={fetalParameters}
                                                    Immunoprophylaxis={Immunoprophylaxis}
                                                    tests={tests}
                                                    radiology_tests={radiology_tests}
                                                    outcomeGroup={outcomeGroup}
                                                    total_count_fetal={total_count_fetal}
                                                    total_riskfactor_length={total_riskfactor_length}

                                                />
                                            </div>


                                        </div>
                                    </div>
                                </Form>

                            </div>
                        </div>


                    </div>
                </div>
            </>
        )
    }
}


