import React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Moment from "react-moment";
import { GET_VITALS_RANGE_CLASS } from '../../utils/constant';
import LS_SERVICE from '../../utils/localStorage';
import { Popover } from 'antd';

class VitalsSS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: props.dataLoaded,
      constants: props.constants,
      VitalsConstants: Object.values(props.constants.vitals_details),
      visitVitalsFormatted: props.visitVitalsFormatted ?? [],
      vitalsOrder: props.vitalsOrder,
      newVitalsOrder: []
    }
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (nextProps.visitVitalsFormatted !== prevState.visitVitalsFormatted)
      return { visitVitalsFormatted: nextProps.visitVitalsFormatted }
    if (nextProps.vitalsOrder !== prevState.vitalsOrder)
      return { vitalsOrder: nextProps.vitalsOrder }
    return null;
  }

  componentDidMount (){
    this.updateVitalsOrder();
  }

  componentDidUpdate(prevProps, prevState) {
    if ((this.state.vitalsOrder != null && this.state.vitalsOrder != prevState.vitalsOrder) || (this.state.visitVitalsFormatted != null && this.state.visitVitalsFormatted != prevState.visitVitalsFormatted)) {
      this.updateVitalsOrder();
    }
  }

  updateVitalsOrder = async () => {
    let new_vitals_order = [];
    this.state.vitalsOrder.map((v, k) => {
      let obj = {display_order: v.display_order, vital_id: v.vital_id};
      let findex = this.state.VitalsConstants.findIndex((x) => x.vital_id == v.vital_id);
      if(findex !== -1){
        let vital = this.state.VitalsConstants[findex];
        let vitalsavailable = false;
        this.state.visitVitalsFormatted[vital.id]?.map((val, i) => {
          if (val && val != "") {
            vitalsavailable = true;
          }
        });
        obj.value_available = vitalsavailable;
        new_vitals_order.push(obj);
      }
    });
    await this.setState({
      newVitalsOrder: new_vitals_order
    });
  }

  getSourceDisplayName(source, date, update_date) {
    let sourceName = source[0].toUpperCase() + source.substring(1);
    let time = moment(update_date).format('DD MMM, h:mm A');
    //return source == 'patient' && date && update_date && date != update_date ? sourceName + ' | ' + time : sourceName;
    return sourceName;
  }

  render() {
    let one_value_avail = false;
    this.state.newVitalsOrder.forEach((data) => {
      if(!one_value_avail){
        one_value_avail = data.value_available;
      }
    });
    return (
      <>
        {this.state.dataLoaded ? ( 
          <div className="card-body vitals" id="vital-details">
            <div className="row">
              <div className="col-12 table-responsive" style={{ padding: "0px" }}>
              {this.state.newVitalsOrder.length && one_value_avail ? 
                <table className="table">
                  <tbody>
                    <tr>
                      <td>Parameter</td>
                      {this.state?.visitVitalsFormatted?.date.map((v, i) => (
                        <td key={i} className={`pl-0 pr-0 ${this.state.visitVitalsFormatted.source[i] === 'patient' && 'bgis-pink'}`} style={{ maxWidth: "250px" }}>
                          <div className="row">
                            <div className="col-12 text-center vital-source">
                              {this.state.visitVitalsFormatted?.source_name[i] !== "" ? (
                                <Popover
                                  className="ml-1"
                                  content={
                                    this.state.visitVitalsFormatted?.source_name[i]
                                  }
                                >
                                  {this.getSourceDisplayName(this.state.visitVitalsFormatted.source[i], v, this.state.visitVitalsFormatted.update_date[i])}
                                </Popover>
                              ) : (
                                this.getSourceDisplayName(this.state.visitVitalsFormatted.source[i], v, this.state.visitVitalsFormatted.update_date[i])
                              )}
                            </div>
                            <div className="col-12 text-center vital-time">    
                              {moment(v).format("DD MMM, yyyy | h:mm A")}
                              {/* CORRECTION MADE BY VEENA */}
                              {/* <Moment parse="YYYY-MM-DDTHH:mm:ss.SSSSZ" format="DD MMM, yyyy | h:mm A" date={v} /> */}
                            </div>
                          </div>
                        </td>
                      ))}
                      <td className="text-center" style={{ width: "132px" }}>Reference Range</td>
                      <td className="text-center">Trend Graph</td>
                    </tr>
                    {this.state.newVitalsOrder.map((v, k) => {
                      let findex = this.state.VitalsConstants.findIndex((x) => x.vital_id == v.vital_id);
                      if(findex !== -1){
                        let vital = this.state.VitalsConstants[findex];
                        if(v.value_available){
                          if (vital.id != 'V005' && vital.id != 'V006'){
                            return (
                              <tr key={vital.id}>
                                <td>{vital.display_name} {vital.display_value ? '('+vital.display_value+')' : null}</td>
                                {this.state.visitVitalsFormatted[vital.id]?.map((val, i) => {
                                  if (val) return (<td key={i} className={`text-center ${vital.id != 'V001' && vital.id != 'V002' ? GET_VITALS_RANGE_CLASS(val, this.state.constants.vitals_range[vital.id]) : ''} ${this.state.visitVitalsFormatted.source[i] === 'patient' && 'bgis-pink'}`}>{val}</td>)
                                  else return (<td key={i} className={`text-center ${this.state.visitVitalsFormatted.source[i] === 'patient' && 'bgis-pink'}`}>-</td>);
                                })}
                                <td>{vital.id == 'V001' || vital.id == 'V002' ? '-' : this.state.constants.vitals_range[vital.id].normal.min + '-' + this.state.constants.vitals_range[vital.id].normal.max}</td>
                                <td>
                                  {/* {
                                    vital.vital_type == 'N'
                                  ? */}
                                    <button type="button" className="btn chart-btn button-chart-individual"
                                      onClick={e => this.props.handleModalPopupStateLift(e, 'chartModal', { type: vital.id, name: 'Trend Graph' })}>
                                      <i className="icon-chart"></i>
                                    </button>
                                  {/* :
                                    null
                                  } */}
                                </td>
                              </tr>
                          )
                          } else if (vital.id == 'V005'){
                            return (
                              <tr key={vital.id}>
                                <td>Blood Pressure ({vital.display_value})</td>
                                {this.state.visitVitalsFormatted.V006.map((val, i) => (
                                  <td className={`text-center ${this.state.visitVitalsFormatted.source[i] === 'patient' && 'bgis-pink'}`} key={i}>
                                    <span className={(val ? GET_VITALS_RANGE_CLASS(this.state.visitVitalsFormatted.V006[i], this.state.constants.vitals_range.V006) : '')}>{this.state.visitVitalsFormatted.V006[i] ? this.state.visitVitalsFormatted.V006[i] : '-'}</span>/
                                    <span className={(val ? GET_VITALS_RANGE_CLASS(this.state.visitVitalsFormatted.V005[i], this.state.constants.vitals_range.V005) : '')}>{this.state.visitVitalsFormatted.V005[i] ? this.state.visitVitalsFormatted.V005[i] : '-'}</span>
                                  </td>)
                                )}

                                <td>{this.state.constants.vitals_range.V006.normal.min + '-' + this.state.constants.vitals_range.V006.normal.max}/{this.state.constants.vitals_range.V005.normal.min + '-' + this.state.constants.vitals_range.V005.normal.max}</td>

                                <td>
                                  <button type="button" className="btn chart-btn button-chart-individual"
                                    onClick={e => this.props.handleModalPopupStateLift(e, 'chartModal', { type: vital.id, name: 'Trend Graph' })}>
                                    <i className="icon-chart"></i>
                                  </button>
                                </td>
                              </tr>
                            )
                          }
                        }
                      }
                    })}
                  </tbody>
                </table>
              : <div className="mb-2 mt-2 text-center">No Record Found</div>}
              </div>
            </div>
          </div>
        ) : ""}
      </>
    )
  }
}

export default VitalsSS;