import React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Moment from "react-moment";
import { GET_VITALS_RANGE_CLASS, LOADER_RED, LOADER, KEY_FINDINGS_LIS_STORE_NOTES, API_BASE_URL } from '../../utils/constant';
import LS_SERVICE from '../../utils/localStorage';
import { Popover, Modal, Button, Form, Input, notification } from 'antd';
import {
  SettingFilled, FileOutlined
} from '@ant-design/icons';
import { string } from 'prop-types';
import Axios from 'axios';
import { asyncScheduler } from 'rxjs';

class KeyFindingsNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyFindingsNewDataFormatted: props?.keyFindingsNewDataFormatted ?? {},
      keyfindingDates: props?.keyFindingsNewDataFormatted?.kfdates ?? [],
      keyfindingDatesFormatted: props?.keyFindingsNewDataFormatted?.kfdates.slice(0, 5) ?? []
      ,
      chartDataGroup: props?.keyFindingsNewDataFormatted?.chartDataGroup ?? {},
      kfDisplayData: props?.keyFindingsNewDataFormatted?.kfDisplayData ?? {},
      kfDisplayDataNonNumeric: props?.keyFindingsNewDataFormatted?.kfDisplayDataNonNumeric ?? {},
      kfDisplayDataInv: props?.keyFindingsNewDataFormatted?.kfDisplayDataInv ?? {},
      path: null,
      showViewerModal: false,
      showNotesModal: false,
      sample_id: null,
      notesSaved: false,
      index: 0
    }

    this.notesRef = React.createRef();
  }

  onViewClick(path) {
    this.setState({
      path: path,
      showViewerModal: true
    });
  }

  handleNotesPopup(sample_id) {

    this.setState({ sample_id: sample_id, showNotesModal: true, });

  }

  onSaveNotes(values, sample_id) {

    let data = {
      lis_sampledata_id: sample_id,
      impression: values.notes,
      enteredById: LS_SERVICE.get('staff_id')
    }

    this.setState({ notesSaved: true });

    Axios.post(KEY_FINDINGS_LIS_STORE_NOTES, data)
      .then(res => {
        let response = res.data.data;
        if (response.statusCode == 200) {
          this.setState({ showNotesModal: false, notesSaved: false });
          notification.success({
            message: "Notes saved successfully!",
            placement: 'topRight'
          })
          this.notesRef.current.setFieldsValue({
            notes: null
          });
        }
      }).catch(err => {
        this.setState({ showNotesModal: false, notesSaved: false });
        this.notesRef.current.setFieldsValue({
          notes: null
        });
        notification.error({
          message: "Something Went Wrong!",
          placement: 'topRight'
        })
        console.log("Error", err);
      })
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (prevState.keyFindingsNewDataFormatted !== nextProps.keyFindingsNewDataFormatted) {
      return {
        keyFindingsNewDataFormatted: nextProps?.keyFindingsNewDataFormatted,
        keyfindingDatesFormatted: nextProps?.keyFindingsNewDataFormatted?.kfdates.slice(0, 5),
        chartDataGroup: nextProps?.keyFindingsNewDataFormatted?.chartDataGroup ?? {},
        kfDisplayData: nextProps?.keyFindingsNewDataFormatted?.kfDisplayData ?? {},
        kfDisplayDataNonNumeric: nextProps?.keyFindingsNewDataFormatted?.kfDisplayDataNonNumeric ?? {},
        kfDisplayDataInv: nextProps?.keyFindingsNewDataFormatted?.kfDisplayDataInv ?? {},
      };
    }
    return null;
  }

  onClickArrow = async (arrow_type) => {
    const { keyfindingDates, keyfindingDatesFormatted } = this.state;
    var size = 5;
    if (arrow_type == 'next') {
      await this.setState({ index: this.state.index + size });
      while (this.state.index >= keyfindingDates.length) {
        this.setState({ index: this.state.index - size });
      };
      let nextDates = keyfindingDates.slice(this.state.index, this.state.index + size);
      this.setState({ keyfindingDatesFormatted: nextDates });
    }

    if (arrow_type == 'previous') {
      await this.setState({ index: this.state.index - size });
      while (this.state.index < 0) {
        this.setState({ index: this.state.index + size });
      };
      let prevDates = keyfindingDates.slice(this.state.index, this.state.index + size);
      this.setState({ keyfindingDatesFormatted: prevDates });
    }
  }

  GET_RANGE_CLASS = (status) => {
    if (status == "-") return "";
    let vitalRange = "";
    //normal range
    if (status == 'N')
      vitalRange = "text-success-vitals";

    //abnormal range
    if (
      status == 'A' || status == 'P'
    )
      vitalRange = "text-danger";

    //critical range
    if (
      status == 'L' || status == 'H'
    )
      vitalRange = "text-warning";

    return vitalRange;
  };

  render() {
    const { keyfindingDates, keyfindingDatesFormatted, chartDataGroup, kfDisplayData, kfDisplayDataNonNumeric, kfDisplayDataInv } = this.state;

    let headings =typeof this.state.keyFindingsNewDataFormatted?.kfDisplayData != 'undefined' ? Object.keys(this.state.keyFindingsNewDataFormatted?.kfDisplayData) : [];
    let nonNumericHeadings = typeof this.state.keyFindingsNewDataFormatted?.kfDisplayDataNonNumeric != 'undefined' ? Object.keys(this.state.keyFindingsNewDataFormatted?.kfDisplayDataNonNumeric) : [];
    let invHeadings = typeof this.state.keyFindingsNewDataFormatted?.kfDisplayDataInv != 'undefined' ?  Object.keys(this.state.keyFindingsNewDataFormatted?.kfDisplayDataInv) : [];

    return (
      <>
        <div className="card-body vitals">
          <div className="row">
            <div className="col-12 table-responsive" style={{ padding: "0px" }}>
              <div className='fixed-table'>
                <table className="table">
                  <tbody>

                    { typeof keyfindingDatesFormatted != 'undefined' &&  keyfindingDatesFormatted?.length > 0 ?
                      <tr>
                        <th width="20%">Parameter
                          <span disabled="disabled" className="" style={{ position: 'absolute', right: '3px', top: "26px" }}>
                            <a >
                              <span disabled className="float-right chevron chevron-left arrow-left"
                                onClick={() => this.onClickArrow('previous')}
                              />
                            </a>
                          </span>
                        </th>
                        {
                          keyfindingDatesFormatted.map((v, i) => (
                            <th key={i} className={`pl-0 pr-0`} style={{ maxWidth: "250px" }}>
                              <div className="row">
                                <div className="col-12 text-center vital-time">
                                  <div>LIS</div>
                                  {v}
                                </div>
                              </div>
                            </th>
                          ))
                        }
                        <th className="text-center text-bold" style={{ width: '132px' }}>
                          Reference Range
                          <span disabled="disabled" className="" style={{ position: 'absolute', left: '3px', top: "26px" }}>
                            <a >
                              <span disabled={keyfindingDatesFormatted.length > 5 ? true : false} className="float-right chevron chevron-right arrow-right"
                                onClick={() => this.onClickArrow('next')}
                              />
                            </a>
                          </span>
                        </th>
                        <th className="text-center text-bold">
                          Trend Graph
                        </th>
                      </tr>
                      : <tr><td className="pt-2">No Record Found</td></tr>}

                    {/* Numeric GRP*/}
                    {
                      typeof keyfindingDatesFormatted != 'undefined' && keyfindingDatesFormatted?.length > 0 ?
                      typeof headings != 'undefined' && headings?.length > 0 ?
                          headings.map((kf, k2) => {
                            let subHeadings = Object.keys(kfDisplayData[kf]);
                            let testData = this.state.chartDataGroup[kf];
                            return (
                              <React.Fragment key={k2}>
                                <tr>
                                  <td className={`text-bold`}>{kf}</td>
                                  {
                                    keyfindingDatesFormatted.map(date => {
                                      return (<td></td>)
                                    })
                                  }
                                  <td></td>
                                  <td>
                                    <button
                                      type="button"
                                      className="btn chart-btn button-chart-individual"
                                      onClick={e => this.props.handleModalPopupStateLift(e, 'keyFindingChartModalNew', { type: 'finding-group', name: 'Key Findings Trend Graphs', testData, testName: kf })}
                                    >
                                      <i className="icon-chart"></i>
                                    </button>
                                  </td>
                                </tr>
                                {
                                  typeof subHeadings != 'undefined' && subHeadings?.length > 0 ?
                                    subHeadings.map(sh => {
                                      let refDate;
                                      let isNormal;
                                      let testName;
                                      let testDataIndividual;
                                      return (
                                        <>
                                          <tr>
                                            <td>{sh}</td>
                                            {
                                              keyfindingDatesFormatted.map(date => {
                                                refDate = date;
                                                isNormal = kfDisplayData[kf][sh][date]?.isNormal;
                                                testName = sh;
                                                testDataIndividual = this.state.chartDataGroup[kf];
                                                if (typeof kfDisplayData[kf][sh][date]?.test_value != undefined && kfDisplayData[kf][sh][date]?.test_value != "") {
                                                  return (
                                                    <td style={{ maxWidth: '114px', width: '114px' }} className={`text-center ${this.GET_RANGE_CLASS(isNormal)}`}>{kfDisplayData[kf][sh][date]?.test_value != null && kfDisplayData[kf][sh][date]?.test_value != "" ? kfDisplayData[kf][sh][date]?.test_value : '-'}</td>
                                                    // <td className='text-center'>-</td>
                                                  )
                                                } else {
                                                  return (
                                                    <td className='text-center'>-</td>
                                                  )
                                                }
                                              })
                                            }
                                            <td className='text-center'><div dangerouslySetInnerHTML={{ __html: kfDisplayData[kf][sh]?.reference_range }} />{kfDisplayData[kf][sh]?.UOM != "" ? `( ${kfDisplayData[kf][sh]?.UOM} )` : ""}</td>
                                            <td>
                                              <button
                                                type="button"
                                                className="btn chart-btn button-chart-individual"
                                                onClick={e => this.props.handleModalPopupStateLift(e, 'keyFindingChartModalNew', { type: 'individual', id: 1, name: 'Key Findings Trend Graphs', isNormal, testDataIndividual, testName })}
                                              >
                                                <i className="icon-chart"></i>
                                              </button>
                                            </td>
                                          </tr>
                                        </>
                                      )
                                    }) : null
                                }
                              </React.Fragment>

                            );
                          })
                          : null
                        : null
                    }

                    {/* Numeric INV*/}
                    {
                      typeof keyfindingDatesFormatted != 'undefined' && keyfindingDatesFormatted?.length > 0 ?
                        typeof invHeadings != 'undefined' && invHeadings?.length > 0 ?
                          invHeadings.map((ikf, k2) => {
                            let refDate;
                            let isNormal;
                            let testName;
                            let testDataIndividual = this.state.chartDataGroup[ikf];
                            let testObj = {};
                            testObj[ikf] = testDataIndividual;
                            return (
                              <React.Fragment key={k2}>
                                {
                                  <tr>
                                    <td className={`text-bold`}>{ikf}</td>
                                    {
                                      keyfindingDatesFormatted.map(date => {
                                        refDate = date;
                                        isNormal = kfDisplayDataInv[ikf][date]?.isNormal;
                                        testName = ikf;

                                        if (typeof kfDisplayDataInv[ikf][date]?.test_value != undefined && kfDisplayDataInv[ikf][date]?.test_value != "") {
                                          return (
                                            <td style={{ maxWidth: '114px', width: '114px' }} className={`text-center ${this.GET_RANGE_CLASS(isNormal)}`}>{kfDisplayDataInv[ikf][date]?.test_value != null && kfDisplayDataInv[ikf][date]?.test_value != "" ? kfDisplayDataInv[ikf][date]?.test_value : '-'}</td>
                                          )
                                        } else {
                                          return (
                                            <td className='text-center'>-</td>
                                          )
                                        }
                                      })
                                    }
                                    <td className='text-center'><div dangerouslySetInnerHTML={{ __html: kfDisplayDataInv[ikf]?.reference_range }} />{kfDisplayDataInv[ikf]?.UOM != "" && kfDisplayDataInv[ikf]?.UOM != null ? `( ${kfDisplayDataInv[ikf]?.UOM} )` : ""}</td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn chart-btn button-chart-individual"
                                        onClick={e => this.props.handleModalPopupStateLift(e, 'keyFindingChartModalNew', { type: 'individual', id: 1, name: 'Key Findings Trend Graphs', isNormal, testDataIndividual: testObj, testName })}
                                      >
                                        <i className="icon-chart"></i>
                                      </button>
                                    </td>
                                  </tr>

                                }
                              </React.Fragment>
                            );
                          })
                          : null
                        : null
                    }

                    {/* Non Numeric*/}
                    {
                      typeof keyfindingDatesFormatted != 'undefined' &&  keyfindingDatesFormatted?.length > 0 ?
                      typeof nonNumericHeadings != 'undefined' && nonNumericHeadings?.length > 0 ?
                          nonNumericHeadings.map((kf, k2) => {
                            return (
                              <React.Fragment key={k2}>
                                <tr>
                                  <td className={`text-bold`}>{kf}</td>
                                  {
                                    keyfindingDatesFormatted.sort().reverse().map(date => {

                                      let image = kfDisplayDataNonNumeric[kf][date]?.imageURL
                                      let result = kfDisplayDataNonNumeric[kf][date]?.result_pdf
                                      let lis_sampledata_id = kfDisplayDataNonNumeric[kf][date]?.lis_sampledata_id

                                      if (typeof image != undefined || typeof result != undefined) {
                                        return (<td className='text-center' style={{maxWidth: '114px', width: '114px'}}>
                                          {
                                            image != "" && image != null ?
                                              <a style={{ marginRight: '11px', color: 'red', textDecoration: 'underline' }}
                                                onClick={() => this.onViewClick(result)}
                                              >Resulted</a>
                                              : null
                                          }

                                          {
                                            result != "" && result != null ?
                                              <a style={{ marginRight: '11px', color: '#bda800' }} href={image} target="_blank" rel="noopener noreferrer"><SettingFilled /></a>
                                              : null
                                          }
                                          <a onClick={() => this.handleNotesPopup(lis_sampledata_id)}><FileOutlined /></a>
                                        </td>)
                                      } else {
                                        return (<td>-</td>)
                                      }

                                    })
                                  }
                                </tr>
                              </React.Fragment>
                            );
                          })
                          : null
                        : null
                    }

                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>

        {/* For Viewing Material */}
        <Modal
          visible={this.state.showViewerModal}
          footer={false}
          width={850}
          onCancel={() => { this.setState({ showViewerModal: false, pdfPath: null, index: 0 }) }}
          style={{ top: 20 }}
        >
          <div className="row">
            <div className="col-12 my-3 text-center">
              {this.state.path != null ? <iframe src={this.state.path} frameBorder="0" scrolling="no" style={{ overflow: 'hidden' }} width="100%" height="700">
                {LOADER_RED}
              </iframe> : null}
            </div>
          </div>
        </Modal>


        <Modal
          visible={this.state.showNotesModal}
          footer={false}
          width={600}
          onCancel={() => { this.setState({ showNotesModal: false }) }}
          style={{ top: 20 }}
        >
          <Form ref={this.notesRef} layout='vertical' onFinish={(val) => this.onSaveNotes(val, this.state.sample_id)}>
            <Form.Item label="Notes" type='text' name='notes' className='mt-3'>
              <Input autoComplete="off" autoFocus={true} />
            </Form.Item>
            <div className='text-center'>
              <button disabled={this.state.notesSaved} type='Submit' className="btn btn-primary">
                Save {!this.state.notesSaved ? "" : LOADER}
              </button>
            </div>
          </Form>
        </Modal>
      </>


    );
  }
}

export default KeyFindingsNew;
